(function (module) {

    var templateRoot = "/Apps/program/templates/";

    module.directive('programInformation', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programInformation.html'
        };
    });

    module.directive('programReview', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programReview.html'
        };
    });

    module.directive('programLinks', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programLinks.html'
        };
    });

    module.directive('programHistory', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programHistory.html'
        };
    });

    module.directive('programSplitMerge', function () {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programSplitMerge.html'
        };
    });

    module.directive('programSummary', function (programSummarySvc, helperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programSummary.html',
            link: function (scope) {
                scope.formatDate = helperSvc.formatDate;
                scope.isEmpty = helperSvc.isEmpty;
                scope.isNestedArrayEmpty = helperSvc.isNestedArrayEmpty;
                scope.getProgramReviewDisciplineAreas = programSummarySvc.getProgramReviewDisciplineAreas;
                scope.getDistanceLearningTypeName = programSummarySvc.getDistanceLearningTypeName;
                scope.getProgramReviewCampuses = programSummarySvc.getProgramReviewCampuses;
                scope.editChangeHistory = programSummarySvc.editChangeHistory;
            }
        };
    });
    
    module.directive('programNameSearch', function (programSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'programNameSearch.html',
            scope: {
                program: '=',
                commissionId: '=',
                required: '@?',
                callback: '&?',
                disabled: '=?'
            },
            link: function (scope, element, attrs, ctrl) {
                scope.isRequired = function() {
                    return scope.required === 'true';
                };

                scope.searchProgramNames = function (val) {
                    return programSvc.searchProgramNames(val, scope.commissionId ? scope.commissionId : scope.program.commissionIds, 16).then(function (data) {
                        return data.value;
                    });
                };

                scope.onSelectProgramName = function (item, model) {
                    scope.program.programName = model;
                    scope.callback();
                };
            }
        };
    });

    module.directive('editCampusAddress', function (codeSvc, rfrSvc, organizationSvc, helperSvc, programCampusTypes, readinessSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'editCampusAddress.html',
            scope: {
                addresses: '=',
                container: '=',
                isOriginalRecord: '<?',
                rfr: '<?',
                readiness: '<?'
            },
            transclude: {
                'buttons': '?buttons'
            },
            link: function (scope, elem, attrs) {
                scope.isOriginalRecord = (attrs.isOriginalRecord === 'false') ? false : true;
                scope.isCreateMode = false;
                scope.isUpdateMode = false;
                scope.isMainOnly = scope.container ? scope.container.programCampusTypeId === 1 : false;
                scope.campusTypeIsSelected = scope.addresses.length > 0;
                scope.selectedCampus = {};
                scope.noResultsMsg = '+ Add a new campus';
                scope.helpText = {
                    locationType: 'An accredited program may be offered at the institution’s main location, at both the main location and one or more additional locations, only at another location, or only online.  Please indicate the location type and complete the address section when appropriate.',
                    distanceLearning: 'If a program is offered at another location than the institution’s main location, please provide an estimate of the number of miles from the institution’s main location to the other location.  This information assists in the onsite visit planning and execution.'
                };
                scope.mode = {
                    rfr: (attrs.rfr) ? true : false,
                    readiness: (attrs.readiness) ? true : false,
                    programEdit: (attrs.rfr || attrs.readiness) ? true : false
                };

                intializeShowHideFields();

                scope.add = function (isMain) {
                    var newAddress = (scope.mode.programEdit) ? getNewProgramAddress(isMain) : getNewAddress(isMain);

                    if (isMain) {
                        if (scope.mode.programEdit) angular.copy(scope.mainCampusAddressDto, newAddress);
                        
                        markNewAddress(newAddress);
                        scope.addresses.splice(0, 0, newAddress);
                    } else {
                        markNewAddress(newAddress);
                        scope.addresses.push(newAddress);
                    }

                    function markNewAddress(address) { address.isNew = scope.mode.programEdit; }

                    function getNewAddress(isMain) {
                        return {
                            addressDto: {
                                addressId: 0,
                                isDeleted: false
                            },
                            addressId: 0,
                            changeId: null,
                            isDeleted: false,
                            isMainCampus: (isMain) ? true : false,
                            programReviewCampusId: 0,
                            programReviewId: scope.container.programReviewId
                        };
                    }

                    function getNewProgramAddress(isMain) {
                        return {
                            addressDto: {
                                addressId: 0,
                                isDeleted: false
                            },
                            addressId: 0,
                            isDeleted: false,
                            isMainCampus: (isMain) ? true : false,
                        };
                    }
                };

                scope.changeCountry = function (address) {
                    address.isOutsideUS = address.addressDto.countryCode !== 'US';
                };

                scope.changeUS = function (address) {
                    address.addressDto.countryCode = address.isOutsideUS ? '' : 'US';
                };

                scope.delete = function (address) {
                    address.isDeleted = true;
                };

                scope.isDeletable = function (index) {
                    var isMainOnly = scope.container.programCampusTypeId === 1,
                        isMainAndOther = scope.container.programCampusTypeId === 2,
                        isOtherOnly = scope.container.programCampusTypeId === 3,
                        mainOnlyConditions = false,
                        mainAndOtherConditions = false,
                        otherOnlyConditions = false;

                    // if mainOnlyConditions is true, main address cannot be deleted

                    if (isMainOnly) mainOnlyConditions = true;

                    // if mainAndOtherConditions is true, main address and first other address cannot be deleted
                    // if otherOnlyConditions is true, first other address cannot be deleted

                    if (isMainAndOther || isOtherOnly) {
                        var otherCampusesMainAndOther = 0,
                            otherCampusesOtherOnly = 0;

                        for (var i = 0; i < scope.addresses.length; i++) {
                            if (scope.addresses[i].isMainCampus === false && scope.addresses[i].isDeleted === false) {
                                if (isMainAndOther) ++otherCampusesMainAndOther;
                                if (isOtherOnly) ++otherCampusesOtherOnly;
                            };
                        }

                        mainAndOtherConditions = isMainAndOther && (scope.addresses[index].isMainCampus === true || scope.addresses.length < 3 || otherCampusesMainAndOther === 1);
                        otherOnlyConditions = isOtherOnly && (scope.addresses.length < 2 || otherCampusesOtherOnly === 1);
                    }

                    // If any of the conditions are met, the item is NOT deleteable and returns false
                    // If no conditions are met, the item IS deleteable and returns true
                    return (mainOnlyConditions || mainAndOtherConditions || otherOnlyConditions) ? false : true;
                };

                scope.insert = function () {
                    switch (scope.container.programCampusTypeId) {
                        case 1:
                            intializeShowHideFields();
                            insertMainOnly();
                            break;
                        case 2:
                            insertMainAndOther();
                            break;
                        case 3:
                            insertOtherOnly();
                            break;
                        default:
                            // online only
                            intializeShowHideFields();
                            clearLocations();
                    }
                };

                var insertMainOnly = function () {
                    var hasMain = false,
                        hasOriginalMainRecord = false;

                    scope.isMainOnly = true;
                    scope.campusTypeIsSelected = true;

                    scope.addresses.forEach(function (address) {
                        if (address.isMainCampus && !address.isDeleted) {
                            hasMain = true;

                            if (!scope.mode.programEdit) {
                                if (address.changeId === null) hasOriginalMainRecord = true;
                            }
                        } else {
                            address.isDeleted = true;
                        }
                    });

                    if ((!scope.mode.programEdit && scope.isOriginalRecord && !hasOriginalMainRecord) || !hasMain) {
                        var isMain = true;
                        scope.add(isMain);
                    }
                };

                var insertMainAndOther = function () {
                    var hasMain = false,
                        hasOther = false,
                        hasOriginalMainRecord = false,
                        hasOriginalOtherRecord = false;

                    scope.isMainOnly = false;
                    scope.campusTypeIsSelected = true;

                    scope.addresses.forEach(function (address) {
                        if (address.isMainCampus && !address.isDeleted) {
                            hasMain = true;

                            if (!scope.mode.programEdit) {
                                if (address.changeId === null) hasOriginalMainRecord = true;
                            }
                        } else if (!address.isMainCampus && !address.isDeleted) {
                            hasOther = true;
                            if (!scope.mode.programEdit) {
                                if (address.changeId === null) hasOriginalOtherRecord = true;
                            }
                        }
                    });

                    if ((!scope.mode.programEdit && scope.isOriginalRecord && !hasOriginalMainRecord) || !hasMain) {
                        var isMain = true;
                        scope.add(isMain);
                    }

                    if ((!scope.mode.programEdit && scope.isOriginalRecord && !hasOriginalOtherRecord) || !hasOther) {
                        scope.add();
                    }
                };

                var insertOtherOnly = function () {
                    var hasOther = false,
                        hasOriginalOtherRecord = false;

                    scope.isMainOnly = false;
                    scope.campusTypeIsSelected = true;

                    scope.addresses.forEach(function (address) {
                        if (address.isMainCampus && !address.isDeleted) {
                            // does not work, for change records need to delete only records with current changeId
                            if (!scope.mode.programEdit) {
                                if ((scope.isOriginalRecord && address.changeId === null) || (!scope.isOriginalRecord && address.changeId !== null)) {
                                    address.isDeleted = true;
                                }
                            } else {
                                address.isDeleted = true;
                            }
                        } else if (!address.isMainCampus && !address.isDeleted) {
                            hasOther = true;

                            if (!scope.mode.programEdit) {
                                if (address.changeId === null) hasOriginalOtherRecord = true;
                            }
                        }
                    });

                    if (!hasOther) scope.add();
                };

                //Obsolete - don't need to check
                scope.getChangeIdVal = function () {
                    // SLF: When changeId is a factor, review to confirm when
                    // isCreateMode || isUpdateMode would be true vs both false
                    if (scope.isCreateMode || scope.isUpdateMode) {
                        return '!null';
                    } else {
                        return null;
                    }
                };

                scope.copyAddress = function (oldAddress, newAddress) {
                    var index = scope.addresses.indexOf(oldAddress)

                    if (newAddress !== null) {
                        angular.copy(newAddress, scope.addresses[index]);
                    } else {
                        var isMain = false;
                        scope.addresses[index] = getNewAddress(isMain);
                    }
                };

                if (scope.mode.programEdit) {
                    var organizationId =  scope.mode.readiness ? scope.readiness.organizationId : scope.rfr.organizationId;
                    var campusData = scope.mode.readiness ? readinessSvc.getCampusLocations() : rfrSvc.getCampusLocations();
                    var campusLocations = campusData.campusLocations;
                    var numCampuses = campusLocations.length;
                    var mainCampusIndex = 0;
                    var allowDuplicates = true;
                    var validationCampusData = scope.mode.readiness ? readinessSvc.getCampusLocations(allowDuplicates) : rfrSvc.getCampusLocations(allowDuplicates);

                    //values used within validateCampusAddress directive
                    scope.validationMainCampusCount = validationCampusData.mainCampusCount;
                    scope.validationOtherCampusCount = validationCampusData.otherCampusCount;
                    scope.validationOtherCampuses = validationCampusData.campusLocations;
                    scope.addedSecondMainCampus = false;
                    
                    //load organizations main address
                    if (numCampuses > 0 && campusLocations[mainCampusIndex].isMainCampus) {
                        scope.mainCampusAddressDto = campusLocations.shift();
                    } else {
                        if (organizationId && !scope.mode.readiness) {
                            organizationSvc.getOrganizationByUser(organizationId).then(function (data) {
                                var organization = helperSvc.getItem(data)
                                scope.mainCampusAddressDto = rfrSvc.convertOrgToCampusDto(organization);
                            });
                        } else {
                            scope.mainCampusAddressDto = readinessSvc.convertOrgToCampusDto(readinessSvc.data.selectedRR.organizationJson);
                        }
                    }

                    //values used within editCampusAddress directive
                    scope.rfrOtherCampuses = campusLocations;
                    scope.mainCampusCount = campusData.mainCampusCount;
                    scope.otherCampusCount = campusData.otherCampusCount;

                    //remove main campus address, not needed in validateCampusAddress directive
                    if (scope.validationOtherCampuses.length > 0 && scope.validationOtherCampuses[mainCampusIndex].isMainCampus) {
                        scope.validationOtherCampuses.shift();
                    }

                    scope.searchCampus = function (val) {
                        var campusResults = [];
                        var searchEntries = val.split(' ');

                        scope.rfrOtherCampuses.forEach(function (campus) {
                            var textFound = false;
                            
                            searchEntries.forEach(function (searchString) {
                                var currentCampusName = campus.campusName.toLowerCase();
                                var currentSearchString = searchString.toLowerCase();

                                textFound = helperSvc.strContains(currentCampusName, currentSearchString);
                            });

                            if (textFound) campusResults.push(campus);
                        });

                        scope.hasSearched = true;

                        return campusResults;
                    };

                    scope.onSelectCampus = function (oldAddress, selectedAddress) {
                        var isNew = oldAddress.isNew;

                        scope.copyAddress(oldAddress, selectedAddress);
            
                        if (isNew) oldAddress.isNew = true;
                    };

                    scope.noResultsFunc = function () {
                        scope.noResults = false;
                        scope.forceShowCampusFields = true;
                    };
                } // end isProgramMode section

                scope.showCampusFields = function (address) {
                    return (
                        (!scope.mode.programEdit || address.isMainCampus) ||
                        (!address.isMainCampus && address.campusName && address.addressDto.address2) ||
                        (scope.forceShowCampusFields)
                    ) ? true : false;
                };

                scope.programCampusTypeOptions = programCampusTypes;

                codeSvc.getCountries().then(function (data) {
                    scope.countryOptions = data.value;
                });

                codeSvc.getStates().then(function (data) {
                    scope.stateOptions = data.value;
                });

                function clearLocations () {
                    // Need to empty existing array object rather than create a new one because campusEditCtrl.save is a closure wrapped around 
                    // original addresses array. See also https://davidwalsh.name/empty-array 
                    scope.addresses.length = 0; // = [];
                    scope.isMainOnly = false;
                    scope.campusTypeIsSelected = false;
                }

                function intializeShowHideFields() {
                    scope.hasSearched = false;
                    scope.forceShowCampusFields = false;
                }
            }
        };
    });
    
    module.directive('validateCampusAddress', function (rfrSvc, helperSvc) {
        return {
            restrict: 'E',
            require: 'ngModel',
            link: function (scope, element, attrs, ctrl) {
                var editCampusCtrlScope = scope.$parent.$parent;

                //if in rfr mode enable validation of addresses
                if (editCampusCtrlScope.isRfrMode) {
                    scope.validate = function (newValue, oldValue) {
                        //pull values from grandparent scope because they cant be local to this scope because it resets
                        scope.mainCampusCount = editCampusCtrlScope.validationMainCampusCount;
                        scope.otherCampusCount = editCampusCtrlScope.validationOtherCampusCount;
                        scope.rfrOtherCampuses = editCampusCtrlScope.validationOtherCampuses;

                        scope.mainCampusAddressDto = editCampusCtrlScope.mainCampusAddressDto;

                        if (isValidAddress(newValue, oldValue)) {
                            ctrl.$setValidity('checkAddress', true);
                        }
                        else {
                            ctrl.$setValidity('checkAddress', false);
                        }
                    };

                    //watch for address changes to trigger validation
                    scope.$watch('address', scope.validate, true);

                    function isValidAddress(newAddress, oldAddress) {
                        var otherConflicts = 0;
                        var updatedPreviousAddress = false;
                        var validateMainAddress = true;

                        if ((scope.mainCampusCount === 0) && (newAddress.isNew === true)) {
                            validateMainAddress = false;
                        }

                        if (scope.mainCampusCount === 1 && !newAddress.isNew) {
                            validateMainAddress = false;
                        }

                        if (validateMainAddress) {
                            //dont allow different main campus address data if more than one copy exists
                            if (scope.mainCampusCount > 1 && !isValidAddressPair(newAddress, scope.mainCampusAddressDto) ||
                                scope.mainCampusCount > 1 && newAddress.isMainCampus && !isSameAddress(newAddress, scope.mainCampusAddressDto)) {
                                return false;
                            }

                            //dont allow different main campus address when it has just been added
                            if (newAddress.isNew && newAddress.isMainCampus && !isSameAddress(newAddress, scope.mainCampusAddressDto)) {
                                return false;
                            }
                        }

                        for (var i = 0; i < scope.rfrOtherCampuses.length; i++) {
                            var otherCampus = angular.copy(scope.rfrOtherCampuses[i]);

                            //dont allow addresses that have common properties to be newly added
                            if (newAddress.isNew && !isValidAddressPair(newAddress, otherCampus)) {
                                otherConflicts += 1;
                            }

                            //update old version of other campus address within array so that validation always works
                            if (!updatedPreviousAddress && !newAddress.isMainCampus && isSameAddress(otherCampus, oldAddress) && !newAddress.isNew) {
                                angular.copy(newAddress, scope.rfrOtherCampuses[i]);
                                //only update one version of this address
                                updatedPreviousAddress = true;
                            }

                            if (scope.otherCampusCount > 1 && !isValidAddressPair(newAddress, otherCampus)) {
                                otherConflicts += 1;
                            }
                        }

                        //if there are at least 2 conflicts then address must be conflicting with something other than itself, return invalid
                        if (otherConflicts > 1) {
                            return false;
                        }

                        return true;
                    }

                    function isValidAddressPair(lAddress, rAddress) {
                        var matchingCampusNames = isCampusNameSame(lAddress, rAddress);
                        var matchingAddresses = isAddressSame(lAddress, rAddress);

                        if (matchingCampusNames && !matchingAddresses) {
                            return false;
                        }
                        else if (!matchingCampusNames && matchingAddresses) {
                            return false;
                        }

                        return true;
                    }

                    function isSameAddress(lhs, rhs) {
                        return isCampusNameSame(lhs, rhs) && isAddressSame(lhs, rhs);
                    }

                    function isCampusNameSame(lhs, rhs) {
                        return lhs.campusName === rhs.campusName;
                    }

                    function isAddressSame(lhs, rhs) {
                        var lhAddress = getAddressString(lhs);
                        var rhAddress = getAddressString(rhs);

                        return lhAddress === rhAddress;
                    }

                    function getAddressString(address) {
                        var text = address.isOutSideUs +
                            address.addressDto.address1 +
                            address.addressDto.address2 +
                            address.addressDto.address3 +
                            address.addressDto.city +
                            address.addressDto.province +
                            address.addressDto.stateCode +
                            address.addressDto.postalCode +
                            address.distanceMiles +
                            address.note;

                        //in some cases text is not a string (e.g. NaN), converting to be sure
                        return String(text).toLowerCase();
                    }
                }
            }
        };
    });

}(angular.module('program')));