(function (module) {

    var rfrCommissionReviewOptionsCtrl = function ($scope, $uibModal, $state, $stateParams, helperSvc, rfrLogicSvc, rfrSvc, alertSvc, oauth, rfrProcessStatuses, commissionIds, contactRoleTypes, rfrValidationSvc, currentUser, currentUserHelper, messageTemplateTypes, membershipSvc, messageSvc, institutionReviewOptionsHelperSvc, programReviewTypeIds, onsiteVisitExpectations, onsiteVisitExpectationNames) {
        var model = this;
        var orgId = $stateParams.organizationId;
        var isPresident = currentUserHelper.isPresident(orgId);
        var isDelegateForPresident = currentUserHelper.isDelegateForPresident(orgId);

        var templateRoot = '/Apps/rfr/templates/';

        model.commissionIds = commissionIds;
        model.isAdmin = oauth.isAdmin;
        model.title = 'Commission Review Options';
        model.formatDate = helperSvc.formatDate;
        model.getBooleanText = helperSvc.getBooleanText;
        model.currentStatus = null;
        model.isEditable = false;
        model.isReturnable = false;
        model.readOnlyText = 'Read-only';
        //model.onsiteVisitExpectations = onsiteVisitExpectations;
        //model.getOnsiteVisitExpectationNames = onsiteVisitExpectationNames;

        model.helpText = {
            general: 'This required section notifies ABET of the institution’s approval for ABET to place observers on an ABET visit team and of the institution’s suggestion for the start date of the onsite visit.  It is the institution’s prerogative to accept observers to the onsite visit team.',
            pevs: 'ABET’s calculation for the required number of Program Evaluators (PEVs) are used for the institution’s budgeting and planning purposes. The institution will not be able to edit this field.  If there is a question about the required number of PEVs, please contact ABET at accreditation@abet.org.',
            observers: 'For an institution located <strong>inside the U.S.</strong>, the local educational authority is usually the applicable state board. For an institution <strong>outside the U.S.</strong>, the local educational authority may be the Ministry of (Higher) Education, recognition agency of the degree-granting institution, and/or the accrediting agency that may or may not have entered a Mutual Recognition Agreement (MRA)/Memorandum of Understanding (MOU) with ABET. A list of the agencies that have a MRA/MOU with ABET can be found at www.abet.org/global-engagement',
            DEICriteria: 'Institution with EAC programs can opt-in to pilot changes to Criterion 5 & 6 of the General Criteria regarding the principles of diversity, equity, and inclusion (DEI)'
        }

        activate();

        model.editReviewOption = function(commission) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: templateRoot + 'rfrCommissionReviewOptionsEdit.html',
                size: 'lg',
                controller: 'rfrCommissionReviewOptionsEditCtrl',
                resolve: {
                    commission: function() {
                        return commission;
                    }
                }
            });
        };

        model.modes = {
            PREVIEWONLY: 1,
            PREVIEWANDSUBMIT: 2,
            SUBMITONLY: 3,
            PREVIEWNORSUBMIT: 4
        };

        model.statuses = {
            EDITABLE: 0,
            PENDINGPRESIDENTSUBMISSION: 1,
            PENDINGFINALSUBMISSION: 2,
            SUBMITTEDTOPRESIDENT: 3,
            AWAITINGSUBMISSION: 4,
            DEFAULT: 5,
            PENDINGABETSUBMISSION: 6,
            SUBMITTEDTOABET: 7,
            SUBMITEDFORPREREVIEW: 8,
            PENDINGABETPREREVIEWSSUBMISSION: 9,
            ABETACCEPTED: 10
        }

        model.setStatus = function (scope, commission, isPreviewMode, isSubmitMode) {
            var isUserDesignated = oauth.isDesignated(orgId, commission.commissionId);
            var statusCode = model.statuses.DEFAULT;
            var isEditable = false;
            model.isReturnable = false;
            var isDelegateForPrimary = currentUserHelper.isDelegateForPrimary(orgId, commission.commissionId);

            if (!commission.submittedTimestamp && (isUserDesignated || isPresident || isDelegateForPresident || isDelegateForPrimary) && getCommissionDisplayStatus(isPreviewMode, isSubmitMode) === model.modes.PREVIEWNORSUBMIT) {
                isEditable = true;
                statusCode = model.statuses.EDITABLE;
            }
            if (!commission.submittedTimestamp && getCommissionDisplayStatus(isPreviewMode, isSubmitMode) === model.modes.PREVIEWONLY) {
                if (isUserDesignated || isPresident || isDelegateForPresident || isDelegateForPrimary) {
                    if (model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.WAITINGFORREVISEDRFRSUBMISSION)
                        statusCode = model.statuses.PENDINGABETSUBMISSION;
                    if (model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.WAITINGFORSUBMISSIONTOABET || model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.PROJECTEDRFR)
                        statusCode = model.statuses.PENDINGABETPREREVIEWSSUBMISSION;
                }
                else {
                    statusCode = model.statuses.DEFAULT;
                }
            }
            if (!commission.submittedTimestamp && getCommissionDisplayStatus(isPreviewMode, isSubmitMode) === model.modes.PREVIEWANDSUBMIT) {
                statusCode = model.statuses.AWAITINGSUBMISSION;
            }
            if (commission.submittedTimestamp && model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.WAITINGFORABETPREREVIEW) {
                statusCode = model.statuses.SUBMITEDFORPREREVIEW;
            }
            if (commission.submittedTimestamp && !isSubmitMode && model.data.rfr.rfrProcessStatusId !== rfrProcessStatuses.WAITINGFORABETPREREVIEW) {
                if (model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.WAITINGFORPRESIDENTSIGNATURE) {
                    statusCode = model.statuses.PENDINGPRESIDENTSUBMISSION;
                } else {
                    statusCode = model.statuses.PENDINGFINALSUBMISSION;
                }
            }
            if (commission.submittedTimestamp && isSubmitMode && model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.WAITINGFORPRESIDENTSIGNATURE) {
                //set multiselect variables so pres has option to reject
                model.rfrCommissions = rfrSvc.getCommissionDtos(model.data.rfr);
                model.rejectedCommissions = [];
                model.isReturnable = true;
                statusCode = model.statuses.PENDINGPRESIDENTSUBMISSION;

            }
            if (model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.WAITINGFORABETACCEPTANCE) {
                statusCode = model.statuses.SUBMITTEDTOABET;
            }
            if (model.data.rfr.rfrProcessStatusId === rfrProcessStatuses.ABETACCEPTED) {
                statusCode = model.statuses.ABETACCEPTED;
            }
            if (!isPreviewMode && model.isAdmin()) {
                model.isEditable = true;
                statusCode = model.statuses.EDITABLE;
            }

            scope.statusCode = statusCode;
            scope.isEditable = isEditable;

            scope.requiredPevs = model.getRequiredPevs(commission.jsonContents);

            //It causes missing review options. And implenting new NPC process, this longer needed to check. 11/15/2021
            //scope.reviewOptionsRequired = scope.requiredPevs > 0 || commission.jsonContents.programs.some(function (program) {
            //    // Visits require PEVs except for termination visits (TV) which only require a team chair (TC)
            //    return program.programReviewTypeCode === programReviewTypeIds.TERMINATIONVISIT;
            //});
        };

        model.submitForAbetPreReview = function (commission) {
            rfrValidationSvc.validate(model.data.rfr, commission.commissionId).then(function (results) {
                if (results.length === 0) {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: templateRoot + 'rfrCommissionSubmission.html',
                        size: 'lg',
                        controller: 'rfrCommissionSubmissionCtrl',
                        resolve: {
                            commission: function () {
                                return commission;
                            }
                        }
                    });
                } else {
                    alertSvc.openModalAlert("The commission you are trying to submit is not valid. Please look at the errors in each tab at the top of the page and fix them.");
                }
            });

        };

        model.submitToAbet = function (commission) {

            rfrValidationSvc.validate(model.data.rfr, commission.commissionId).then(function (results) {
                if (results.length === 0) {
                    var confirmationMsg = 'Are you sure you want to submit <em><strong>' + commission.commissionAbbreviatedName + '</strong></em> for review? This procedure is irreversible.';

                    alertSvc.confirm(confirmationMsg, submit, commission);
                    
                } else {
                    alertSvc.openModalAlert("The commission you are trying to submit is not valid. Please look at the errors in each tab at the top of the page and fix them.");
                }
            });

            function submit() {
                rfrSvc.submitToAbet(commission).then(function (data) {
                    var updatedRfr = rfrSvc.data.rfr;
                    if (updatedRfr.rfrProcessStatusId === rfrProcessStatuses.WAITINGFORABETACCEPTANCE) {
                        alertSvc.addAlertSuccess("RFE succesfully submitted back to ABET");
                        $state.go('rfeSummary', {
                            rfrId: updatedRfr.rfrId,
                            organizationId: updatedRfr.organizationId
                        });
                    }

                });
            }
        };

        model.rejectCommissionRfr = function () {
            rfrSvc.rejectCommissionRfr(model.rejectedCommissions, model.data.rfr, true).then(function () {
                alertSvc.addAlertSuccess("Commissions successfully returned.");
            });
        };

        model.rfrHasCommissionDtos = function () {
            return model.data && model.data.rfr && model.data.rfr.rfrCommissionDtos;
        };

        model.getRequiredPevs = institutionReviewOptionsHelperSvc.getRequiredPevs;
 
        model.isFocusedReport = institutionReviewOptionsHelperSvc.isFocusedReport;
        
        model.isCriteriaOption = function () {
            var result = false;
            angular.forEach(model.data.rfr.rfrCommissionDtos, function (commission) {
                if (commission.jsonContents.isPhaseInOption !== null && commission.jsonContents.inPhaseOption !== undefined)
                    result = true;
            });
            return result;
        }


        function getCommissionDisplayStatus(isPreviewMode, isSubmitMode) {
            if (isPreviewMode && !isSubmitMode) {
                return model.modes.PREVIEWONLY;
            }
            else if (isPreviewMode && isSubmitMode) {
                return model.modes.PREVIEWANDSUBMIT;
            }
                //possibly not needed
                else if (!isPreviewMode && isSubmitMode) {
                    return model.modes.SUBMITONLY;
            }
            else if (!isPreviewMode && !isSubmitMode) {
                return model.modes.PREVIEWNORSUBMIT;
            }
        }

        function activate() {
            model.data = rfrSvc.data;
        }
    };
    
    module.controller('rfrCommissionReviewOptionsCtrl', rfrCommissionReviewOptionsCtrl);

}(angular.module('rfr')));