(function (module) {
    var userReviewCtrl = function ($scope, $state, $stateParams, $q, $uibModal, currentUser, helperSvc, alertSvc, barSvc, reviewTeamMemberSvc, messageSvc,
        messageTemplateTypes, contactTypes, userReviewSvc, reviewTypes, teamMemberTypeNames, teamMemberStatusTypes, rfrSvc, rfrProcessStatuses,
        globalConstantSvc, currentOrganization, contactRoleTypes, statementSvc, membershipSvc, currentUserHelper, offlineSvc, statementStatusNames,
        statementStatuses, teamMemberTypeIds, offlineStatementSvc, statementTypeNamesById, codeSvc, oauth, statementStorageSvc, assignmentSvc,
        statementTypeNames, statementTypeIds, statementTypeLabel, reviewProcessStatus, typeConstSvc, personSvc, onsiteVisitRestrictionStatuses,$rootScope
    ) {

        var model = this;
        var academicYear = helperSvc.getAcademicYear(false); 
        var previousAcademicYear = academicYear - 1;
        var nextAcademicYear = academicYear + 1;

        var SENT_DRAFT_STATEMENT_TO_INSTITUTION_NAME = typeConstSvc.getReviewProcessStatusName(reviewProcessStatus.SENT_DRAFT_STATEMENT_TO_INSTITUTION);
        var SENT_FINAL_STATEMENT_TO_INSTITUTION_NAME = typeConstSvc.getReviewProcessStatusName(reviewProcessStatus.SENT_FINAL_STATEMENT_TO_INSTITUTION);
        
        model.excludedYear = 2017;//these wil be deleted after this year
        model.previousAcademicYear = previousAcademicYear
        model.currentAcademeicYear = academicYear;
        model.teamMemberTypeNames = teamMemberTypeNames;
        model.getYearSpan = helperSvc.getYearSpan;
        model.formatDateWithCurrent = helperSvc.formatDateWithCurrent;
        model.formatDate = helperSvc.formatDate;
        model.getNextDueDateText = statementSvc.getNextDueDateText;
        model.currentDate = new Date();
        model.statementTypeNames = angular.copy(statementTypeNames);
        model.statementTypeLabel = angular.copy(statementTypeLabel);
        model.statementStatusNames = angular.copy(statementStatusNames);
        
        //model.rfeTitle = previousAcademicYear + "–" + nextAcademicYear + " RFEs";
        model.rfeTitle = "Recent RFEs";

        model.previousDate = "Previous Reviews";
        model.currentDate = "Reviews " + previousAcademicYear + "–" + (academicYear - 2000) + "";
        model.upcomingDate = "Reviews " + academicYear + "–" + (nextAcademicYear - 2000) + "";

        model.rfeVsReviewMsg = 'A Request for Evaluation (RFE) is a  written request from an institution for one or more programs to be considered for an ABET accreditation review. Once a RFE submission has been received and accepted by ABET it is considered to be an upcoming Review. Review details and assignment progress for team chairs and program evaluators (PEVs) can be monitored by clicking on a review name below.';
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.hasPendingPevAcceptance = false;
        model.hasPendingInstitutionAcceptance = false;
        model.hasPendingTCEDAcceptance = false;
        model.isInstitutionUser = false;
        model.isVolunteer = false;
        model.hasSingleRFE = false;
        model.hasRfeList = false;
        model.isDataReady = false;
        model.hasReviews = false;
        model.isInAcceptProccess = false;
        model.isRoleAdmin = oauth.isAdmin();
        model.sortReverse = false;

        model.select = offlineStatementSvc.select;
        
        model.goOffline = offlineStatementSvc.goToOfflineState;

        model.getSelectedReviews = offlineStatementSvc.getSelectedReviews;
        model.canEditOffline = offlineStatementSvc.canEditOffline;
        model.clearSelectedReviews = offlineStatementSvc.clearSelectedReviews;

        barSvc.showBar();

        model.datesConfirmed = function (review) {
            return review && review.visitStartDate && review.visitEndDate;
        };

        model.openRfr = function (rfe) {
            if(!model.isAdmin){
                currentOrganization.profile.organizationId = $stateParams.organizationId;
            }
            var state = null;
            switch (rfe.rfrProcessStatusId) {
                case rfrProcessStatuses.WAITINGFORSUBMISSIONTOABET:
                case rfrProcessStatuses.WAITINGFORPRESIDENTSIGNATURE:
                case rfrProcessStatuses.WAITINGFORREVISEDRFRSUBMISSION:
                case rfrProcessStatuses.WAITINGFORABETPREREVIEW:
                    state = 'rfe';
                    break;
                default:
                    state = 'rfeSummary';
            }

            $state.go(state, {
                organizationId: rfe.organizationId,
                rfrId: rfe.rfrId
            });
        };

        model.isAssignmentAccepted = function (review) {
            var isAdjOrInstContact = review.teamMemberTypeName == 'Institution Contact' || review.teamMemberTypeName == 'Adjunct';
            var assignmentApproved = review.teamMemberStatusId == teamMemberStatusTypes.ASSIGNMENTAPPROVED;
            return isAdjOrInstContact ? true : assignmentApproved;
        };

        model.openReviewDetail = async function (review) {
            if (model.isInAcceptProccess) return;

            if (model.isAssignmentAccepted(review)) {
                if (model.pendingTravelWaiver(review)) {
                    model.isInAcceptProccess = true;

                    var modalInstance = await openTravelWaiverModal(review);
                    try {
                        var modalPromise = await modalInstance.result;
                    } catch (err) {
                        modalPromise = 'rejected';
                    }
                    if (modalPromise !== 'completed') { model.isInAcceptProccess = false; return }
                }

                userReviewSvc.data.currentUserReviewTeam = review;

                $state.go('userReview.detail', {
                    reviewTeamId: review.reviewTeamId.toString(),
                    organizationId: review.organizationId
                });
            }
        };

        model.previousTCEDFilterOptions = { reviewStatusId: null, organizationName: null, reviewType: null, statementStatusId: null };
        model.currentTCEDFilterOptions = { reviewStatusId: null, organizationName: null, reviewType: null, statementStatusId: null };
        model.nextTCEDFilterOptions = { reviewStatusId: null, organizationName: null, reviewType: null, statementStatusId: null };
        model.searches = {
            previousTCEDReviews: null,
            currentTCEDReviews: null,
            nextTCEDReviews: null
        };

        model.tCEDFilter = function (filterOptions, reviewsName) {

            model.searches[reviewsName] = filterOptions;

            var filteredReviews = [];
            var reviews = model[reviewsName];
            for (var i = 0; i < reviews.length; i++) {
                var currentReview = reviews[i];
                if (filterOptions.reviewStatusId) {
                    if (filterOptions.reviewStatusId !== currentReview.reviewStatus)
                        continue;
                }
                if (filterOptions.organizationName) {
                    var lowerOrgName = filterOptions.organizationName.toLowerCase();
                    var lowerCurrentReviewOrgName = currentReview.organizationName.toLowerCase();
                    if (lowerCurrentReviewOrgName.indexOf(lowerOrgName) === -1)
                        continue;
                }
                if (filterOptions.reviewType) {
                    if (filterOptions.reviewType !== currentReview.reviewTypeCode)
                        continue;
                }
                if (filterOptions.statementStatusId) { //need to filter on previous statement if statementId is null and previous statement exists... means that prev statement is the one sent to institutions
                    //ALSO NEED TO ADD THE ADJUNCT STATEMENT STATUS NEEDS TO START TO THE ADJUNCT STATUS

                    if (!currentReview.previousStatement || !currentReview.previousStatement.StatementStatusId)
                        continue; //check cancelled or withdrawn reviews

                    if (parseInt(filterOptions.statementStatusId) === statementStatuses.WAITINGFORADJSUBMIT && currentReview.reviewProcessTracking && currentReview.reviewProcessTracking[0].StatusName === SENT_DRAFT_STATEMENT_TO_INSTITUTION_NAME && currentReview.previousStatement) {
                        if (currentReview.previousStatement.SubmittedToTeamMemberTypeId !== teamMemberTypeNames.ADJUNCT) {
                            continue;
                        }
                    }
                    else if (parseInt(filterOptions.statementStatusId) === statementStatuses.WAITINGFORTCSUBMIT && currentReview.reviewProcessTracking && currentReview.reviewProcessTracking[0].StatusName === SENT_DRAFT_STATEMENT_TO_INSTITUTION_NAME && currentReview.previousStatement) {
                        if (currentReview.previousStatement.SubmittedToTeamMemberTypeId !== teamMemberTypeNames.TEAMCHAIR && currentReview.previousStatement.SubmittedToTeamMemberTypeId !== teamMemberTypeNames.REPORTTEAMCHAIR && currentReview.statementTypeId !== statementTypeIds.COMMISSIONEDITING) {
                            continue;
                        }
                    }
                    else if (parseInt(filterOptions.statementStatusId) === statementStatuses.SENTTOINSTITUTION || parseInt(filterOptions.statementStatusId) === statementStatuses.RESENTTOINSTITUTION) {
                        if ((currentReview.statementId == null || currentReview.statementId === undefined) && currentReview.previousStatement) {
                            if (parseInt(filterOptions.statementStatusId) !== currentReview.previousStatement.StatementStatusId) {//capitalized here for some reason
                                continue;
                            }
                        } else 
                            continue;   
                    }
                    
                    if (parseInt(filterOptions.statementStatusId) === statementStatuses.WAITINGFORTCSUBMIT) {
                        if(parseInt(filterOptions.statementStatusId) !== currentReview.statementStatusId &&  currentReview.statementStatusId !== null)
                            continue;
                    }
                    else if (parseInt(filterOptions.statementStatusId) !== currentReview.statementStatusId)
                        continue;
                }
                filteredReviews.push(currentReview);
            }

            if (reviewsName.indexOf('previous') > -1)
                model.filteredPreviousTCEDReviews = filteredReviews;
            if (reviewsName.indexOf('current') > -1)
                model.filteredCurrentTCEDReviews = filteredReviews;
            else
                model.filteredNextTCEDReviews = filteredReviews;
        }

        var defaultSort = 'organizationSortName';
        model.previousTCEDReviewsFilter = [defaultSort];
        model.currentTCEDReviewsFilter = [defaultSort];
        model.nextTCEDReviewsFilter = [defaultSort];

        model.sortBy = function (property, reviewFilter) {//if we need to we can just add an additional parameter to take in the list name
            model[reviewFilter] = doSort(defaultSort, model[reviewFilter], property);

            function doSort(defaultSort, oldSortFilter, newSortFilter) {
                if (angular.isArray(newSortFilter) && angular.isArray(oldSortFilter)) {
                    var filtersAreEqual = newSortFilter.every(function (elem, i) { return elem === oldSortFilter[i]; });
                    if (filtersAreEqual) {
                        return newSortFilter.map(function (elem) { return "-" + elem; });
                    }
                } else {
                    // Index and default sort will always be in same order
                    if ((newSortFilter === "index" && oldSortFilter === defaultSort) ||
                        (newSortFilter === defaultSort && oldSortFilter === "index") ||
                        newSortFilter === oldSortFilter) {

                        return ("-" + newSortFilter);
                    }
                }
                return newSortFilter;
            }
        };

        var openTravelWaiverModal = async function (review) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/userReview/templates/travelWaiverModal.html',
                size: 'lg',
                controller: 'travelWaiverModalCtrl',
                resolve: {
                    review: () => review,
                }
            });
            return modalInstance;
        };

        model.openTravelWaiverPDF = async function (review) {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/Apps/userReview/templates/travelWaiverPDFModal.html',
                size: 'xl',
                controller: 'travelWaiverModalCtrl',
                resolve: {
                    review: () => review,
                }
            });
        };

        model.acceptAssignment = async function (review) {
            model.isInAcceptProccess = true;

            if (model.pendingTravelWaiver(review)) {
                var modalInstance = await openTravelWaiverModal(review);
                try {
                    var modalPromise = await modalInstance.result;
                } catch (err) {
                    modalPromise = 'rejected';
                }
                if (modalPromise !== 'completed') { model.isInAcceptProccess = false; return }
            }

            var acceptedStatus = teamMemberStatusTypes.ASSIGNMENTAPPROVED;

            // Already accepted assignment, no need to update
            if (review.teamMemberStatusId == acceptedStatus) {
                return userReviewSvc.getCurrentCycleReviews().then(function () {
                    assignCycleReviews(userReviewSvc.data.currentCycleReviews);
                    model.setAcceptanceColumnVisibility();
                });
            }

            updateReviewTeamMemberStatus({ reviewTeamMemberId: review.reviewTeamMemberId, teamMemberStatusId: acceptedStatus, rejectComment: null, startDate: new Date(), endDate: helperSvc.formatDate(new Date(review.reviewYear, 8, 30), false, false).toDateString() }).then(function () {
                assignCycleReviews(userReviewSvc.data.currentCycleReviews);
                //if tc accepts no emails sent; if pev accepts primary:TC, CC:pev, society liasons and pev assignors of this pevs lead society
                if (review.teamMemberTypeId === teamMemberTypeNames.PEV) {
                    reviewTeamMemberSvc.getMyReviewTeamMembers(review.reviewTeamId).then(function (data) {
                        var tcRecipientIds = [];
                        var pevRecipientIds = [review.personId];
                        var societyRecipientIds = [];

                        var leadSocietyId = 0;
                        for(var i =0; i < data.length; i++){                      
                            if(data[i].teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR)
                            {                              
                                tcRecipientIds.push(data[i].personId);
                                if (leadSocietyId !== 0)
                                    break;
                            }
                            if (review.reviewTeamMemberId === data[i].reviewTeamMemberId)
                            {
                                leadSocietyId = data[i].leadSocietyId;
                                if (tcRecipientIds.length > 0)
                                    break;
                            }
                        }

                        //send email to tc and copy education liaisions and pevassignosr who are only resposible for the commission.
                        var roles = [contactRoleTypes.EDUCATIONLIASON, contactRoleTypes.PEVASSIGNOR];
                        membershipSvc.getOAuthOrganizationUsersByOrganizationId(leadSocietyId, roles).then(function (users) {
                            var orgUsers = helperSvc.getValue(users);                           
                            angular.forEach(orgUsers, function (person) {
                                if (person.roleId === contactRoleTypes.EDUCATIONLIASON ||
                                    (person.roleId === contactRoleTypes.PEVASSIGNOR && person.oAuthOrganizationUserDesignations &&
                                        helperSvc.arrayContainsByPropertyValue(person.oAuthOrganizationUserDesignations, 'designationId',
                                            model.data.currentCycleReviews[0].commissionId))) {
                                    societyRecipientIds.push(person.personId);
                                }
                            });
                            var pevAcceptMessageParameters = [{ messageParameterName: 'OrganizationName', messageParameterKey: review.organizationId.toString() }];

                            var recipients = messageSvc.createRecipientDtos(tcRecipientIds, contactTypes.VOLUNTEERACTIVITYONLY);
                            var ccdRecipients = [...messageSvc.createRecipientDtos(pevRecipientIds, contactTypes.VOLUNTEERACTIVITYONLY), ...messageSvc.createRecipientDtos(societyRecipientIds, contactTypes.SOCIETYCONTACTONLY)];

                            messageSvc.buildAndSendMessage(recipients, ccdRecipients, messageTemplateTypes.PEVACCEPTED, pevAcceptMessageParameters)
                        });                      
                    });
                }
                model.setAcceptanceColumnVisibility();
                alertSvc.addAlertSuccess("Your assignment has been successfully accepted.");
           });
        };

        model.openDeclineModal = function (review) {
            if(userReviewSvc.isChairPerson(review, true)){
                alertSvc.openModalAlert('<p class="synopsis">Please Contact the person below.</p>'
                                    + '<div class="row">Director, Accreditation Operations</div>'
                                    + '<div class="row">(410) 347-7736 </div>'
                                    + '<div class="row">accreditation@abet.org</div>',
                                    'Decline an Assignment');
            }
            else {
                //get reviewTeam to get team chair, this decline is for a pev
                reviewTeamMemberSvc.getMyReviewTeamMembers(review.reviewTeamId).then(function (persons) {
                    for (var i = 0; i < persons.length; i++) {
                        if (persons[i].teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR)
                        {
                            var name = persons[i].firstName + ' ' + persons[i].lastName;
                            globalConstantSvc.getGlobalVariable(globalConstantSvc.nameConstants.personVolunteerEmail, persons[i].personId).then(function (email) {
                                var email = email && email.value ? email.value : 'No email at this time.';
                                alertSvc.openModalAlert('<p class="synopsis">Please Contact the person below.</p>'
                                  + '<div class="row">Email the teamchair ' + name+ ' at: ' + email + '</div>',
                                  'Decline an Assignment');

                            });
                        }
                    }
                });
            }
            
        };

        model.isTeamChair = function (review) {
            return review.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR;
        };

        model.isPev = function (reviewTeam) {
            return userReviewSvc.isTeamMemberType([teamMemberTypeNames.PEV], reviewTeam);
        }

        model.setAcceptanceColumnVisibility = function () {
            // set 'hasPendingXXXAcceptance' to true if a review in that category is needs a team member to accept assignment
            model.hasPendingPevAcceptance = model.nextPevReviews.some(model.pendingAcceptance);
            model.hasPendingInstitutionAcceptance = model.nextInstitutionReviews.some(model.pendingAcceptance);
            model.hasPendingTCEDAcceptance = model.nextTCEDReviews.some(model.pendingAcceptance);
        };

        model.pendingAcceptance = function (reviewTeam) {
            return reviewTeam.teamMemberStatusId === teamMemberStatusTypes.APPROVEDBYINSTITUTIONWAITINGFORVOLUNTEERACCEPT;
        };

        model.pendingTravelWaiver = function (review) {
            var result = [teamMemberTypeNames.PEV, teamMemberTypeNames.TEAMCHAIR, teamMemberTypeNames.COTEAMCHAIR, teamMemberTypeNames.PROGRAMOBSERVER, teamMemberTypeNames.VISITOBSERVER].includes(review.teamMemberTypeId) &&
                review.reviewTypeCode !== 'IR' &&
                (review.onsiteVisitRestrictionStatusId === null ||
                    review.onsiteVisitRestrictionStatusId === onsiteVisitRestrictionStatuses.WITHOUTRESTRICTION ||
                    review.onsiteVisitRestrictionStatusId === onsiteVisitRestrictionStatuses.WITHRESTRICTION) &&
                !!review.agreement && !model.travelWaiverOnFile(review);

            return result;
        };

        model.travelWaiverOnFile = function (review) {
            return review && review.agreement && !angular.equals(review.agreement, {}) && review.agreement.answer;
        };

        model.getReviewType = function (type) {
            return reviewTypes[type];
        }

        model.getStatementStatus = function (reviewTeam) {
            if (reviewTeam.statementStatusId) {
                //if(reviewTeam.submittedToTeamMemberTypeId === teamMemberTypeNames.TEAMCHAIR && (reviewTeam.statementTypeId === statementTypeIds.DRAFT && (reviewTeam.statementStatusId === statementStatuses.SENTTOINSTITUTION || reviewTeam.statementStatusId === statementStatuses.RESENTTOINSTITUTION))){
                //    return "Waiting on TC to Start Final";
                //}

                if (reviewTeam.statementTypeId === statementTypeIds.DRAFT) {
                    return statementTypeNames.DRAFT + " Statement " + statementStatusNames[reviewTeam.statementStatusId]
                }
                else if (reviewTeam.statementTypeId === statementTypeIds.FINAL) {
                    if (reviewTeam.statementStatusId === statementStatuses.ADMINPREPFORSEND) {
                        return 'Admin Received Draft Final';
                    }
                    return statementTypeNames.FINAL + " Statement " + statementStatusNames[reviewTeam.statementStatusId];
                }
                else if (reviewTeam.statementTypeId === statementTypeIds.POSTTHIRTYDAY) {
                    if (reviewTeam.statementStatusId === statementStatuses.ADMINPREPFORSEND) {
                        return 'Admin Received Post-30 Day Statement';
                    }
                    return statementTypeNames.POST30DAY + " Statement " + statementStatusNames[reviewTeam.statementStatusId];
                }
                else if (reviewTeam.statementTypeId === statementTypeIds.COMMISSIONEDITING) {
                    return statementTypeNames.COMMISSIONEDITING + " Statement " + statementStatusNames[reviewTeam.statementStatusId];
                }
            } else if (reviewTeam.reviewProcessTracking && reviewTeam.reviewProcessTracking[0].StatusName === SENT_DRAFT_STATEMENT_TO_INSTITUTION_NAME && reviewTeam.previousStatement && reviewTeam.previousStatement.SubmittedToTeamMemberTypeId) { //THIS IS FOR WHEN FINAL STATEMENT NEEDS TO BE STARTED
                var nextStatementTypeId = reviewTeam.previousStatement.StatementTypeId <= statementTypeIds.POSTTHIRTYDAY ? reviewTeam.previousStatement.StatementTypeId + 1 : statementTypeIds.COMMISSIONEDITING; //need to check business logic here
                return 'Waiting on ' + teamMemberTypeIds[reviewTeam.previousStatement.SubmittedToTeamMemberTypeId] + ' to start the ' + statementTypeNamesById[nextStatementTypeId] + ' Statement.'
            } else if (reviewTeam.reviewProcessTracking && reviewTeam.reviewProcessTracking[0].StatusName === SENT_FINAL_STATEMENT_TO_INSTITUTION_NAME)
                return 'Statement Complete'
            else
                return 'Statement Not Started'
        };

        model.getStatementTypeNames = function (reviewTeam) {
            if (reviewTeam.statementStatusId) {
                if (reviewTeam.statementTypeId === statementTypeIds.DRAFT) {
                    return statementTypeNames.DRAFT;
                }
                else if (reviewTeam.statementTypeId === statementTypeIds.FINAL) {
                    return statementTypeNames.FINAL;
                }
                else if (reviewTeam.statementTypeId === statementTypeIds.POSTTHIRTYDAY) {
                    return statementTypeNames.POST30DAY;
                }
                else if (reviewTeam.statementTypeId === statementTypeIds.COMMISSIONEDITING) {
                    return statementTypeNames.COMMISSIONEDITING;
                }
            }
        };

        model.getReviewStatus = function(reviewTeam){
            if(reviewTeam.programActionCodes && reviewTeam.programActionCodes.length > 0){
                for(var i = 0; i < reviewTeam.programActionCodes.length; i++){
                    var ac = reviewTeam.programActionCodes[i];
                    if(ac.actionCode === 'W' || ac.actionCode === 'C'){
                        if(i === (reviewTeam.programActionCodes.length - 1))
                            return "Cancelled/Withdrawn";
                        else
                            continue;
                    }
                    else
                        return reviewTeam.reviewProcessTracking ? reviewTeam.reviewProcessTracking[0].StatusName : 'N/A'
                }
            }
        }

        activate();

        function activate() {
            if (!offlineSvc.isAppOffline()) {
                statementStorageSvc.syncOfflineData().catch(function (error) {
                    alertSvc.addAlertDanger("Offline statement data could not be synchronized.");
                }).finally(function () {
                    doActivation();
                });
            } else {
                doActivation();
            }

            function doActivation() {
                // Make sure no reviews remain selected for offline viewing  
                model.clearSelectedReviews();

                var dataSourceArray = [
                    {
                        dataHolder: model,
                        svcCallback: [codeSvc.getReviewProcessStatuses],
                        dataLocationName: 'reviewStatuses',
                        helperCallback: helperSvc.getValue
                    },
                    {
                        storeData: false,
                        svcCallback: [userReviewSvc.getRfesForUser],
                        svcCallbackArguments: [],
                        odataResource: true
                    },
                    {
                        storeData: false,
                        svcCallback: [userReviewSvc.getCurrentCycleReviews],
                        svcCallbackArguments: [],
                        odataResource: true,
                    },
                    {
                        dataHolder: model,
                        dataLocationName: 'statementStatuses',
                        svcCallback: codeSvc.getStatementStatuses,
                        helperCallback: helperSvc.getResults
                    },
                    {
                        dataHolder: model,
                        dataLocationName: 'reviewTypes',
                        svcCallback: codeSvc.getReviewTypes,
                        helperCallback: helperSvc.getResults
                    }
                ]

                helperSvc.getData(dataSourceArray).then(async function () {
                    model.data = userReviewSvc.data;
                    model.isVolunteer = userReviewSvc.isVolunteer();
                    model.isInstitutionUser = userReviewSvc.isInstitutionUser() || currentUserHelper.isInstitutionUser();

                    if (model.isInstitutionUser) {
                        model.hasRfeList = model.data.rfeList.length > 1;
                        model.hasSingleRFE = model.data.rfeList.length === 1;
                    };

                    model.currentRFE = model.data.rfeList[0] || null;

                    await assignCycleReviews(userReviewSvc.data.currentCycleReviews);

                    barSvc.hideBar();
                    model.isDataReady = true;

                    $rootScope.$apply();
                });
            }
        }

        async function assignCycleReviews(userReviewTeams) {
            if (!userReviewTeams) { return; }

            //first add the most recent status and attach any travel waiver agreements
            await Promise.all(
                userReviewTeams.map(review => {
                    review.reviewStatus = review.reviewProcessTracking ? review.reviewProcessTracking[0].OrderNumber : -1 // arbitrary, should never be this
                    if (!review.agreement && review.reviewTeamMemberId) {
                        return userReviewSvc.getReviewTeamMemberTravelAgreement(review.reviewTeamMemberId).then((agreement) => review.agreement = agreement);
                    } else {
                        return $q.when(true);
                    };
                })
            );

            // Filter down to visible reviews for previous cycle
            model.allPreviousCycleReviews = userReviewSvc.filterVisibleReviews(userReviewTeams);
            model.previousInstitutionReviews = model.allPreviousCycleReviews.institutionReviews.filter(arr => arr.reviewYear < academicYear);
            model.hasPreviousInsitutionReviews = model.previousInstitutionReviews.length > 0;
            model.previousTCEDReviews = model.allPreviousCycleReviews.teamChairAndEditorReviews.filter(arr => arr.reviewYear === academicYear - 1);
            model.hasPreviousTCEDReviews = model.previousTCEDReviews.length > 0;
            model.previousPevReviews = model.allPreviousCycleReviews.pevReviews.filter(arr => arr.reviewYear === academicYear - 1);
            model.hasPreviousPevReviews = model.previousPevReviews.length > 0;
            model.hasAnyPreviousReviews = model.hasPreviousInsitutionReviews || model.hasPreviousTCEDReviews || model.hasPreviousPevReviews;

            // Filter down to visible reviews for current cycle
            model.allCurrentCycleReviews = userReviewSvc.filterVisibleReviews(userReviewTeams);
            model.currentInstitutionReviews = model.allCurrentCycleReviews.institutionReviews.filter(arr => arr.reviewYear === academicYear);
            model.hasCurrentInsitutionReviews = model.currentInstitutionReviews.length > 0;
            model.currentTCEDReviews = model.allCurrentCycleReviews.teamChairAndEditorReviews.filter(arr => arr.reviewYear === academicYear);
            model.hasCurrentTCEDReviews = model.currentTCEDReviews.length > 0;
            model.currentPevReviews = helperSvc.uniqueArrayByKey(model.allCurrentCycleReviews.pevReviews.filter(arr => arr.reviewYear === academicYear), 'reviewTeamId');
            model.hasCurrentPevReviews = model.currentPevReviews.length > 0;
            model.hasAnyCurrentReviews = model.hasCurrentInsitutionReviews || model.hasCurrentTCEDReviews || model.hasCurrentPevReviews;

            // Filter down to visible reviews for next cycle
            model.allNextCycleReviews = userReviewSvc.filterVisibleReviews(userReviewTeams);
            model.nextInstitutionReviews = model.allNextCycleReviews.institutionReviews.filter(arr => arr.reviewYear === academicYear + 1);
            model.hasNextInsitutionReviews = model.nextInstitutionReviews.length > 0;
            model.nextTCEDReviews = model.allNextCycleReviews.teamChairAndEditorReviews.filter(arr => arr.reviewYear === academicYear + 1);
            model.hasNextTCEDReviews = model.nextTCEDReviews.length > 0;
            model.nextPevReviews = model.allNextCycleReviews.pevReviews.filter(arr => arr.reviewYear === academicYear + 1);
            model.hasNextPevReviews = model.nextPevReviews.length > 0;
            model.hasAnyNextReviews = model.hasNextInsitutionReviews || model.hasNextTCEDReviews || model.hasNextPevReviews;

            model.reviewSections = 0;
            if (model.hasAnyPreviousReviews) model.reviewSections++;
            if (model.hasAnyCurrentReviews) model.reviewSections++;
            if (model.hasAnyNextReviews) model.reviewSections++;

            model.filteredPreviousTCEDReviews = model.previousTCEDReviews.length > 9 ? [] : model.previousTCEDReviews;
            model.filteredCurrentTCEDReviews = model.currentTCEDReviews.length > 9 ? [] : model.currentTCEDReviews;
            model.filteredNextTCEDReviews = model.nextTCEDReviews.length > 9 ? [] : model.nextTCEDReviews;

            // Set up for offline editing
            model.clearSelectedReviews();
            model.offlineEnabledForCurrent = model.currentTCEDReviews.some(function (review) {
                return model.canEditOffline(review);
            });
            model.offlineEnabledForNext = model.nextTCEDReviews.some(function (review) {
                return model.canEditOffline(review);
            });

            // Re-apply search filters
            if (model.searches.previousTCEDReviews) {
                model.tCEDFilter(model.searches.previousTCEDReviews, 'previousTCEDReviews');
            }

            if (model.searches.currentTCEDReviews) {
                model.tCEDFilter(model.searches.currentTCEDReviews, 'currentTCEDReviews');
            }

            if (model.searches.nextTCEDReviews) {
                model.tCEDFilter(model.searches.nextTCEDReviews, 'nextTCEDReviews');
            }

            // Clear is isInAcceptProccess flag
            model.isInAcceptProccess = false;
        };

        function updateReviewTeamMemberStatus(reviewTeamMember) {
            return reviewTeamMemberSvc.changeTeamMemberStatus(reviewTeamMember).then(function () {
                return userReviewSvc.getCurrentCycleReviews();
            });
        }

    };
    module.controller('userReviewCtrl', userReviewCtrl);

}(angular.module('userReview')));
