(function (module) {

    var jointOptionsEditCtrl = function (parent, reviewJointOptionDtos, $scope, $uibModalInstance, helperSvc, eventSvc, alertSvc, reviewJointOptionSvc, typeConstSvc) {
        var model = $scope;
        model.parent = parent;
        model.reviewJointOptionDtos = reviewJointOptionDtos;
        model.title = 'Edit Joint Programs';
        model.programs = [];
        model.currentJointOption = {};

        model.isInsertMode = false;
        model.isCreateMode = false;
        model.isUpdateMode = false;
        model.isReviewSelectMode = false;

        var reviewId;

        init();

        model.close = function () {
            $uibModalInstance.close();
        };

        model.delete = function (jointOption) {
            alertSvc.confirmDelete(null, deleteFunc, jointOption);

            function deleteFunc() {
                reviewJointOptionSvc.delete(jointOption).then(function (data) {
                    broadcastRefreshMsg();
                    if (model.currentJointOption === jointOption) {
                        model.isInsertMode = false;
                    }
                    alertSvc.addModalAlertSuccess('Joint program successfully deleted.');
                });
            }
        };

        model.addRow = function () {
            reset();
            model.isInsertMode = true;
            model.isCreateMode = true;
            model.isUpdateMode = false;
        };

        model.cancel = function () {
            reset();
        }

        model.save = function (jointOption) {
            model.currentJointOption = jointOption;           

            if (model.isCreateMode) {
                reviewJointOptionSvc.create(jointOption).then(function () {
                    reset();
                    broadcastRefreshMsg();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess('Joint program successfully created.');
                });
            } else if (model.isUpdateMode) {
                //convert selected new programs to have the correct jointoption id
                angular.forEach(model.currentJointOption.reviewJointOptionProgramDtos, function (jointProgram) {
                    jointProgram.reviewJointOptionId = model.currentJointOption.reviewJointOptionId;
                });

                reviewJointOptionSvc.update(jointOption).then(function () {
                    reset();
                    broadcastRefreshMsg();
                    model.isInsertMode = false;
                    alertSvc.addModalAlertSuccess('Joint program successfully updated.');
                });
            }

        };

        model.select = function(jointOption) {
            model.currentJointOption = jointOption;          
            model.isInsertMode = true;
            model.isCreateMode = false;
            model.isUpdateMode = true;
        };

        model.loadReviewGroupOptions = function (review) {
            reviewId = review.reviewId;
            reset();
            model.reviewJointOptionDtos = review.reviewJointOptionDtos;
            model.isReviewSelectMode = false;
            loadPrograms();
        };

        function init() {
            if (!model.reviewJointOptionDtos) {
                model.isReviewSelectMode = true;
                model.reviewGroups = parent.reviewGroups;
            } else {
                reset();
                loadPrograms();
            }
        };       

        model.formatDate = helperSvc.formatDate;

        function loadPrograms() {
            var id = model.reviewJointOptionDtos.length > 0 ? model.reviewJointOptionDtos[0].reviewId : reviewId;
            reviewJointOptionSvc.getProgramsByReviewId(id).then(function (data) {
                var programs = helperSvc.getValue(data);
                angular.forEach(programs, function (program) {
                    model.programs.push({
                        programId: program.programId,
                        programName: program.programDetailDto.programName,
                        commissionName: program.programDetailDto.commissionName,
                        reviewJointOptionId: 0
                    });
                });
            });
        }

        function reset() {
            model.currentJointOption = {
                reviewJointOptionId: reviewJointOptionDtos ? reviewJointOptionDtos[0].reviewJointOptionId : 0,
                reviewId: reviewJointOptionDtos ? reviewJointOptionDtos[0].reviewId : reviewId,
                reviewJointOptionProgramDtos: []
            };            
            model.isInsertMode = false;
            model.isCreateMode = false;
            model.isUpdateMode = false;
        };

        function broadcastRefreshMsg() {
            eventSvc.broadcast('refreshAllReviews');
        };
    };

    module.controller('jointOptionsEditCtrl', jointOptionsEditCtrl);

}(angular.module('review')));