(function (module) {

    var reviewEditCtrl = function (review, parent, $scope, $uibModalInstance, helperSvc,codeSvc, eventSvc, alertSvc, reviewTeamSvc, reviewSvc) {
        var model = $scope;
        model.parent = parent;
        model.commissionOptions = model.parent.commissions;
        helperSvc.keysToInt(model.commissionOptions);
        model.reviewTypeOptions = model.parent.reviewTypes;
        model.onsiteVisitRestrictionStatusOptions = model.parent.onsiteVisitRestrictionStatuses;
        var useUndefined = true;       

        if (review) {
            model.currentReview = angular.copy(reviewTeamSvc.data.reviewTeam);
            model.title = 'Edit Review Information';
            model.currentReview.suggestedVisitDate = helperSvc.formatDate(model.currentReview.suggestedVisitDate, useUndefined);
            model.currentReview.visitStartDate = helperSvc.formatDate(model.currentReview.visitStartDate, useUndefined);
            model.currentReview.visitEndDate = helperSvc.formatDate(model.currentReview.visitEndDate, useUndefined);
        }
        else {
            model.title = 'Add Review Information';
            model.currentReview = { reviewTeamId: 0, reviewId: 0, stateboardObserverFlag: null, reviewDto: { reviewId: 0, organizationId: model.parent.organizationId } };
        }

        model.save = function () {
            var reviewTeam = model.currentReview;

            if (reviewTeam.reviewTeamId === 0) {
                reviewTeamSvc.create(reviewTeam).then(function () {              
                    updateReviews();
                    $uibModalInstance.close();
                    alertSvc.addAlertSuccess("Review successfully created.");
                });
            }
            else if (reviewTeam.reviewTeamId > 0) {
                reviewTeamSvc.update(reviewTeam).then(function () {                   
                    $uibModalInstance.close();
                    alertSvc.addAlertSuccess("Review successfully updated.");
                });
            }
        };

        model.cancel = function () {
            $uibModalInstance.close();
        };

        //used for add to refresh review data

        function updateReviews() {
            reviewSvc.getReviewsByOrganizationId(model.parent.organizationId, true).then(function () {
                model.parent.refreshReviewGroups();
            });
            reviewSvc.getReviewsByOrganizationId(model.parent.organizationId, false).then(function () {
                model.parent.refreshReviewGroups();
            });
        }
    };

    module.controller('reviewEditCtrl', reviewEditCtrl);

}(angular.module('review')));