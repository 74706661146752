(function (module) {

    var assignmentSvc = function ($http, $odataresource, $q, $uibModal, odataSvc, amsConst, helperSvc, alertSvc, availabilityTypes,
                                  teamMemberTypeNames, programReviewTypes, tcAssignmentSvc, pevAssignmentSvc) {
        var factory = {};

        factory.assignmentTypes = {
            TEAMCHAIR: 'TC',
            COTEAMCHAIR: 'Co-TC', // not the assignmentType used for assigning, only used for identification
            EDITOR1: 'Editor1',
            EDITOR2: 'Editor2',
            PEV: 'PEV',
            OBSERVER: 'Observer'
        };

        factory.create = function (newAssignee, volunteer, isTC) {
            return validate(newAssignee).then(function (results) {
                if (results.length == 0) {
                    var apiPath = '/ReviewAssignment';
                    var key = 'reviewAssignmentId';

                    var oSvc = odataSvc.get();
                    var resource = oSvc.instantiate(apiPath, key, newAssignee);

                    var promise = resource.$save(oSvc.onSuccess, oSvc.onFailure);

                    promise.then(function (result) {
                        var assignment = {
                            teamMemberTypeId: newAssignee.teamMemberTypeId,
                            reviewAssignmentId: result.value
                        };

                        if (volunteer.assignedReviews) {
                            volunteer.assignedReviews.push(assignment);
                        } else {
                            volunteer.assignedReviews = [assignment];
                        };

                        if (isTC) {
                            tcAssignmentSvc.refreshData();
                        } else {
                            /*this refresh call seems unnecessary because the data on the front-end and back-end 
                            are already updated. We essentially validate the back-end and then insert 
                            the data into a front-end array. This should not call for a complete data refresh
                            */
                            //pevAssignmentSvc.refreshData();
                        }
                    });

                    return promise;
                } else {
                    var listItems = "";
                    var title = "Assignment Conflict";
                    for (var i = 0; i < results.length; i++) {
                        listItems += "<li><h4>" + results[i] + "</h4></li>";
                    }
                    var msg = "<div>Please resolve the following conflict(s) if you wish to proceed with the assignment.<br/><br/></div>" +
                              "<div class='text-center'>" +
                                "<ul class='semi-style'>" +
                                    listItems +
                                "</ul>" +
                              "</div>";

                    alertSvc.openModalAlert(msg, title);

                    return $q.reject();
                }
            });
        };

        factory.delete = function (assignment, tcMode) {
            var assignmentId = parseInt(assignment.reviewAssignmentId);
            var apiPath = '/ReviewAssignment';
            var key = 'reviewAssignmentId';

            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(apiPath, key, { reviewAssignmentId: assignmentId });

            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure);

            promise.then(function () {
                if (tcMode) {
                    tcAssignmentSvc.refreshData();
                } else {
                    pevAssignmentSvc.refreshData();
                }
            });

            return promise;
        };

        factory.assign = function (volunteer, type, currentReviewTeam, volunteers) {
            var isTC = true;
            var teamMemberTypeId = null;
            var repeatedAssignment = null;
                type = type.toLowerCase();

            if (type.contains('tc')) {
                teamMemberTypeId = type.contains('co') ? teamMemberTypeNames.COTEAMCHAIR : teamMemberTypeNames.TEAMCHAIR;
            } else if (type.contains('editor')) {
                teamMemberTypeId = type.contains('1') ? teamMemberTypeNames.EDITOR1 : teamMemberTypeNames.EDITOR2;
            } else if (type.contains('pev')) {
                isTC = false;
                teamMemberTypeId = teamMemberTypeNames.PEV;
            } else if (type.contains('observer')) {
                isTC = false;
                teamMemberTypeId = teamMemberTypeNames.PROGRAMOBSERVER;
            }

            if (volunteer.assignedReviews) {
                repeatedAssignment = volunteer.assignedReviews.find(function (review) {
                    return review.teamMemberTypeId === teamMemberTypeId;
                });
            }

            if (!repeatedAssignment) {
                var newAssignee = {
                    reviewAssignmentId: 0,
                    reviewTeamId: currentReviewTeam.reviewTeamId,
                    volunteerId: volunteer.volunteerId,
                    reviewYear: currentReviewTeam.reviewYear,
                    commissionId: currentReviewTeam.commissionId,
                    teamMemberTypeId: teamMemberTypeId,
                    releaseDate: null,
                    societyId: currentReviewTeam.leadSocietyId || null,
                    programReviewDisciplineId: currentReviewTeam.programReviewDisciplineId || null
                };

                factory.create(newAssignee, volunteer, isTC).then(function (result) {
                    incrementAssignmentCounts(volunteer, teamMemberTypeId, currentReviewTeam, volunteers, result.value);
                });
            }
        };

        factory.unassign = function (volunteer, teamMemberTypeId, selectedReviewTeam, selectedVolunteers) {
            alertSvc.confirmDelete(volunteer.lastName + ", " + volunteer.firstName, deleteFunc);

            function deleteFunc() {
                if (volunteer.assignedReviews && volunteer.assignedReviews.length > 0) {
                    var tcMode = teamMemberTypeId !== teamMemberTypeNames.PEV && teamMemberTypeId !== teamMemberTypeNames.PROGRAMOBSERVER;
                    var review = volunteer.assignedReviews[0];
                    // teamMemberTypeId passed in here is PEV for PEV and Oberservers, TC for TC and Co-TC
                    // Need actual type to know which collection to remove this volunteer from
                    var specificTeamMemberTypeId = review.teamMemberTypeId;  
                    if (volunteer.assignedReviews.length == 1 && review.reviewAssignmentId) {
                        // If there is only one review, delete it
                        factory.delete(review, tcMode).then(function () {
                            // Delete assignment from client so assignment icon will disappear from matrix without refreshing data
                            volunteer.assignedReviews = [];
                            decrementAssignmentCounts(volunteer, specificTeamMemberTypeId, selectedReviewTeam, selectedVolunteers);
                        });

                    } else {
                        // If there are multiple reviews find the correct one and delete it
                        // It should not be possible to have multiple assignments for one team; this is a fallback
                        var reviewToDeleteIndex = -1;
                        var isChair = teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR;

                        reviewToDeleteIndex = volunteer.assignedReviews.findIndex(function (review) {
                            if (isChair) {
                                return review.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || review.teamMemberTypeId === teamMemberTypeNames.COTEAMCHAIR;
                            } else {
                                return review.teamMemberTypeId === teamMemberTypeId;
                            }
                        });

                        if (reviewToDeleteIndex > -1) {
                            var review = volunteer.assignedReviews[reviewToDeleteIndex];

                            if (review.reviewAssignmentId) {
                                factory.delete(review, tcMode).then(function () {
                                    // Delete assignment from client so assignment icon will disappear from matrix without refreshing data
                                    volunteer.assignedReviews.splice(reviewToDeleteIndex, 1);
                                    decrementAssignmentCounts(volunteer, specificTeamMemberTypeId, selectedReviewTeam, selectedVolunteers);
                                });
                            }
                        }
                    }
                } else {
                    alertSvc.openModalAlert('This volunteer has no reviews to delete.');
                }
            }
        };

        function validate(assignment) {
            var apiPath = amsConst.webApiUrl + '/odata/ValidateReviewAssignment';
            var data = { 'Value': assignment };
            var result = [];

            return $http.post(apiPath, data).then(function (response) {
                for (var i = 0; i < response.data.validationResultItems.length; i++) {
                    result.push(response.data.validationResultItems[i].message);
                }
                return result;
            });
        }

        function incrementAssignmentCounts(volunteer, teamMemberTypeId, selectedReview, selectedVolunteers, reviewAssignmentId) {
           
            var reviewTeamVolunteers = getReviewTeamVolunteers(selectedReview, teamMemberTypeId);

            // Update review team volunteer count
            var newTeamMember = {
                reviewAssignmentId: reviewAssignmentId,
                firstName: volunteer.firstName,
                lastName: volunteer.lastName,
                volunteerId: volunteer.volunteerId
            }

            reviewTeamVolunteers.push(newTeamMember);

            // Update volunteer assignment count
            var newAssignment = {
                teamMemberTypeId: teamMemberTypeId,
                reviewAssignmentId: reviewAssignmentId,
                // TC assignment object fields
                organizationName: selectedReview.sortName ? selectedReview.sortName : selectedReview.organizationName,
                commissionName: selectedReview.commissionName,
                suggestedVisitDate: selectedReview.suggestedVisitDate ? selectedReview.suggestedVisitDate : selectedReview.visitStartDate,
                // PEV assignment object fields
                OrganizationName: selectedReview.sortName ? selectedReview.sortName : selectedReview.organizationName,
                ProgramName: selectedReview.programDetail && selectedReview.programDetail.length > 0 ? selectedReview.programDetail[0].programName : null,
                statusName: 'Not Released'
            };

            var selectedVolunteer = selectedVolunteers ? selectedVolunteers.find(function (item) { return item.volunteerId === volunteer.volunteerId; }) : null;
            if (selectedVolunteer) {
                selectedVolunteer.assignedReviews = selectedVolunteer.assignedReviews ? selectedVolunteer.assignedReviews : [];
                selectedVolunteer.assignedReviews.push(newAssignment);
            }                 
        }

        function decrementAssignmentCounts(volunteer, teamMemberTypeId, selectedReviewTeam, selectedVolunteers) {
            // Update review team volunteer count
            var reviewTeamVolunteers = getReviewTeamVolunteers(selectedReviewTeam, teamMemberTypeId);
            var volunteerIndex = reviewTeamVolunteers.findIndex(function (item) { return item.volunteerId === volunteer.volunteerId });
            if (volunteerIndex > -1) {
                var reviewAssignmentId = reviewTeamVolunteers[volunteerIndex].reviewAssignmentId
                reviewTeamVolunteers.splice(volunteerIndex, 1);
                // Update volunteer assignment count
                var selectedVolunteer = selectedVolunteers ? selectedVolunteers.find(function (item) { return item.volunteerId === volunteer.volunteerId; }) : null;
                if (selectedVolunteer && selectedVolunteer.assignedReviews && reviewAssignmentId) {
                    var assignedReviewIndex = selectedVolunteer.assignedReviews.findIndex(
                        function (item) {
                            return item.reviewAssignmentId === reviewAssignmentId;
                        });
                    if (assignedReviewIndex > -1) {
                        selectedVolunteer.assignedReviews.splice(assignedReviewIndex, 1);
                    }
                }
                
            }
        }

        function getReviewTeamVolunteers(selectedReviewTeam, teamMemberTypeId) {
            // Get the right collection of volunteers for the specified team member type.
            var reviewTeamVolunteers;
            switch (teamMemberTypeId) {
                case teamMemberTypeNames.TEAMCHAIR:
                    reviewTeamVolunteers = selectedReviewTeam.teamChairs = selectedReviewTeam.teamChairs ? selectedReviewTeam.teamChairs : [];
                    break;
                case teamMemberTypeNames.COTEAMCHAIR:
                    reviewTeamVolunteers = selectedReviewTeam.coTeamChairs = selectedReviewTeam.coTeamChairs ? selectedReviewTeam.coTeamChairs : [];
                    break;
                case teamMemberTypeNames.EDITOR1:
                    reviewTeamVolunteers = selectedReviewTeam.editor1 = selectedReviewTeam.editor1 ? selectedReviewTeam.editor1 : [];
                    break;
                case teamMemberTypeNames.EDITOR2:
                    reviewTeamVolunteers = selectedReviewTeam.editor2 = selectedReviewTeam.editor2 ? selectedReviewTeam.editor2 : [];
                    break;
                case teamMemberTypeNames.PEV:
                    reviewTeamVolunteers = selectedReviewTeam.pevsAssigned = selectedReviewTeam.pevsAssigned ? selectedReviewTeam.pevsAssigned : [];
                    break;
                case teamMemberTypeNames.PROGRAMOBSERVER:
                    reviewTeamVolunteers = selectedReviewTeam.observersAssigned = selectedReviewTeam.observersAssigned ? selectedReviewTeam.observersAssigned : [];
                    break;
            }
            return reviewTeamVolunteers;
        }

        factory.getName = function (person) {
            return person.lastName + ', ' + person.firstName;
        };

        factory.getDateConflictText = function (commissioner) {
            var availabilityDate = getAvailabilityDate(commissioner);
            var yesOrEmpty = getYesOrEmptyString(availabilityDate, 'availabilityTypeId');
            return yesOrEmpty;
        };

        factory.getCountryConflictText = function (commissioner) {
            var availabilityCountry = getAvailabilityCountry(commissioner);
            var yesOrEmpty = getYesOrEmptyString(availabilityCountry, 'availabilityTypeId');
            return yesOrEmpty;
        };

        var getYesOrEmptyString = function (obj, prop) {
            return (obj && obj[prop] !== availabilityTypes.ALL) ? 'Yes' : '';
        };

        factory.getDateConflictTypeName = function (commissioner) {
            var availabilityDate = getAvailabilityDate(commissioner);
            var typeNameOrEmpty = (availabilityDate) ? availabilityDate.availabilityTypeName : '';
            return typeNameOrEmpty;
        };

        factory.getConflictDates = function (commissioner) {
            var availabilityDate = getAvailabilityDate(commissioner);
            var dates = getItems(availabilityDate.unavailableDates);
            return dates;
        };

        factory.getCountryConflictTypeName = function (commissioner) {
            var availabilityCountry = getAvailabilityCountry(commissioner);
            var typeNameOrEmpty = (availabilityCountry) ? availabilityCountry.availabilityTypeName : '';
            return typeNameOrEmpty;
        };

        factory.getConflictCountries = function (commissioner) {
            var availabilityCountry = getAvailabilityCountry(commissioner);
            var countries = getItems(availabilityCountry.unavailableCountries);
            return countries;
        };

        var getItems = function (array) {
            var items = [];

            if (array) {
                for (var i = 0; i < array.length; i++) {
                    var item = array[i];
                    items.push(item);
                }
            }
            
            return items;
        };

        var getAvailabilityDate = function (commissioner) {
            var availabilityDates = commissioner.availabilityDate;
            var availabilityDate = getFirstEntryOrNull(availabilityDates);
            return availabilityDate;
        };

        var getAvailabilityCountry = function (commissioner) {
            var availabilityCountries = commissioner.availabilityCountry;
            var availabilityCountry = getFirstEntryOrNull(availabilityCountries);
            return availabilityCountry;
        };

        var getFirstEntryOrNull = function (array) {
            return (array && array.length > 0) ? array[0] : null;
        };

        factory.getCommissionerName = function (commissioner) {
            if(commissioner)
                return commissioner.lastName + ', ' + commissioner.firstName;

            return '';
        };

        factory.clearIndex = function (items) {
            if (!items) return;
            items.forEach(function (item) { item.index = null; });
        };

        factory.setIndex = function (items) {
            var index = 0;

            items.forEach(function (item) {
                // Only want to set index once, when data is first loaded for display.
                if (item.index === undefined || item.index === null) {
                    item.index = ++index;
                }
            });

            return items;
        };

        factory.setSortBy = function (defaultSort, oldSortFilter, newSortFilter) {
            if (angular.isArray(newSortFilter) && angular.isArray(oldSortFilter)) {
                var filtersAreEqual = newSortFilter.every(function (elem, i) { return elem === oldSortFilter[i]; });
                if (filtersAreEqual) {
                    return newSortFilter.map(function (elem) { return "-" + elem; });
                }
            } else {
                // Index and default sort will always be in same order
                if ((newSortFilter === "index" && oldSortFilter === defaultSort) ||
                    (newSortFilter === defaultSort && oldSortFilter === "index") ||
                    newSortFilter === oldSortFilter) {

                    return ("-" + newSortFilter);
                }
            }
            return newSortFilter;
        };

        let getDisabledMsg = function (volunteer, isPEV) {
            let text = "";

            text += "Volunteer must meet the following requirements to be available for selection:<br/>";
            text += "<ul>";

            if (!volunteer.isConfirmedTermsAgreement) text += "<li>Agree to ABET's Code of Conduct</li>";
            if (!volunteer.isConfirmedHealthSafety) text += "<li>Acknowledge ABET's Assumption Of Risk/Liability Waiver</li>";
            if (isPEV && volunteer.isRecertificationTrainingRequired) text += "<li>Complete Recertification Training</li>";

            text += "</ul>";
            return text;
        }

        factory.getTCDisabledMsg = function (volunteer) {
            return getDisabledMsg(volunteer);
        }

        factory.getPEVDisabledMsg = function (volunteer) {
            let isPEV = true;
            return getDisabledMsg(volunteer, isPEV);
        }

        factory.addBodyClass = function () {
            helperSvc.addBodyClass('assignment-tool');
        };

        factory.removeBodyClass = function () {
            helperSvc.removeBodyClass('assignment-tool');
        };

        factory.removeBodyClassOnPageChange = function (scope) {
            scope.$on('$locationChangeStart', function (event, next, current) {
                var page = next.substr(next.length - 5);

                if (page !== '/tool') {
                    factory.removeBodyClass();

                    //var answer = confirm('Are you sure you want to navigate away from this page?');
                    //if (!answer) event.preventDefault();
                }
            });
        };

        return {
            create: factory.create,
            delete: factory.delete,
            assign: factory.assign,
            unassign: factory.unassign,
            assignmentTypes: factory.assignmentTypes,
            getName: factory.getName,
            getDateConflictText: factory.getDateConflictText,
            getDateConflictTypeName: factory.getDateConflictTypeName,
            getCountryConflictTypeName: factory.getCountryConflictTypeName,
            getConflictDates: factory.getConflictDates,
            getCountryConflictText: factory.getCountryConflictText,
            getConflictCountries: factory.getConflictCountries,
            clearIndex: factory.clearIndex,
            setIndex: factory.setIndex,
            setSortBy: factory.setSortBy,
            getCommissionerName: factory.getCommissionerName,
            getTCDisabledMsg: factory.getTCDisabledMsg,
            getPEVDisabledMsg: factory.getPEVDisabledMsg,
            addBodyClass: factory.addBodyClass,
            removeBodyClass: factory.removeBodyClass,
            removeBodyClassOnPageChange: factory.removeBodyClassOnPageChange,
            setTCNameSortBy: factory.setTCNameSortBy
        };
    };
    module.factory('assignmentSvc', assignmentSvc);

})(angular.module('assignment'));