(function (module) {

    var rrSearchCtrl = function ($scope, barSvc, helperSvc, codeSvc, rrAdminSvc, $q) {
        var model = this;

        model.title = "Find a RR";
        model.resultsTitle = "Results";
        model.defaultSearchMsg = "Waiting on search...";
        model.resultMsg = model.defaultSearchMsg;
        model.showSpinner = false;
        model.showSpinnerSearch = false;
        model.firstLoad = true;
        model.readinessReviews = [];
        model.searchOptions = {};
        model.showSpinnerExport = false;

        //var commissionsDataSource = {
        //    dataHolder: model,
        //    dataLocationName: 'commissions',
        //    svcCallback: codeSvc.getCommissions,
        //    helperCallback: helperSvc.getResults
        //};

        model.csvHeaders = ['Institution Name', 'Country', 'State', 'Province', 'Commission', 'Contact Name', 'Contact Email', 'Current Status', 'Request Date', 'Submitted Date', 'Accepted Date', 'Is Contact Confirmed', 'Is Institution Confirmed', 'Is Readiness Waived', 'Account Number'];
        model.csvColumns = ['institutionName', 'countryCode', 'stateCode', 'province', 'commissionName', 'contactPersonFullName', 'email', 'latestStatusName', 'requestedDate', 'submittedDate', 'acceptedDate', 'isContactConfirmed', 'isInstitutionConfirmed', 'isReadinessWaived', 'accountNumber'];

        var statusOptionsDataSource = {
            dataHolder: model,
            dataLocationName: 'rrStatuses',
            svcCallback: codeSvc.getReadinessStatuses,
            helperCallback: helperSvc.getResults
        };

        barSvc.listenForShow(function () { 
            if (model.firstLoad) {
                model.showSpinnerSearch = true;
            } else{
                model.showSpinner = true;
            } 
        });

        barSvc.listenForHide(function () {
            if (model.firstLoad){
                model.showSpinnerSearch = false;
                model.firstLoad = false;
            } else{
                model.showSpinner = false; 
            }
        });

        model.reset = function () {
            reset();
        };

        model.retrieve = function () {
            var searchOpts = model.searchOptions;

            model.resultMsg = null;
            barSvc.showSpinner();

            rrAdminSvc.advancedSearch(searchOpts).then(function (data) {
                barSvc.hideSpinner();
                model.readinessReviews = data;

                if (model.readinessReviews.length === 0) {
                    model.resultMsg = "No results found";
                }
            });
        };

        model.exportCSV = function () {
            var defer = $q.defer();
            model.showSpinnerExport = true;

            rrAdminSvc.exportList(model.searchOptions).then(function (data) {
                var list = data.map(function (rr) {

                    rr.isContactConfirmed = convertBoolToYesNo(rr.isContactConfirmed);
                    rr.isInstitutionConfirmed = convertBoolToYesNo(rr.isInstitutionConfirmed);
                    rr.isReadinessWaived = convertBoolToYesNo(rr.isReadinessWaived);

                    rr.requestedDate = convertToDateString(rr.requestedDate);
                    rr.submittedDate = convertToDateString(rr.submittedDate);
                    rr.acceptedDate = convertToDateString(rr.acceptedDate);

                    return rr;
                });
                defer.resolve(resolveExport(list));
            });

            return defer.promise;
        };

        function convertBoolToYesNo(bool) {
            return bool ? 'Yes' : 'No';
        }

        function convertToDateString(date) {
            if (date) {
                var newDate = new Date(date);
                if (newDate != NaN)
                    return newDate.toLocaleDateString().replace(/\u200E/g, ''); // IE 11 adds LTR characters -- remove them.;
            } 
            return undefined;
        }

        function resolveExport(data) {
            model.showSpinnerExport = false;
            return data;
        }

        function reset () {
            model.searchOptions = {
                requestYear: null,
                statusId: null,
                organizationName: null,
                location: null,
                commissions: []
            };
            model.resultMsg = model.defaultSearchMsg;
            model.readinessReviews = [];
        }

        function getYear() {
            //get years from start year to current year
            var STARTYEAR = 2017;
            var currentYear = new Date().getFullYear();

            var numberOfYearsToAdd = (currentYear - STARTYEAR) + 1;  
            var years = [];

            for (var i = numberOfYearsToAdd; i > 0; i--) {
                years.push(currentYear - (numberOfYearsToAdd - i));
            }

            return years;
        }

        var activate = function () {
            model.rrYears = getYear();
            reset();

            barSvc.showSpinner();

            var dataSourceArray = [/*commissionsDataSource, */statusOptionsDataSource];

            helperSvc.getData(dataSourceArray).then(function(){
                barSvc.hideSpinner();
            });
        }();
    };

    module.controller('rrSearchCtrl', rrSearchCtrl);

}(angular.module('readinessReview')));