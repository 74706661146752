(function (module) {

    var travelAvailabilityEditCtrl = function (parent, $scope, $uibModalInstance, $state, alertSvc, volunteerSvc, codeSvc, travelAvailabilitySvc, volunteerHelperSvc, helperSvc, personSvc) {
        var model = $scope;
        var checkIsVolunteer = true;

        model.parent = parent;
        model.title = 'Edit ' + model.parent.title;
        model.currentInfo = {};
        model.volunteerId = volunteerHelperSvc.getVolunteerId(); 

        // START: DEPARTURE COUNTRY (Work in progress)
        // Note: data is previously loaded into personSvc and travelAvailabilitySvc by parent controller.
        model.volunteerCountries = personSvc.data.person?.personAddressDtos
            .filter((personAddress, index, self) => 
                personAddress.countryCode != null && 
                index === self.findIndex(t => t.countryCode === personAddress.countryCode)
            )
            .map(personAddress => ({ countryCode: personAddress.countryCode, countryName: personAddress.countryName.trim() }))
            .sort((a, b) => a.countryName.localeCompare(b.countryName)) || [];
        // END: DEPARTURE COUNTRY (Work in progress)

        if (travelAvailabilitySvc.data.travelAvailability.volunteerAvailabilityTravelId) {
            model.currentInfo.travelAvailability = angular.copy(travelAvailabilitySvc.data.travelAvailability);
            // Clear previously set departure country if it isn't home to one of the volunteer's current addresses.
            // Note: this shouldn't happen for new data as we update travel availability on the server-side when we update addresses.
            if (model.currentInfo.travelAvailability.departureCountryCode && !model.volunteerCountries.some(country => country.countryCode === model.currentInfo.travelAvailability.departureCountryCode)) {
                model.currentInfo.travelAvailability.departureCountryCode = null;
            }
        } else {
            model.currentInfo.travelAvailability = travelAvailabilitySvc.data.travelAvailability.volunteerAvailabilityTravelId ? angular.copy(travelAvailabilitySvc.data.travelAvailability) :
                {
                    volunteerId: model.volunteerId,
                    year: travelAvailabilitySvc.currentYear,
                    volunteerTravelAvailability: null,
                    volunteerVirtualAvailability: null,
                    departureCountryCode: null,
                    willFulfilABETRequirement: null,
                    willFulfilInstitutionRequirement: null
                };
        }

        model.save = function () {

            //if travel answer is NO
            if (!model.currentInfo.travelAvailability.volunteerTravelAvailability || model.currentInfo.travelAvailability.volunteerTravelAvailability == 4) {
                model.currentInfo.travelAvailability.willFulfilABETRequirement = null;
                model.currentInfo.travelAvailability.willFulfilInstitutionRequirement = null;
                model.currentInfo.travelAvailability.departureCountryCode = null;
            }

            if (model.currentInfo.travelAvailability.volunteerAvailabilityTravelId) {
                travelAvailabilitySvc.updateTravelAvailability(model.currentInfo.travelAvailability).then(onSuccess, onFailure);
            }
            else {
                travelAvailabilitySvc.createTravelAvailability(model.currentInfo.travelAvailability).then(onSuccess, onFailure);
            }

            function onSuccess() {
                $uibModalInstance.close();
                alertSvc.addAlertSuccess("Travel Availability information successfully saved.");
            }
            function onFailure() {
                alertSvc.addModalAlertWarning('Travel Availability  information could not be saved at this time.');
            }

        };

        model.cancel = function () {
            $uibModalInstance.close();
        };
    };

    module.controller('travelAvailabilityEditCtrl', travelAvailabilityEditCtrl);

}(angular.module('volunteer')));