(function (module) {

    var rfrSubmittedSummaryCtrl = function (rfrSvc, $stateParams, $state, alertSvc, rfrProcessStatuses) {
        var model = this;
        var rfrId = parseInt($stateParams.rfrId);
        var organizationId = parseInt($stateParams.organizationId);

        model.rfr = rfrSvc.data.rfr;
        //model.reviseSubmissionStillAvailable = rfrSvc.isWithinRevisionCutoffDate(model.rfr);
        model.reviseSubmissionStillAvailable = model.rfr.rfrProcessStatusId < rfrProcessStatuses.ABETACCEPTED;

        rfrSvc.getRfrSubmissionAndCommissions(rfrId);

        model.title = 'Request for Evaluation (' + (model.rfr.reviewYear - 1) + '–' + model.rfr.reviewYear + ')';

        model.buttonTitle = 'I want to revise this RFE and resubmit';
        model.infoTitle = 'This RFE has been submitted';
        model.infoText = 'The Request for Evaluation (RFE) for programs at this instutition has been submitted to ABET for review. ABET staff are currently conducting the pre-review to insure that the RFE has been properly completed.  If ABET has any questions, the primary contact(s) will be notified.';
        
        if (model.reviseSubmissionStillAvailable) model.infoText += ' If for any reason this institution must revise this submission, please use the button immediately below.';

        model.revise = function () {
            alertSvc.confirm('Are you sure you want modify this RFE?', confirmedFunc);

            function confirmedFunc() {
                rfrSvc.createRevisedRfr(model.rfr).then(function (response) {
                    var newRfrId = response.data.rfrId;

                    $state.go('rfe', { rfrId: newRfrId, organizationId: organizationId });
                });
            }
        };
    };

    module.controller('rfrSubmittedSummaryCtrl', rfrSubmittedSummaryCtrl);

}(angular.module('rfr')));