(function (module) {

    var environmentSvc = function ($location) {
        const devDomain = 'localhost';
        const stagingDomain = 'stage.abet.org';
        const prodDomain = 'amsapp.abet.org';
        const devApiUrl = 'http://localhost:3000';

        const environments = {
            Development: "Dev",
            Stage: "Stage",
            Production: "Prod"
        };

        function isDev() {
            return $location.host() === devDomain;
        }

        function isStage() {
            return $location.absUrl().includes(stagingDomain);
        }

        function isProd() {
            return $location.absUrl().includes(prodDomain);
        }

        function getCurrentEnvironment() {
            if (isDev())
                return environments.Development;
            else if (isStage())
                return environments.Stage;
            else if (isProd())
                return environments.Production;
            else
                return null;
        }
        function getDevApiUrl() { return devApiUrl; }

        return {
            environments: environments,
            isDev: isDev,
            isStage: isStage,
            isProd: isProd,
            getCurrentEnvironment: getCurrentEnvironment,
            getDevApiUrl: getDevApiUrl
        };
    };
    module.service('environmentSvc', environmentSvc);

})(angular.module('common'));