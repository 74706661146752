(function (module) {
    var rrManageStatusCtrl = function ($uibModal, helperSvc, alertSvc, $stateParams, readinessSvc, rfrSvc, eventSvc, typeConstSvc, organizationSvc,
                                       personSvc, personEmailSvc, telephoneTypes, codeSvc, contactTypes, messageTemplateTypes, messageSvc,commissionTypes,
                                       commissionAbbreviations, readinessValidationSvc, noteTypes, noteSvc) {

        var model = this;
        var readinessEmailAddress = 'ReadinessReview@abet.org';

        model.readinessId = parseInt($stateParams.readinessId);
        model.data = null;
        model.isContactVerified = false;
        model.isInstitutionVerified = false;
        model.formatDate = helperSvc.formatDate;
        model.acceptanceTitle = null;
        model.defaultTitle = 'Current Status';
        model.notes = [];
        model.noteTypes = noteTypes;
        model.acceptance = {
            acknowledge: false, 
            accept: false,
            recommendation: false,
            informedResult: false
        };
        model.titles = {
            acknowledge: 'Acknowledge Request',
            accept: 'Accept Submission',
            recommendation: 'Recommendation',
            informedResult: 'Recommendation'
        };
        model.readinessStatuses = typeConstSvc.getReadinessStatuses();

        model.commissionAbbreviations = commissionAbbreviations;

        eventSvc.listen(refreshRR, 'refreshReadinessData');
        eventSvc.listen(refreshRRNotes, 'refreshRRNotes');

        var templatePath = '/apps/readinessReview/templates/';

        model.editStatus = function () {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: templatePath + 'rrStatusEdit.html',
                    size: 'md',
                    controller: 'rrStatusEditCtrl',
                    resolve: {
                        readinessReview: function () { return model.rr; }
                    }
                });
        };

        model.getReviewer = function (program, commissionId) {
            var reviewer;

            if (program && program.readinessReview && Array.isArray(program.readinessReview.reviewers)) {
                reviewer = program.readinessReview.reviewers.find(function (reviewer) { return reviewer.commissionId === commissionId; }) || null;
            }

            return reviewer;
        };

        model.editReviewer = function (program, commissionId) {
            if (Number.isInteger(model.data.selectedRR.organizationId)) {
                var modalInstance = $uibModal.open({
                    animation: true,
                    templateUrl: templatePath + 'rrProgramReviewerEdit.html',
                    size: 'md',
                    controller: 'rrProgramReviewerEditCtrl',
                    resolve: {
                        readinessReview: function () { return model.rr; },
                        selectedProgram: function () { return program; },
                        selectedCommissionId: function () { return commissionId; }
                    }
                });
            } else {
                blockEditReviewer();
            }
        };

        function blockEditReviewer() {
            alertSvc.openModalAlert('Reviewers cannot be edited until the institution is verified.');
        }

        model.editAccountNumber = function (commission) {
            if (!commission.organizationId && model.rr.organizationId) {
                commission.organizationId = model.rr.organizationId
            }
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: templatePath + 'rrAccountNumberEdit.html',
                size: 'md',
                controller: 'rrAccountNumberEditCtrl',
                resolve: {
                    readinessReview: function () { return model.rr; },
                    selectedCommission: function () { return commission; }
                }
            });
        };
        
        model.verifyContact = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: templatePath + 'verifyContactEdit.html',
                size: 'md',
                controller: 'verifyContactEditCtrl',
                resolve: {
                    rr: function () { return readinessSvc.data; }
                }
            });
        };
        
        model.verifyInstitution = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: templatePath + 'verifyInstitutionEdit.html',
                size: 'md',
                controller: 'verifyInstitutionEditCtrl',
                resolve: {
                    rr: function () { return readinessSvc.data; }
                }
            });
        };

        model.deleteReviewer = function (program, commissionId) {
            if (Number.isInteger(model.data.selectedRR.organizationId)) {
                var reviewer = model.getReviewer(program, commissionId);
                var fullName = reviewer.firstName + " " + (reviewer.middleName ? reviewer.middleName + " " : "")  + reviewer.lastName;
                alertSvc.confirmDelete(fullName, deleteFunc);
            } else {
                blockEditReviewer();
            }

            function deleteFunc() {
                var updatedReadiness = angular.copy(model.rr);
                var updatedProgram = updatedReadiness.programs.find(function (record) {
                    return record.programName === program.programName && record.degreeCode === program.degreeCode;
                });

                if (updatedProgram.readinessReview && Array.isArray(updatedProgram.readinessReview.reviewers)) {
                    var reviewerIndex = updatedProgram.readinessReview.reviewers.findIndex(function (reviewer) {
                        return reviewer.commissionId === commissionId;
                    });

                    if (reviewerIndex > -1) {
                        updatedProgram.readinessReview.reviewers.splice(reviewerIndex, 1);
                    }
                } else {
                    delete updatedProgram.readinessReview.reviewer;
                }

                readinessSvc.assignReviewer(updatedReadiness).then(function (data) {
                    alertSvc.addAlertSuccess("Reviewer successfully deleted.");
                    refreshRR();
                });
            }
        };

        model.acknowledge = function () {
            var doAcknowledge = function () {
                var statusId = model.RRNotRequired ? model.readinessStatuses.READINESS_REVIEW_NOT_REQUIRED : model.readinessStatuses.WAITING_FOR_READINESS_REVIEW_SUBMISSION;
                model.addStatus(statusId);

                var readinessDto = createReadinessDto();

                var params = {
                    readinessDto: readinessDto
                };

                readinessSvc.acknowledgeRequest(params).then(function (data) {
                    refreshRR().then(function () {
                        alertSvc.addAlertSuccess("Readiness Review has been acknowledged.");
                    });
                });
            }
            
            confirmThenProcess('acknowledge', doAcknowledge);
        };

        model.accept = function () {
            var doAccept = function () {
                model.addStatus(model.readinessStatuses.REVIEW_IN_PROGRESS);
                var readinessDto = createReadinessDto();

                var params = {
                    readinessDto: readinessDto
                };

                readinessSvc.acceptRequest(params).then(function (data) {
                    refreshRR().then(function () {
                        alertSvc.addAlertSuccess("Readiness Review has been accepted.");
                        setAcceptance('recommendation');
                        sendAcceptanceNotification();
                    });
                });
            }

            validateThenProcess('accept', doAccept);
        };

        
        model.recommend = function () {
            var doRecommend = function () {
                var attachmentParams = {
                    file: model.file,
                    title: model.documentTitle
                };
                
                var totalRecommendations = 0;

                var submittedCt = 0;
                var reqPgms = [];

                angular.forEach(model.rr.programs, function (program) {
                    angular.forEach(program.readinessReview.response, function (response) {
                        if (response) {
                            
                            if ((response.isSubmitted === false || response.isSubmitted === undefined) && (response.submitResponse === true))
                                reqPgms.push(program.programName);

                            response.isSubmitted = response.submitResponse;
                            totalRecommendations++;
                            if (response.isSubmitted) submittedCt++;
                        }
                    });
                });
                
                if (submittedCt === totalRecommendations)
                    model.addStatus(model.readinessStatuses.INFORMED_REVIEW_RESULT);

                readinessSvc.sendRecommendation(model.rr).then(function (data) {
                    refreshRR().then(function () {
                        alertSvc.addAlertSuccess("Response has been submitted.");

                        if (model.file)
                        {
                           // sendRecommandResultNotification(attachmentParams, reqPgms); //commented out per task item: 4231
                        }    
                    });
                });
            }

            validateThenProcess('recommend', doRecommend);
        };

        model.isOneResponseChecked = function () {
            var checkedCt = 0;
            angular.forEach(model.rr.programs, function (program) {
                angular.forEach(program.readinessReview.response, function (response) {
                    if (response.submitResponse) checkedCt++;
                });
            });
            return checkedCt > 0;
        };

        model.setCreateRfe = function (response) {
            response.createRfe = response.type.codeName.toLowerCase().trim() === 'submit rfe';
        };

        function confirmThenProcess(actionText, doProcessing) {
            var confirmationMsg = 'Are you sure you want to ' + actionText + ' this readiness review? This procedure is irreversible.';
            alertSvc.confirm(confirmationMsg, doProcessing);
        }

        function validateThenProcess(actionText, doProcessing) {
            readinessValidationSvc.validate(model.rr).then(function (results) {

                confirmThenProcess(actionText, doProcessing);

                //Per Sherri's request on 10/2/2018, disable this validation.
                //if (results.length === 0) {
                //    confirmThenProcess(actionText, doProcessing);
                //} else {
                //    alertSvc.openModalAlert('The readiness review is not valid. Please look at the errors in each tab at the top of the page and fix them.');
                //}
            });
        }

        function sendRecommandResultNotification(attachmentParams, reqPgms) {
            var academicYear = helperSvc.getAcademicYear();
            var commIds = [];
            var commNames = "";

            model.data.selectedRR.programs.forEach(function (program) {
                if (reqPgms.indexOf(program.programName) > -1)
                {
                    program.commissionIds.forEach(function (comId) {
                        if (commIds.indexOf(comId.commissionId) === -1)
                            commIds.push(comId.commissionId);
                    });
                }
            });
            
            commIds.forEach(function (commId) {
                var comObj = commissionTypes.filter(function (item) { return item.id === commId });
                if (commNames.length > 1)
                {
                    commNames = commNames + " and "
                }
                commNames = commNames + comObj[0].name + '(' + comObj[0].abrv + ')'
            });
            var currentYear = helperSvc.formatDateWithCurrent(null).getFullYear();

            var messageParamaters = [{
                messageParameterName: "CurrentYear",
                messageParameterKey: null,
                messageParameterValue: currentYear.toString()
            },
            {
                messageParameterName: "ReviewYear",
                messageParameterKey: null,
                messageParameterValue: (currentYear + 1).toString()
            },
            {
                messageParameterName: "ReviewYearSpan",
                messageParameterKey: null,
                messageParameterValue: helperSvc.getYearSpan(currentYear + 2).toString()
            },
            {
                messageParameterName: "LastName",
                messageParameterKey: null,
                messageParameterValue: model.data.selectedRR.contactPersonJson.lastName
            },
            {
                messageParameterName: "CommissionFullNames",
                messageParameterKey: null,
                messageParameterValue: commNames
            },
            {
                messageParameterName: "InstitutionName",
                messageParameterKey: null,
                messageParameterValue: model.data.selectedRR.organizationJson.organizationName
            }];

            var recipients = messageSvc.createRecipientDtos([model.data.selectedRR.contactPersonId], contactTypes.INSTITUTIONCONTACTONLY);
            messageSvc.buildAndSendMessage(recipients, [readinessEmailAddress], messageTemplateTypes.RRRESULT, messageParamaters, null, null, null, attachmentParams);
        };

        function sendAcceptanceNotification() {
            var academicYear = helperSvc.getAcademicYear();
            var programList = "";

            model.data.selectedRR.programs.forEach(function (program) {
                if (program)
                    programList = programList + program.programName + '(' + program.degreeCode + ')' + '<br>' 
            });

            var currentYear = helperSvc.formatDateWithCurrent(null).getFullYear();

            var messageTemplate = messageTemplateTypes.RRACCEPTED;
            var messageParamaters = [{
                messageParameterName: "CurrentYear",
                messageParameterKey: null,
                messageParameterValue: currentYear.toString()
            },
            {
                messageParameterName: "ReviewYear",
                messageParameterKey: null,
                messageParameterValue: (currentYear + 1).toString()
            },
            {
                messageParameterName: "ReviewYearSpan",
                messageParameterKey: null,
                messageParameterValue: helperSvc.getYearSpan(currentYear + 2).toString()
            },
            {
                messageParameterName: "ProgramList",
                messageParameterKey: null,
                messageParameterValue: programList
            },
            {
                messageParameterName: "InstitutionName",
                messageParameterKey: null,
                messageParameterValue: model.data.selectedRR.organizationJson.organizationName
            }];

            var recipients = messageSvc.createRecipientDtos([model.data.selectedRR.contactPersonId], contactTypes.INSTITUTIONCONTACTONLY);
            messageSvc.buildAndSendMessage(recipients, [readinessEmailAddress], messageTemplate, messageParamaters);
        };

        model.addStatus = function (statusId) {
            var newStatus = {
                readinessProcessTrackingId: 0,
                commitDate: new Date(),
                readinessId: model.rr.readinessId,
                readinessStatusId: statusId
            };
            model.rr.readinessProcessTrackingDtos.unshift(newStatus);
        };

        model.updateAccountNumber = function (commission) {

        }

        function createReadinessDto() {
            var readinessDto = angular.copy(model.rr);
            delete readinessDto["@odata.context"];
            delete readinessDto.$promise;
            delete readinessDto.$resolved;
            return readinessDto;
        }

        function getAcceptance(statusId) {
            if (isAcknowledgeMode()) {
                setAcceptance('acknowledge');
            } else if (isRecommendationMode()) {
                setAcceptance('recommendation');
            }
            else if (isAcceptMode()) {
                setAcceptance('accept');
            }
            else if (isResponseFinalized()) {
                setAcceptance('informedResult');
            } else {
                setAcceptance();
            }
        }

        function isAcknowledgeMode() {
            return getCurrentStatus() === model.readinessStatuses.WAITING_FOR_ABET_ACKNOWLEDGEMENT;
        }

        function isAcceptMode() {
            return getCurrentStatus() === model.readinessStatuses.SUBMITTED_WAITING_FOR_ACCEPTANCE;
        }

        function isRecommendationMode() {
            return getCurrentStatus() === model.readinessStatuses.REVIEW_IN_PROGRESS;
        }

        function isResponseFinalized() {
            return getCurrentStatus() === model.readinessStatuses.INFORMED_REVIEW_RESULT;
        }

        function getCurrentStatus() {
            return model.rr.readinessProcessTrackingDtos && model.rr.readinessProcessTrackingDtos.length ?
                model.rr.readinessProcessTrackingDtos[0].readinessStatusId : null;
        }

        function setAcceptance(property) {
            model.acceptanceTitle = model.defaultTitle;
            for (var key in model.acceptance) {
                model.acceptance[key] = (key == property);
                if (key == property) model.acceptanceTitle = model.titles[key];
            }
        };

        function refreshRR() {
            return readinessSvc.getRRById(readinessSvc.data.selectedRR.readinessId).then(function (rr) {
                readinessSvc.setSelectedRR(rr);
                model.rr = readinessSvc.data.selectedRR;
                readinessValidationSvc.invokeValidation();
                model.hasErrors = model.data.manageStatusErrors.length > 0;
                getAcceptance();
                initResponses();
                refreshRRNotes();
            });
        }

        function initResponses() {
            for (var i = 0; i < model.rr.programs.length; i++) {
                var program = model.rr.programs[i];
                if (program.readinessReview) {
                    if (!program.readinessReview.response || !Array.isArray(program.readinessReview.response))
                        program.readinessReview.response = [];

                    angular.forEach(program.commissionIds, function (commission) {
                        var commissionName = getCommissionName(commission.commissionId);

                        var responseExists = function (response) {
                            return response.commissionId === commission.commissionId;
                        };

                        if (!program.readinessReview.response.some(responseExists)) {
                            var newResponse = {
                                isSubmitted: false,
                                submitResponse: false,
                                createRfe: false,
                                commissionName: commissionName,
                                commissionId: commission.commissionId
                            }
                            program.readinessReview.response.push(newResponse);
                        }
                    });
                } 
            }

        }

        function getCommissionName (id) {
            var commission = model.selectedCommissions.find(function (item) {
                return item.codeKey === id;
            });
            return commission ? commission.codeName : "";
        }

        function refreshRRNotes() {
            var notesDataSource = {
                dataHolder: model,
                dataLocationName: 'notes',
                svcCallback: noteSvc.getNotesByReferenceId,
                svcCallbackArguments: [model.rr.readinessId, noteTypes.READINESS],
                odataResource: true
            };

            var dataSourceArray = [notesDataSource];
            var promise = helperSvc.getData(dataSourceArray);

            promise.then(function () {
                broadcastLoadRRNotesMsg();
            });
        }

        function broadcastLoadRRNotesMsg() {
            eventSvc.broadcast('loadRRNotes');
        }

        var activate = function () {
           
            
            model.data = readinessSvc.data;

            
            
            model.contact = readinessSvc.data.selectedRR.contactPersonJson;
            model.organization = readinessSvc.data.selectedRR.organizationJson;
            codeSvc.getReadinessResponseTypes().then(function (data) {
                model.responseTypes = helperSvc.getValue(data);
            });
            codeSvc.getCommissions().then(function (data) {

                var commissions = helperSvc.getValue(data);
                var ids = readinessSvc.data.selectedRR.commissionIds && readinessSvc.data.selectedRR.commissionIds.length ? readinessSvc.data.selectedRR.commissionIds : [];
                model.selectedCommissions = commissions.filter(function (commission) {
                    return readinessSvc.data.selectedRR.commissionIds.indexOf(commission.codeKey) > -1;
                });
                refreshRR();
            });
            
        }();
    };
    module.controller('rrManageStatusCtrl', rrManageStatusCtrl);

}(angular.module('readinessReview')));