(function (module) {

    var templateRoot = "/Apps/assignment/templates/common/";

    module.directive('assignmentSummary', function ($state, $stateParams, $filter, alertSvc, assignmentSvc, tcAssignmentSvc, pevAssignmentSvc, helperSvc, teamMemberTypeNames, eventSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'assignmentSummary.html',
            scope: {
                mode: '@'
            },
            link: function (scope, elem, attrs) {
                scope.pevMode = scope.mode ? scope.mode.toLowerCase().contains('pev') : false;
                scope.currentSocietyId = $stateParams.societyId ? parseInt($stateParams.societyId) : null;
                scope.tcMode = !scope.pevMode; // Default to tc mode if no mode is given
                scope.inReleaseMode = false;
                scope.pageTitle = scope.pevMode ? 'Program Evaluators' : 'Team Chairs and Editors';
                scope.title = 'Assign ' + (scope.pevMode ? 'Program Evaluators' : 'And Release Team Chairs and Editors');
                scope.statistics = [];
                scope.isDataReady = false;

                scope.openMasterInstructions = function () {
                    if (scope.tcMode) tcAssignmentSvc.openMasterInstructions();
                    if (scope.pevMode) pevAssignmentSvc.openMasterInstructions();
                };

                scope.openVideoInstructions = function () {
                    if (scope.pevMode) alertSvc.openVideoModal("BPwy0r8h57U", "PEV Assignment Demo");
                };

                scope.releaseHelpTxt = 'Once you release assignments you will be unable to make further changes. If a change is necessary after release you\'ll need to contact an ABET Staff member to make the change on your behalf.';
                scope.selectionHelpTxt = 'Only programs with releasable assignments will be available for selection. Assignments cannot be released if:' +
                                         '<ul class="semi-style">' +
                                            '<li>Visit date is unconfirmed</li>' +
                                            '<li>PEVs have not been assigned</li>' +
                                            '<li>Assignments have already been released</li>' +
                                         '</ul>';

                activate();

                // Release TCs and Editors

                scope.releaseTeamChairs = function () {
                    alertSvc.confirm("You are about to release all Team Chairs and Co-Team Chairs for your commission. This process is irreversible. Do you want to proceed?", release)
                    function release() {
                        scope.inReleaseMode = true;
                        tcAssignmentSvc.releaseTCsAndEditors(teamMemberTypeNames.TEAMCHAIR).then(function () {
                            alertSvc.addAlertSuccess("You have successfully released all TC's and CO-TC's");
                            scope.inReleaseMode = false;
                        });
                    };
                };

                scope.releaseEditor1 = function () {
                    alertSvc.confirm("You are about to release all Editor 1's for your commission. This process is irreversible. Do you want to proceed?", release)
                    function release() {
                        scope.inReleaseMode = true;
                        tcAssignmentSvc.releaseTCsAndEditors(teamMemberTypeNames.EDITOR1).then(function () {
                            alertSvc.addAlertSuccess("You have successfully released all Editor 1's");
                            scope.inReleaseMode = false;
                        });
                    }
                };

                scope.releaseEditor2 = function () {
                    alertSvc.confirm("You are about to release all Editor 2's for your commission. This process is irreversible. Do you want to proceed?", release)
                    function release() {
                        scope.inReleaseMode = true;
                        tcAssignmentSvc.releaseTCsAndEditors(teamMemberTypeNames.EDITOR2).then(function () {
                            alertSvc.addAlertSuccess("You have successfully released all Editor 2's");
                            scope.inReleaseMode = false;
                        });
                    };
                };

                // Release PEVs and Observers

                scope.getProgramSelectionCounter = function () {
                    return pevAssignmentSvc.programSelectionCounter;
                };

                scope.releasePending = function () {
                    return pevAssignmentSvc.releasePending;
                };

                scope.showReleasableOnly = function () {
                    return pevAssignmentSvc.showReleasableOnly;
                };
                
                var clearSelections = function () {
                    pevAssignmentSvc.data.reviewPrograms.forEach(function (program) {
                        if (program.selected) program.selected = false;
                    })
                };

                scope.toggleSelection = function () {
                    pevAssignmentSvc.releasingPEVs = false;
                    pevAssignmentSvc.releasingObservers = false;
                    pevAssignmentSvc.showReleasableOnly = true;

                    pevAssignmentSvc.programSelectionCounter = 0;
                    pevAssignmentSvc.releasePending = !pevAssignmentSvc.releasePending;

                    if (!pevAssignmentSvc.releasePending) clearSelections();
                };

                scope.scrollToBottom = function () {
                    window.setTimeout(function () {
                        var elem = document.querySelector('#assignment-release-list');
                        if (elem) {
                            elem.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        }
                    }, 0);
                };

                scope.toggleSelectionVisibility = function () {
                    pevAssignmentSvc.showReleasableOnly = !pevAssignmentSvc.showReleasableOnly;
                };

                scope.selectPevs = function () {
                    scope.toggleSelection();
                    pevAssignmentSvc.releasingPEVs = true;
                    pevAssignmentSvc.releasingObservers = false;
                };

                scope.selectObservers = function () {
                    scope.toggleSelection();
                    pevAssignmentSvc.releasingPEVs = false;
                    pevAssignmentSvc.releasingObservers = true;
                };

                scope.getReleaseLabel = function () {
                    return pevAssignmentSvc.releasingPEVs ? "PEVs" : "Observers";
                };

                scope.releasePevsOrObservers = function () {
                    var selectedPrograms = scope.data.reviewPrograms.filter(function (program) {
                        return program.selected === true;
                    });

                    if (selectedPrograms.length > 0) {
                        if (pevAssignmentSvc.releasingPEVs) {
                            alertSvc.confirm("You are about to release PEVs for " + pevAssignmentSvc.programSelectionCounter + " selected programs. Do you want to proceed?", releasePevFunc);
                        } else if (pevAssignmentSvc.releasingObservers) {
                            alertSvc.confirm("You are about to release Observers for " + pevAssignmentSvc.programSelectionCounter + " selected programs. Do you want to proceed?", releaseObsvFunc);
                        }

                        function releasePevFunc() {
                            scope.inReleaseMode = true;
                            scope.isDataReady = false;
                            pevAssignmentSvc.releasePevs(selectedPrograms).then(function () { reset("PEVs"); });
                        }
                        function releaseObsvFunc() {
                            scope.inReleaseMode = true;
                            scope.isDataReady = false;
                            pevAssignmentSvc.releaseObservers(selectedPrograms).then(function () { reset("Observers"); });
                        }
                        function reset(label) {
                            clearSelections();
                            scope.toggleSelection();
                            pevAssignmentSvc.refreshReviewPrograms().then(function () {
                                scope.updatePevStats();
                                scope.isDataReady = true;
                                alertSvc.addAlertSuccess("You have successfully released the selected " + label + ".");
                                scope.inReleaseMode = false;
                            });
                        }
                    } else {
                        alertSvc.openModalAlert("Please make a selection above to release assignments.", "No Assignments Selected");
                    }
                };


                // Tool Links

                scope.openTcAssignmentTool = function () {
                    $state.go('tcAssignmentTool');
                };

                scope.openEditorAssignmentTool = function (editorType) {
                    $state.go('editorAssignmentTool', { type: editorType });
                };

                scope.openPevAssignmentTool = function () {
                    $state.go('pevAssignmentTool', { societyId: scope.currentSocietyId });
                };

                // Set Statistics
                var total = {};
                function activate() {

                    if (scope.tcMode) {

                        tcAssignmentSvc.loadAllData().then(function () {
                            total = {
                                tcs: 0,
                                editor1s: 0,
                                editor2s: 0
                            };

                            scope.data = tcAssignmentSvc.data;

                            scope.data.reviewTeams.forEach(function (review) {
                                if (review.teamChair) total.tcs += 1;
                                if (review.editor1) total.editor1s += 1;
                                if (review.editor2) total.editor2s += 1;
                            })

                            scope.teamChairsReleased = function () { return scope.data.teamChairsReleased; }
                            scope.editor1Released = function () { return scope.data.editor1Released; }
                            scope.editor2Released = function () { return scope.data.editor2Released; }

                            scope.statistics = [
                                { label: 'Total Reviews', data: scope.data.reviewTeams.length },
                                { label: 'Team Chairs', data: total.tcs, percentage: calculatePercentage(total.tcs, scope.data.reviewTeams.length), isReleased: scope.teamChairsReleased },
                                { label: 'Editor 1\'s', data: total.editor1s, percentage: calculatePercentage(total.editor1s, scope.data.reviewTeams.length), isReleased: scope.editor1Released },
                                { label: 'Editor 2\'s', data: total.editor2s, percentage: calculatePercentage(total.editor2s, scope.data.reviewTeams.length), isReleased: scope.editor2Released }
                            ];

                            scope.isDataReady = true;
                        });

                    } else if (scope.pevMode) {

                        pevAssignmentSvc.loadAllData(scope.currentSocietyId).then(function () {
                            scope.data = pevAssignmentSvc.data;
                            scope.calculatePevStatistics()
                            scope.isDataReady = true;
                            eventSvc.listen(scope.calculatePevStatistics, 'reviewProgramsLoaded', scope);
                        });
                    }
                }

                scope.calculatePevStatistics = function () {
                    total = {
                        pevs: 0,
                        pevsRequired: 0,
                        programsWithObservers: 0
                    };

                    scope.data.reviewPrograms.forEach(function (program) {
                        if (program.pevsRequired) total.pevsRequired += program.pevsRequired;
                        if (program.pevsAssigned) total.pevs += program.pevsAssigned.length;
                        if (program.observersAssigned) ++total.programsWithObservers;
                    })

                    scope.updatePevStats();
                } 

                var ofText = " <span class='help-block'>of</span> ";
                var releasedPevsHelpTxt = 'The ratio below (X of Y) shows the number of PEVs released (X) from the total number of PEVs required (Y)';

                scope.updatePevStats = function () {
                    scope.statistics = [
                        { label: 'Total Programs', data: scope.data.reviewPrograms.length },
                        {
                            label: 'PEVs ' + (getNumOfPevsReleased() > 0 ? 'Released' : 'Required'),
                            data: (getNumOfPevsReleased() > 0 ? getNumOfPevsReleased() + ofText : "") + total.pevsRequired,
                            help: (getNumOfPevsReleased() > 0 ? releasedPevsHelpTxt : null)
                        },
                        { label: 'PEVs Assigned', data: total.pevs, percentage: calculatePercentage(total.pevs, total.pevsRequired) },
                        { label: 'Programs w/ Observers', data: total.programsWithObservers },
                    ];

                    function getNumOfPevsReleased () {
                        var num = 0;
                        pevAssignmentSvc.data.reviewPrograms.forEach(function (program) {
                            if (program.pevsAssigned) {
                                program.pevsAssigned.forEach(function (pev) {
                                    if (pev.statusName != "Not Released") ++num;
                                });
                            }
                        });
                        return num;
                    }
                };

                function calculatePercentage(x, y) {
                    return parseFloat((x / y * (100)).toFixed(1));
                }

                // Export CSV

                scope.tcCsvHeaders = ['Institution', 'Review Type', 'Suggested Visit Date', 'State/Country', 'Total Programs', 'New Programs', 'Onsite Visit Restriction', 'Show Cause', 'Simultaneous Visit', 'Number of Joint Programs', 'MainCampus', 'Multiple Campuses', 'New Criteria', 'Distance Learning', 'Team Chair', 'Team Chair Email', 'Co-Team Chairs', 'Editor 1', 'Editor 2'];
                scope.tcCsvColumns = ['institutionName', 'reviewTypeCode', 'suggestedVisitDate', 'stateOrCountry', 'totalPrograms', 'newPrograms', 'onsiteVisitRestriction', 'showCause', 'simultaneousVisit', 'jointPrograms', 'mainCampus', 'multipleCampuses', 'isPhaseInCriteria', 'distanceLearning', 'teamChair', 'teamChairEmails', 'coTeamChairs', 'editor1', 'editor2'];
             
                scope.getReviewTeamsForCSV = function () {
                    var convertedReviewTeams = [];

                    angular.forEach(scope.data.reviewTeams, function (reviewTeam) {
                        var summary = tcAssignmentSvc.getProgramsSummary(reviewTeam);

                        var tempTeam = {
                            institutionName: reviewTeam.sortName,
                            reviewTypeCode: reviewTeam.reviewTypeCode,
                            suggestedVisitDate: reviewTeam.suggestedVisitDate ? helperSvc.formatDate(reviewTeam.suggestedVisitDate).toLocaleDateString().replace(/\u200E/g, '') : '', // IE 11 adds LTR characters -- remove them. 
                            stateOrCountry: reviewTeam.countryCode === 'US' ? reviewTeam.stateCode : reviewTeam.countryCode || '',
                            totalPrograms: reviewTeam.totalPrograms,
                            newPrograms: summary.newPrograms.length > 0 ? 'Yes' : '',
                            onsiteVisitRestriction: reviewTeam.onsiteVisitRestrictionStatusName,
                            showCause: summary.showCause.length > 0 ? 'Yes' : '',
                            simultaneousVisit: reviewTeam.simultaneousVisits ? getCommissionsString(reviewTeam.simultaneousVisits) : '',
                            jointPrograms: reviewTeam.jointPrograms ? reviewTeam.jointPrograms.length : '',
                            mainCampus : reviewTeam.mainCampus ? getMainCampus(reviewTeam.mainCampus) : '',
                            multipleCampuses: reviewTeam.multipleCampuses ? 'Yes' : '',
                            isPhaseInCriteria: reviewTeam.isPhaseInCriteria ? 'Yes' : '',
                            distanceLearning: summary.distanceLearningType ? summary.distanceLearningType : '',
                            teamChair: reviewTeam.teamChair ? reviewTeam.teamChair.lastName + ', ' + reviewTeam.teamChair.firstName  : '',
                            teamChairEmails: reviewTeam.teamChair ? getTeamChairEmails(reviewTeam.teamChair) : '',
                            coTeamChairs: reviewTeam.coTeamChairs ? getNamesString(reviewTeam.coTeamChairs) : '',
                            editor1: reviewTeam.editor1 ? getNamesString(reviewTeam.editor1) : '',
                            editor2: reviewTeam.editor2 ? getNamesString(reviewTeam.editor2) : ''
                        };

                        convertedReviewTeams.push(tempTeam);

                    });

                    function getTeamChairEmails (teamChair){//this was already working, it just needed DB update
                        var result = '';                      
                        result += teamChair.emailAddress;
                        return result;
                    }

                    function getMainCampus (mainCampus){
                        if(mainCampus.length > 0){
                            var address = mainCampus[0];
                            return (address.address2 !== null ?  (address.address2 + ' ') : '')  + address.city + ', ' + (address.stateCode || address.province) + ', ' + address.countryCode;
                        }
                        return '';
                        
                    }

                    convertedReviewTeams = $filter('orderBy')(convertedReviewTeams, 'institutionName');

                    return convertedReviewTeams;
                }


                scope.pevCsvHeaders = ['Institution', 'Commission', 'Program', 'Discipline', 'Review Type', 'Assignment Status', 'Onsite Visit Restriction', 'PEVs Assigned', 'Observers Assigned', 'Visit Start Date', 'Visit End Date', 'State', 'Country', 'Distance Learning', 'Team Chairs', 'PEVs', 'Observers'];
                scope.pevCsvColumns = ['institutionName', 'commissionName', 'programName', 'disciplineName', 'reviewTypeCode', 'assignmentStatus', 'onsiteVisitRestriction', 'pevsAssigned', 'obsAssigned', 'visitStartDate', 'visitEndDate', 'state', 'countryName', 'distanceLearning', 'teamChairs', 'pevs', 'observers'];

                scope.getReviewProgramsForCSV = function() {
                    var convertedReviewPrograms = [];

                    angular.forEach(scope.data.reviewPrograms, function (reviewProgram) {
                        var programDetail = reviewProgram.programDetail ? reviewProgram.programDetail[0] : null;

                        var tempProgram = {
                            institutionName: reviewProgram.organizationName || "",
                            commissionName: reviewProgram.commissionName || "",
                            programName: programDetail ? programDetail.programName : "",
                            disciplineName: programDetail ? (programDetail.disciplineName || "") : "",
                            reviewTypeCode: programDetail ? (programDetail.programReviewTypeCode || "") : "",
                            assignmentStatus: reviewProgram.assignmentStatus || "",
                            onsiteVisitRestriction: reviewProgram.onsiteVisitRestrictionStatusName,
                            visitStartDate: reviewProgram.visitStartDate || "",
                            visitEndDate: reviewProgram.visitEndDate || "",
                            pevsAssigned: " " + pevAssignmentSvc.getPEVsRatio(reviewProgram), //add space at beginning to force excel to format as text
                            obsAssigned: reviewProgram.observersAssigned ? reviewProgram.observersAssigned.length : "0",
                            state: reviewProgram.stateCode || '',
                            countryName: reviewProgram.countryName,
                            distanceLearning: programDetail ? (programDetail.distanceLearningType || "") : "",
                            teamChairs: reviewProgram.assignedTcs ? getNamesString(reviewProgram.assignedTcs) : '',
                            pevs: reviewProgram.pevsAssigned ? getNamesAndStatus(reviewProgram.pevsAssigned) : '',
                            observers: reviewProgram.observersAssigned ? getNamesAndStatus(reviewProgram.observersAssigned) : ''
                        };

                        convertedReviewPrograms.push(tempProgram);
                    });

                    convertedReviewPrograms = $filter('orderBy')(convertedReviewPrograms, ['institutionName', 'commissionName', 'programName']);

                    return convertedReviewPrograms; 
                }

                function getCommissionsString(commissions) {
                    var result = '';
                    for (var i = 0; i < commissions.length; i++) {
                        if (i + 1 !== commissions.length) {
                            result += commissions[i].abbreviatedName + ', ';
                        } else {
                            result += commissions[i].abbreviatedName;
                    }
                    }
                    return result;
                }

                function getNamesAndStatus(names) {
                    var result = '';
                    for (var i = 0; i < names.length; i++) {
                        if (i + 1 !== names.length) {
                            result += names[i].firstName + ' ' + names[i].lastName + '[' +  names[i].statusName + ']' + ', ';
                        } else {
                            result += names[i].firstName + ' ' + names[i].lastName + '[' + names[i].statusName + ']';
                        }
                    }
                    return result;
                }

                function getNamesString(names) {
                    var result = '';
                    for (var i = 0; i < names.length; i++) {
                        if (i + 1 !== names.length) {
                            result += names[i].lastName + ', ' + names[i].firstName + ' : ';
                        } else {
                            result += names[i].lastName + ', ' + names[i].firstName;
                        }
                    }
                    return result;
                }
            }
        };
    });

    module.directive('matrix', function ($filter, $window, $rootScope, tcAssignmentSvc, pevAssignmentSvc, alertSvc, teamMemberTypeNames, helperSvc) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'matrix.html',
            scope: {
                reviews: '=',
                volunteers: '=',
                allReviews: '=',
                allVolunteers: '=',
                assign1Func: '&assign',
                assign2Func: '&assign2',
                assign1Text: '@?assignText',
                assign2Text: '@?',
                unassignFunc: '&unassign',
                mode: '@'
            },
            link: {
                pre: function (scope) {
                    scope.reviews = $filter('orderBy')(scope.reviews, 'index');
                    scope.volunteers = $filter('orderBy')(scope.volunteers, 'index');
                },
                post: function (scope, elem, attrs) {
                    var tcMode = scope.mode.toLowerCase().contains('teamchair');
                    var pevMode = scope.mode.toLowerCase().contains('pev');
                    var editorMode = scope.mode.contains('1') || scope.mode.contains('2');
                    var editorType = editorMode ? scope.mode.charAt(0).toUpperCase() + scope.mode.slice(1).toLowerCase() : '';

                    scope.formatDate = helperSvc.formatDate;
                    scope.pevMode = pevMode;
                    scope.tcMode = tcMode;
                    scope.assign1Text = attrs.assignText ? scope.assign1Text : 'Assign';
                    scope.matrix = pevMode ? pevAssignmentSvc.data.matrix : tcAssignmentSvc.data.matrix;
                    scope.teamMemberTypeId = null;

                    $rootScope.$watch(
                        function () { return pevMode ? pevAssignmentSvc.data.matrix : tcAssignmentSvc.data.matrix; }, // Value to watch
                        function (newVal, oldVal) { if (newVal != oldVal) scope.matrix = pevMode ? pevAssignmentSvc.data.matrix : tcAssignmentSvc.data.matrix; }, // Callback
                        true
                    );

                    if (tcMode) {
                        scope.teamMemberTypeId = teamMemberTypeNames.TEAMCHAIR;
                    } else if (editorMode) {
                        scope.teamMemberTypeId = scope.mode.contains('1') ? teamMemberTypeNames.EDITOR1 : teamMemberTypeNames.EDITOR2
                    } else if (pevMode) {
                        scope.teamMemberTypeId = teamMemberTypeNames.PEV;
                    }

                    var keyOptions = {
                        TEAMCHAIR: {
                                title: 'Team Chair', icon: 'user', classes: 'assigned teamchair', popoverTitle: 'Assignment',
                                popoverMsg: 'The commissioner has been assigned as a Team Chair.' },
                        COTEAMCHAIR: {
                                title: 'Co-Team Chair', icon: 'user', classes: 'assigned co-teamchair', popoverTitle: 'Assignment',
                                popoverMsg: 'The commissioner has been assigned as a Co-Team Chair.' },
                        EDITOR: {
                                title: editorType, icon: 'user', classes: 'assigned editor', popoverTitle: 'Assignment',
                                popoverMsg: 'The commissioner has been assigned as an ' + editorType },
                        PEV: {
                                title: 'PEV', icon: 'user', classes: 'assigned pev', popoverTitle: 'Assignment',
                                popoverMsg: 'The volunteer has been assigned as a Program Evaluator.' },
                        OBSERVER: {
                                title: 'Observer', icon: 'user', classes: 'assigned observer', popoverTitle: 'Assignment',
                                popoverMsg: 'The volunteer has been assigned as a Visit Observer.' },
                        ASSIGNED: {
                                title: 'Assigned', icon: null, classes: 'color-block has-assignments', popoverTitle: 'Has Assignments',
                                popoverMsg: 'The number before a volunteer or institution name will be highlighted. For volunteers this indicates 1 or more assignments during this review cycle; for institutions it indicates that a TC has been assigned (excludes Co-TC\'s).' },
                        DISABLED: {
                                title: 'Disabled', icon: null, classes: 'color-block disabled', popoverTitle: 'Disabled',
                                popoverMsg: 'A cell is disabled because the maximum number of assignments have been made for the chosen role on the chosen review team, or because the chosen volunteer has a conflict with that particular visit.' },
                        OVERLAP: {
                                title: 'Overlap', icon: 'exclamation', classes: 'warning-msg', popoverTitle: 'Overlapping Assignments', noPaddingLeft: true,
                                popoverMsg: 'The volunteer has another assignment for this review team during the current cycle, i.e. as a' + (tcMode ? 'n Editor 1 or Editor 2.' : ' Team Chair or Co-Team Chair.') },
                        DATECONFLICT: {
                                title: 'Date Conflict', icon: 'calendar-times-o', classes: 'date-conflict', popoverTitle: 'Date Conflict',
                                popoverMsg: 'The volunteer has a conflict with the institution\'s ' + (pevMode ? 'visit start date and cannot be assigned.' : 'suggested visit date but can still be assigned.') },
                        OTHERCONFLICTS: {
                                title: 'Other Conflicts', icon: 'exclamation-circle', classes: 'other-conflicts', popoverTitle: 'Has Conflicts',
                                popoverMsg: 'The cell where a volunteer and review meet will be highlighted indicating that the volunteer has a conflict with a particular visit.' },
                        EXCOM: {
                                title: 'Excom', icon: null, classes: 'color-block excom', popoverTitle: 'Excom',
                                popoverMsg: 'The volunteer\'s name will be highlighted indicating that they are Excom and can have no more than 1 assignment. Once they have an assignment their cells will be highlighted and disabled.' }
                    };

                    scope.key = [];

                    if (tcMode) {
                        scope.key = [
                            [keyOptions.TEAMCHAIR, keyOptions.COTEAMCHAIR, keyOptions.DATECONFLICT],
                            [keyOptions.OVERLAP, keyOptions.EXCOM, keyOptions.OTHERCONFLICTS],
                            [keyOptions.ASSIGNED, keyOptions.DISABLED, {}]
                        ];
                    } else if (editorMode) {
                        scope.key = [
                            [keyOptions.EDITOR, keyOptions.OVERLAP],
                            [keyOptions.EXCOM, keyOptions.OTHERCONFLICTS],
                            [keyOptions.ASSIGNED, keyOptions.DISABLED]
                        ];
                    } else if (pevMode) {
                        scope.key = [
                            [keyOptions.PEV, keyOptions.OBSERVER],
                            [keyOptions.DATECONFLICT, keyOptions.OTHERCONFLICTS],
                            [keyOptions.ASSIGNED, keyOptions.DISABLED]
                        ];
                    }

                    scope.getAssignmentMsg = function (review) {
                        var length = null;
                        var title = "";

                        if (tcMode) {
                            title = " team chair ";
                            if (review.teamChairs) length = review.teamChairs.length;
                        } else if (editorMode) {
                            title = " editors ";
                            if (editorType.contains('1')) {
                                if (review.editor1) length = review.editor1.length;
                            } else {
                                if (review.editor2) length = review.editor2.length;
                            }
                        } else if (pevMode) {
                            title = " PEVs ";
                            if (review.pevsAssigned) length = review.pevsAssigned.length;
                        }
                        return length ? length + title + "assigned" : "No" + title + "assigned";
                    };

                    scope.getPevIcon = function (review) {
                        return pevAssignmentSvc.pevReqs.hasAssignees(review) ? "check success-color" : "user-circle";
                    };

                    scope.getPevNums = function (review) {
                        var assignedNum = review.pevsAssigned ? review.pevsAssigned.length : 0;
                        var requiredNum = review.pevsRequired ? review.pevsRequired : 0;

                        if (assignedNum == 0 && requiredNum == 0) {
                            return "N/A";
                        }
                        return assignedNum + "/" + requiredNum;
                    };

                    scope.getPevDetails = function (review) {
                        var assignedNum = review.pevsAssigned ? review.pevsAssigned.length : 0;
                        var requiredNum = review.pevsRequired ? review.pevsRequired : 0;

                        if (assignedNum == 0 && requiredNum == 0) {
                            return "No PEVs are required for this visit.";
                        }
                        return '<ul class="no-style">' +
                                 '<li>' + assignedNum + ' PEV(s) assigned</li>' +
                                 '<li>' + requiredNum + ' PEV(s) required</li>' +
                               '</ul>';
                    };

                    scope.isTeamChair = function (volunteer) {
                        return tcMode ? tcAssignmentSvc.isTeamChair(volunteer) : false;
                    };

                    scope.isCoTeamChair = function (volunteer) {
                        return tcMode ? tcAssignmentSvc.isCoTeamChair(volunteer) : false;
                    };

                    scope.isChair = function (volunteer, forceCheck) {
                        if (tcMode || forceCheck) {
                            return tcAssignmentSvc.isTeamChair(volunteer) || tcAssignmentSvc.isCoTeamChair(volunteer);
                        }
                        return false;
                    };

                    scope.isExcomAssigned = function (volunteer, review, forceCheck) {

                        if (tcMode || forceCheck) {
                            if (volunteer.assignedReviews) {
                                for(var i= 0; i < volunteer.assignedReviews.length; i++){
                                    if (volunteer.assignedReviews[i].reviewTypeCode === 'IR')
                                    {
                                        continue;
                                    }
                                    if(volunteer.assignedReviews[i].teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || review.teamMemberTypeId === teamMemberTypeNames.COTEAMCHAIR)
                                    {
                                        return true;
                                    }
                                }
                            }
                        }                           
                        
                        return false;
                    }

                    scope.isEditor = function (volunteer, forceCheck) {
                        if (editorMode || forceCheck) {
                            return tcAssignmentSvc.isEditor1(volunteer) || tcAssignmentSvc.isEditor2(volunteer);
                        }
                        return false;
                    };

                    scope.isPEV = function (volunteer) {
                        return pevMode ? pevAssignmentSvc.isPEV(volunteer) : false;
                    };

                    scope.isObserver = function (volunteer) {
                        return pevMode ? pevAssignmentSvc.isObserver(volunteer) : false;
                    };

                    scope.assign1IsVisible = function (currentReview) {
                        var idName = pevMode ? "programReviewDisciplineId" : "reviewTeamId";                      
                        var review = scope.reviews.find(function (review) {
                            return review[idName] === currentReview[idName];
                        });

                        if (review) {
                            if (tcMode) {
                                return review.teamChairs ? review.teamChairs.length < 1 : true;
                            } else if (pevMode) {
                                if (!review.pevsRequired) {
                                    return false;
                                } else if (review.pevsAssigned) {
                                    return review.pevsAssigned.length < review.pevsRequired;
                                }
                            }
                        }
                        return true;
                    };

                    scope.assign2IsVisible = function (currentReview) {
                        var idName = pevMode ? "programReviewDisciplineId" : "reviewTeamId";                      
                        var review = scope.reviews.find(function (review) {
                            return review[idName] === currentReview[idName];
                        });
                        var hasAssign2 = attrs.assign2 ? true : false;
                        if (pevMode && review) {
                            return hasAssign2 && review.societyObserverFlag;
                        }
                        return hasAssign2;
                    };

                    scope.isAssigned = function (volunteer, review) {
                        if (tcMode) {
                                return tcAssignmentSvc.isTeamChair(volunteer) || tcAssignmentSvc.isCoTeamChair(volunteer);                          
                        } else if (editorMode) {
                            return editorType.contains('1') ? tcAssignmentSvc.isEditor1(volunteer) : tcAssignmentSvc.isEditor2(volunteer);
                        } else if (pevMode) {
                            return scope.isPEV(volunteer) || scope.isObserver(volunteer);
                        }
                        return false;
                    };

                    scope.isReleased = function (volunteer, review) {
                        var isReleased = false;
                        var volunteerDetails;

                        if (pevMode) {
                            if (review.pevsAssigned) {
                                volunteerDetails = review.pevsAssigned.find(function (pev) {
                                    return pev.volunteerId === volunteer.volunteerId;
                                });
                            }

                            if (!volunteerDetails && review.observersAssigned) {
                                volunteerDetails = review.observersAssigned.find(function (observer) {
                                    return observer.volunteerId === observer.volunteerId;
                                });
                            }

                            if (volunteerDetails) isReleased = volunteerDetails.hasOwnProperty('statusName') && volunteerDetails.statusName !== 'Not Released';
                        }
                        return isReleased;
                    };

                    scope.isUnassignable = function (volunteer, review) {
                        return scope.isAssigned(volunteer, review) && !scope.isReleased(volunteer, review);
                    };

                    scope.hasAssignments = function (input) {
                        if (input) {
                            var propName = null;
                            if (input.hasOwnProperty('assignedReviews')) propName = 'assignedReviews';
                            if (!pevMode && input.hasOwnProperty('teamChairs')) propName = 'teamChairs';

                            if (propName) return input[propName] ? input[propName].length > 0 : false;
                        }
                        return false;
                    };

                    scope.hasMaxAssignments = function (volunteer, reviewTeamId, review) {
                        if (editorMode) {
                            var review = scope.allReviews.find(function (review) {
                                return review.reviewTeamId === reviewTeamId;
                            });

                            if (review) {
                                var editors = review[editorType.toLowerCase()];

                                if (editors) return editors.every(function (editor) {
                                    return editor.volunteerId !== volunteer.volunteerId;
                                });
                            }
                        } else if (pevMode) {
                            var isAssigned = pevAssignmentSvc.isPEV(volunteer) || pevAssignmentSvc.isObserver(volunteer);
                            return !isAssigned && pevAssignmentSvc.pevReqs.hasAssignees(review) && !review.societyObserverFlag;
                        }
                        return false;
                    };

                    scope.hasAnotherTeamAssignment = function (volunteer, review) {
                        if (tcMode) {
                            return hasAssignmentAs('editor1') || hasAssignmentAs('editor2');
                        } else if (editorMode) {
                            return hasAssignmentAs('teamChairs') || hasAssignmentAs('coTeamChairs') || hasAssignmentAs(editorType.contains(1) ? 'editor2' : 'editor1');
                        }
                        return false;

                        function hasAssignmentAs(arrName) {
                            return (review[arrName]) ? review[arrName].find(function (assignment) { return assignment.volunteerId == volunteer.volunteerId; }) : false;
                        }
                    };

                    //need to change here to include country conflicts for has conflicts
                    scope.hasConflicts = function (volunteer, review) {
                        if (review.reviewTypeCode === 'IR' && (tcMode || editorMode)) {
                            return volunteer.conflicts ? volunteer.conflicts.length > 0 : false;
                        } else {
                            if (volunteer.conflicts && volunteer.conflicts.length > 0)
                                return true;
                            if (!editorMode && volunteer.countryConflicts && volunteer.countryConflicts.length > 0)
                                return true;
                        }
                        return false;
                    };

                    scope.hasDateConflicts = function (volunteer) {
                        return editorMode ? false : volunteer.hasDateConflict;
                    };

                    scope.isAtExcomLimit = function (volunteer, review) {
                        if (tcMode && volunteer.isExcom && review.reviewTypeCode !== 'IR') {
                            var volunteerWithAllAssignments = scope.allVolunteers.find(function (vol) {
                                return vol.volunteerId === volunteer.volunteerId;
                                
                            });

                            return scope.isExcomAssigned(volunteerWithAllAssignments, review);
                            
                        }
                        return false;
                    };

                    scope.getTotalProgramNumber = function (volunteer) {
                        var total = 0;
                        if (volunteer.assignedReviews) {
                            //get all reviewAssignment ids
                            var reviewAssignmentIds = volunteer.assignedReviews.map(function (a) { return a.reviewAssignmentId })
                                
                            for (var j = 0; j < scope.reviews.length; j++) {
                                if (scope.reviews[j].teamChairs && scope.reviews[j].teamChairs.length > 0) {
                                    if (helperSvc.arrayContainsByPropertyValue(scope.reviews[j].teamChairs, 'reviewAssignmentId', reviewAssignmentIds))
                                        total += scope.reviews[j].programs.length;
                                }
                            }
                            
                        }
                        return total;
                    }

                    scope.jsonifyVolunteers = function (review) {
                        review.volunteers = JSON.parse(review.volunteers);
                    };

                    scope.formatFixedHeaders = function () {
                        var matrix = document.getElementById("matrix-grid");
                        var matrixNodes = document.querySelectorAll("#matrix-grid > *");
                        var verticalHeaders = document.querySelectorAll("#matrix-grid tr td:first-of-type");

                        // X-axis Scroll
                        matrix.addEventListener('scroll', function () {
                            matrixNodes.forEach(function (node) {
                                node.style.width = (matrix.offsetWidth + matrix.scrollLeft) + "px";
                            });

                            if (matrix.scrollLeft > 0) {
                                // Freeze Vertical Headers
                                verticalHeaders.forEach(function (td) {
                                    td.style.left = matrix.scrollLeft + "px";
                                    td.style.borderRightWidth = "2px";
                                });

                            } else {
                                // Unfreeze Vertical Headers
                                verticalHeaders.forEach(function (td) {
                                    td.style.left = "auto";
                                    td.style.borderRightWidth = "1px";
                                });
                            }
                        }, false);

                        setColWidth();
                        setMatrixHeight();
                        addHoverHighlight();
                        resizeOnWindowResize();
                    };

                    function setColWidth() {
                        // Calculate Col Width
                        var defaultMinWidth = 110;
                        var matrix = document.getElementById("matrix-grid");
                        var verticalHeaders = document.querySelectorAll("#matrix-grid tr td:first-of-type");
                        var verticalHeaderWidth = verticalHeaders[1].offsetWidth;
                        var numOfCols = document.querySelectorAll("#matrix-grid thead td").length - 1;
                        var containerWidth = document.getElementById("matrix-container").offsetWidth;
                        var newMinColWidth = ((numOfCols * defaultMinWidth) + verticalHeaderWidth) < containerWidth ? Math.floor((containerWidth - verticalHeaderWidth) / numOfCols) : defaultMinWidth;

                        // Set Vertical Header Col Width
                        verticalHeaders.forEach(function (td) {
                            td.style.minWidth = verticalHeaderWidth + "px";
                        });

                        // Set Col Width
                        document.querySelectorAll("#matrix-grid tr td:not(:first-child)").forEach(function (td) {
                            td.style.minWidth = newMinColWidth + "px";
                        });

                        // Scroll to update adjusted col width
                        ++matrix.scrollLeft;
                    }

                    function setMatrixHeight() {
                        var tbody = document.querySelector('#matrix-grid tbody');
                        var thead = document.querySelector('#matrix-grid thead');
                        var padding = 140;

                        tbody.style.maxHeight = ($window.innerHeight - thead.offsetHeight - padding) + "px";
                    }

                    function resizeOnWindowResize() {
                        var currentWidth = $window.innerWidth;
                        var currentHeight = $window.innerHeight;

                        window.addEventListener("resize", onResize);

                        scope.$on("$destroy", function () {
                            window.removeEventListener("resize", onResize);
                        });

                        function onResize () {
                            // Width Changes
                            if (currentWidth != $window.innerWidth) {
                                currentWidth = $window.innerWidth;
                                setColWidth();
                            }
                            // Height Changes
                            if (currentHeight != $window.innerHeight) {
                                currentHeight = $window.innerHeight;
                                setMatrixHeight();
                            }
                        }
                    }

                    function addHoverHighlight() {
                        document.querySelectorAll("#matrix-grid tbody tr td:not(:first-child):not(.excom-disabled):not(.has-conflicts)").forEach(function (td) {
                            var colNum = getNum("col", td);
                            var rowNum = getNum("row", td.parentElement);
                            var tdSelector = "td.col-" + colNum;
                            var trSelector = "tr.row-" + rowNum + " td";

                            td.onmouseover = function () {
                                var hasConflicts = td.className.contains("has-conflicts") || (pevMode ? td.className.contains("has-date-conflicts") : false);
                                var isDisabled = td.className.contains("disabled") && td.className.contains("unassigned");

                                if (!(hasConflicts || isDisabled)) {
                                    setBackgroundColor(tdSelector, "lightyellow", rowNum);
                                    setBackgroundColor(trSelector, "lightyellow", colNum);
                                }
                            };

                            td.onmouseout = function () {
                                var hasConflicts = td.className.contains("has-conflicts") || (pevMode ? td.className.contains("has-date-conflicts") : false);
                                var isDisabled = td.className.contains("disabled") && td.className.contains("unassigned");

                                if (!(hasConflicts || isDisabled)) {
                                    setBackgroundColor(tdSelector, "", rowNum);
                                    setBackgroundColor(trSelector, "", colNum);
                                }
                            };
                        });

                        function setBackgroundColor(selector, color, num) {
                            var cells = document.querySelectorAll("#matrix-grid " + selector);

                            for (var i = 0; i < cells.length; i++) {
                                var td = cells[i];
                                var currentNum = selector.contains("tr") ? getNum("col", td) : getNum("row", td.parentElement);

                                if (currentNum > num) {
                                    break;
                                    // Once the highlighted cell is reached do not highlight 
                                    // the remaining cells in the row or column
                                } else {
                                    var isAssigned = td.className.startsWith('assigned') || td.className.contains(' assigned ') || td.className.contains(' assigned');
                                    td.style.background = isAssigned ? '' : color;
                                }
                            }
                        }

                        function getNum(elemName, elem) {
                            var num = elem.className.substr(elem.className.indexOf("-", elem.className.indexOf(elemName)) + 1, 2);
                            if (!Number.isInteger(parseInt(num.charAt(1)))) num = num.substr(0, 1);
                            return parseInt(num);
                        }
                    }
                }
            }
        };
    });

    module.directive('assignmentTool', function ($state, $uibModal, $stateParams, assignmentSvc, tcAssignmentSvc, pevAssignmentSvc, helperSvc, barSvc, alertSvc, teamMemberTypeNames) {
        return {
            restrict: 'E',
            templateUrl: templateRoot + 'assignmentTool.html',
            transclude: {
                'title': '?pageTitle',
                'instructions': '?instructions',
                'leftTitle': '?leftTitle',
                'leftFilters': '?leftFilters',
                'leftSummary': '?leftSummary',
                'leftButtonText': '?leftButtonText',
                'rightTitle': '?rightTitle',
                'rightFilters': '?rightFilters',
                'rightSummary': '?rightSummary',
                'rightButtonText': '?rightButtonText',
                'matrixButtons': '?matrixButtons',
                'matrixContainer': '?matrixContainer'
            },
            scope: {
                'reviews': '=',
                'volunteers': '=',
                'selectedReviews': '=',
                'selectedVolunteers': '=',
                'getLeftDataFunc': '&getLeftData',
                'getRightDataFunc': '&getRightData',
                'mode': '@',
                'addBtn': '@',
                'searchFromOtherSocietiesBtn': '@',
                'results': '<'
            },
            link: function (scope, elem, attrs) {
                scope.pevMode = scope.mode ? scope.mode.toLowerCase().contains('pev') : false;
                scope.tcMode = !scope.pevMode; // Default to tc mode if not pevMode
                scope.editorMode = $stateParams.type ? true : false;
                scope.currentSocietyId = $stateParams.societyId ? parseInt($stateParams.societyId) : null;
                scope.addTcHelpText = "Add volunteers who are not currently in the commissioner list that can be designated as Team Chairs.";

                scope.openMasterInstructions = function () {
                    if (scope.tcMode) tcAssignmentSvc.openMasterInstructions();
                    if (scope.pevMode) pevAssignmentSvc.openMasterInstructions();
                };
                
                scope.reviewLabel = scope.pevMode ? 'programs' : 'reviews';
                scope.nameLabel = scope.pevMode ? 'volunteers' : 'commissioners';

                scope.instructions = {
                    filters: {
                        title: 'How to Filter and Select',
                        icon: 'check-square',
                        pdfFunc: scope.tcMode ? tcAssignmentSvc.openFilterInstructions : pevAssignmentSvc.openFilterInstructions
                    },
                    matrix: {
                        title: 'How to Use the Matrix',
                        icon: 'th-large',
                        pdfFunc: scope.tcMode ? tcAssignmentSvc.openMatrixInstructions : pevAssignmentSvc.openMatrixInstructions
                    }
                };

                scope.previewAssignments = function () {
                    tcAssignmentSvc.preview(scope.tcMode ? 'tc' : 'pev', scope.selectedReviews, scope.selectedVolunteers);
                };

                scope.showMatrix = false;
                scope.matrixParametersMet = false;

                scope.getLeftSelectionCounterAndMsg = function () {
                    if (scope.pevMode) {
                        return pevAssignmentSvc.programSelectionCounter + " programs selected";
                    } else if (scope.tcMode) {
                        return tcAssignmentSvc.reviewSelectionCounter + " reviews selected";
                    }
                };

                scope.getRightSelectionCounterAndMsg = function () {
                    if (scope.pevMode) {
                        return pevAssignmentSvc.volunteerSelectionCounter + " volunteers selected";
                    } else if (scope.tcMode) {
                        return tcAssignmentSvc.commissionerSelectionCounter + " commissioners selected";
                    }
                };

                scope.back = function () {
                    if (scope.tcMode) {
                        $state.go('tcAssignment');
                    } else if (scope.pevMode) {
                        if (scope.currentSocietyId) {
                            $state.go('pevAssignment.summary', { societyId: scope.currentSocietyId });
                        } else {
                            $state.go('pevAssignment.summary');
                        }
                    }
                    assignmentSvc.removeBodyClass();
                };

                scope.add = function () {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/assignment/templates/tc/teamChairsEdit.html',
                        size: 'lg',
                        controller: 'teamChairsEditCtrl',
                        resolve: {
                            commissioners: function () {
                                return scope.commissioners;
                            }
                        }
                    });
                };

                scope.searchFromOtherSocieties = function () {
                    var modalInstance = $uibModal.open({
                        animation: true,
                        templateUrl: '/Apps/assignment/templates/pev/searchFromOtherSocieties.html',
                        size: 'lg',
                        controller: 'searchFromOtherSocietiesCtrl',
                        resolve: {
                            reviews: function() {
                                return scope.reviews;
                            }
                        }
                    });
                }

                scope.hasAddBtn = scope.addBtn ? (scope.addBtn.toLowerCase() === "true" ? true : false) : false;

                scope.hasSearchFromOtherSocietiesBtn = scope.searchFromOtherSocietiesBtn ? (scope.searchFromOtherSocietiesBtn.toLowerCase() === "true" ? true : false) : false;

                scope.build = function () {
                    scope.showMatrix = false;
                    scope.selectedReviews = getSelected(scope.reviews);
                    scope.selectedVolunteers = getSelected(scope.volunteers);

                    if (selectionsAreGreaterThan(0)) {
                        if (totalCellsAreGreaterThan(40000)) {
                            alertSvc.openModalAlert("You have selected several hundred items. Please reduce your selections.");
                        } else if (selectionsAreGreaterThan(99) && selectionsAreLessThan(200)) {
                            alertSvc.confirm('You have selected 100 or more items. Your matrix may take a while to build. Do you want to proceed?', executeBuild);
                        } else if (selectionsAreGreaterThan(199)) {
                            alertSvc.confirm('You have selected 200 or more items. Your matrix may take several minutes to build. Do you want to proceed?', executeBuild);
                        } else {
                            executeBuild();
                        }
                    } else {
                        alertSvc.openModalAlert("Please make a selection from each set of results.");
                    }

                    function executeBuild() {
                        var reviewIds = getIds(scope.selectedReviews, scope.tcMode ? 'reviewTeamId' : 'programReviewDisciplineId');
                        var volunteerIds = getIds(scope.selectedVolunteers, 'volunteerId');

                        scope.showSpinner = true;

                        if (scope.tcMode) {
                            var editorType = $stateParams.type ? ($stateParams.type == 1 ? teamMemberTypeNames.EDITOR1 : teamMemberTypeNames.EDITOR2) : null;
                            var teamMemberTypeId = editorType ? editorType : teamMemberTypeNames.TEAMCHAIR;
                            var commissionId = scope.selectedReviews[0].commissionId;

                            tcAssignmentSvc.getMatrixRecords(reviewIds, volunteerIds, commissionId, teamMemberTypeId).then(function (data) {
                                tcAssignmentSvc.data.matrix = data.data.value;
                                setMatrixData(data);
                            });
                        } else if (scope.pevMode) {
                            pevAssignmentSvc.getMatrixRecords(reviewIds, volunteerIds, scope.currentSocietyId).then(function (data) {
                                pevAssignmentSvc.data.matrix = data.data.value;
                                setMatrixData(data);
                            });
                        }

                        function setMatrixData(data) {
                            scope.showSpinner = false;
                            scope.showMatrix = true;
                        }
                    }

                    function selectionsAreLessThan(num) { return (scope.selectedReviews.length + scope.selectedVolunteers.length) < num; }

                    function selectionsAreGreaterThan(num) { return (scope.selectedReviews.length > 0 && scope.selectedVolunteers.length > 0) && (scope.selectedReviews.length + scope.selectedVolunteers.length) > num; }

                    function totalCellsAreGreaterThan(num) { return (scope.selectedReviews.length * scope.selectedVolunteers.length) > num; }

                    function getIds(collection, idName) {
                        var ids = [];
                        for (var i = 0; i < collection.length; i++) {
                            if (collection[i][idName]) ids.push(collection[i][idName]);
                        }

                        ids = ids.join(',');
                        return ids;
                    }

                    function getSelected(collection) {
                        var selected = [];
                        for (var i = 0; i < collection.length; i++) {
                            if (collection[i].selected) selected.push(collection[i]);
                        }
                        return selected;
                    }
                };

                scope.buildCSV = function () {
                    var matrixRecords = scope.tcMode ? tcAssignmentSvc.data.matrix : pevAssignmentSvc.data.matrix;

                    if (matrixRecords) {
                        var tempRecords = [];
                        var headerTitle = scope.tcMode ? "Institution Name" : "Institution / Program";

                        scope.csvHeaders = [headerTitle];
                        scope.csvColumns = ["orgName"];

                        matrixRecords.sort(function (a, b) { return (a.organizationName.toLowerCase() > b.organizationName.toLowerCase()) ? 1 : ((b.organizationName.toLowerCase() > a.organizationName.toLowerCase()) ? -1 : 0); });

                        // Manipulate records to form a complete csv record and add the csvHeader and Columns
                        for (var i = 0; i < matrixRecords.length; i++) {
                            var record = matrixRecords[i];
                            var convertedRecord = {};

                            record.volunteers = angular.fromJson(record.volunteers);
                            record.volunteers.sort(function (a, b) { return (a.lastName > b.lastName) ? 1 : ((b.lastName > a.lastName) ? -1 : 0); })

                            convertedRecord.orgName = scope.tcMode ? record.organizationName : record.organizationName + " / " + record.programName;

                            angular.forEach(record.volunteers, function (volunteer) {
                                // Initialize headers and columns
                                if (i === 0) {
                                    scope.csvHeaders.push(volunteer.lastName + ", " + volunteer.firstName);
                                    scope.csvColumns.push(volunteer.volunteerId);
                                }

                                convertedRecord[volunteer.volunteerId] = "";

                                // Add TC assignment information to cell
                                if (scope.tcMode) {
                                    if (tcAssignmentSvc.isTeamChair(volunteer)) {
                                        convertedRecord[volunteer.volunteerId] += "Assigned [TC]";
                                    }
                                    if (tcAssignmentSvc.isCoTeamChair(volunteer)) {
                                        convertedRecord[volunteer.volunteerId] += "Assigned [CO-TC]";
                                    }
                                    if (tcAssignmentSvc.isEditor1(volunteer)) {
                                        convertedRecord[volunteer.volunteerId] += "Assigned [Editor 1]";
                                    }
                                    if (tcAssignmentSvc.isEditor2(volunteer)) {
                                        convertedRecord[volunteer.volunteerId] += "Assigned [Editor 2]";
                                    }
                                }

                                // Add PEV assignment information to cell
                                if (scope.pevMode) {
                                    if (pevAssignmentSvc.isPEV(volunteer)) {
                                        convertedRecord[volunteer.volunteerId] += "Assigned [PEV]";
                                    }
                                    if (pevAssignmentSvc.isObserver(volunteer)) {
                                        convertedRecord[volunteer.volunteerId] += "Assigned [Observer]";
                                    }
                                }

                                // Add conflict information to cell
                                if (!scope.editorMode && volunteer.hasDateConflict) {
                                    convertedRecord[volunteer.volunteerId] += "Date Conflict";
                                }
                                if (volunteer.conflicts && volunteer.conflicts.length > 0) {
                                    angular.forEach(volunteer.conflicts, function (conflict) {
                                        convertedRecord[volunteer.volunteerId] += conflict.type;
                                    });
                                }
                            });
                            tempRecords.push(convertedRecord);
                        };
                        return tempRecords;
                    } else {
                        alertSvc.openModalAlert("You must build a matrix before you can export a CSV.");
                    }
                };
            }
        };
    });

}(angular.module('assignment')));