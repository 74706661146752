(function (module) {

    var tcAssignmentToolCtrl = function ($scope, $filter, assignmentSvc, currentUserHelper, criteriaTransitionSvc, helperSvc, tcAssignmentSvc, barSvc, codeSvc, alertSvc, teamMemberTypes, onsiteAvailabilityTypes) {
        var model = this;

        model.title = 'Team Chair Assignment Tool';
        model.isDataReady = false;
        model.showMatrix = false;
        model.filteredReviewTeams = [];
        model.filteredCommissioners = [];
        model.onsiteAvailabilityTypes = onsiteAvailabilityTypes;
        model.hasResults = {
            left: false,
            right: false,
            all: false
        };

        model.reviewTeamFilters = tcAssignmentSvc.filters.tcReviewTeamFilters;

        criteriaTransitionSvc.getCriteriaTransitionConfig(currentUserHelper.getViceOpsCommissionId(), helperSvc.getAcademicYear()+1).then(function (data) {
            model.showTransitionCriteria = (data && data.criteriaTransitionConfigId);
        });

        model.commissionerFilters = tcAssignmentSvc.filters.tcCommissionerFilters;

        model.getFilteredReviewTeams = function () {
            var reviewTeamsFilter = $filter('reviewTeams');

            model.filteredReviewTeams = reviewTeamsFilter(model.data.reviewTeams, model.reviewTeamFilters, 'teamChair');
            model.hasResults.left = true;

            if (model.hasResults.left && model.hasResults.right) model.hasResults.all = true;
        };

        model.getFilteredCommissioners = function () {
            var commissionersFilter = $filter('commissioners');

            model.filteredCommissioners = commissionersFilter(model.data.commissioners, model.commissionerFilters, teamMemberTypes.TEAMCHAIRS);
            model.hasResults.right = true;

            if (model.hasResults.left && model.hasResults.right) model.hasResults.all = true;
        };

        model.assignTC = function (commissioner, teamIndex) {
            assignmentSvc.assign(commissioner, assignmentSvc.assignmentTypes.TEAMCHAIR, model.selectedReviewTeams[teamIndex], model.selectedCommissioners);
        };

        model.assignCoTC = function (commissioner, teamIndex) {
            assignmentSvc.assign(commissioner, assignmentSvc.assignmentTypes.COTEAMCHAIR, model.selectedReviewTeams[teamIndex], model.selectedCommissioners);
        };

        model.unassign = function (volunteer, teamMemberTypeId, teamIndex) {
            assignmentSvc.unassign(volunteer, teamMemberTypeId, model.selectedReviewTeams[teamIndex], model.selectedCommissioners);
        }

        activate();

        function activate() {
            assignmentSvc.addBodyClass();

            tcAssignmentSvc.resetFilters();

            tcAssignmentSvc.loadAllData().then(function () {
                model.data = tcAssignmentSvc.data;
                model.isDataReady = true;
            });
        }

        assignmentSvc.removeBodyClassOnPageChange($scope);
    };

    module.controller('tcAssignmentToolCtrl', tcAssignmentToolCtrl);

}(angular.module('assignment')));