(function (module) {

    var statementStorageSvc = function ($http, $q, $state, $stateParams, alertSvc, amsConst, codeSvc, commissionIds, criteriaIncluded, criteriaTypes,
        currentOrganization, currentUser, dueResponsePropNames, dueResponsePropNamesByTypeId, dueResponseTypeIds, evaluatorReportSvc,
        findingStatusTypeIds, helperSvc, localStorage, oauth, odataSvc, offlineSvc, programReviewActionCodes, programReviewSvc, programReviewTypeIds,
        readinessSvc, reviewTeamMemberSvc, reviewTeamSvc, reviewTypeIds, statementBoilerPlate, statementCategories, statementFindingTypeNames,
        statementFindingTypes, statementStatuses, statementSvc, statementTypeIds, teamMemberStatusTypes, 
        teamMemberTypeNames, teamMemberTypes, userReviewSvc, programAuditSvc, programAuditAccessSvc, logClientSvc, dueResponseSvc, environmentSvc) {
        var factory = {
            codes: { statementFindingTypes: null, criterion: null, criterionNoMasters: null, mastersCriterion: null, statementTypes: null },
            data: { statementProgramReviews: null, statement: null, currentStatementDetailDtos: null, reviewTeamId: null, viewingAll: true, currentReviewTeam: null, statementHistory: null, dueResponseStatus: null, response: null }

        }
        var isAdmin = oauth.isAdmin();
        var isAdjunct = oauth.isAdjunct();
        var api = '/Statement';
        var key = 'statementId';
        var OFFLINE_REVIEWS_KEY = 'offlineReviews';
        var STATEMENT_KEY_PREFIX = 'statementData:';
        var REVIEWTEAMSTATEMENTS_KEY_PREFIX = 'reviewTeamStatementsData:';  

        const dueResponseHeadings = {
            1: 'Seven-Day Response',
            2: 'Interim Response',
            3: '30-Day Due-Process Response',
            4: 'Post-30-Day Due-Process Response'
        }; // Consider moving to typeConsts, reusing wherever response section label/heading needed (e.g. statementPDFSvc)

        factory.create = function (statement, noStoredData, isDownloading) {
            var oSvc = odataSvc.get();
            var updatedStatement = serializeJson(statement);
            var resource = oSvc.instantiate(api, key, updatedStatement);

            return resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function (result) {
                return factory.getByReviewTeamId(statement.reviewTeamId, noStoredData, isDownloading);
            });
        }

        factory.getByReviewTeamId = function (reviewTeamId, isDownloading, isHistoryOnly) {
            var promise;
            //if ((!statements && !offlineSvc.isAppOffline()) || isDownloading) {
            if (!offlineSvc.isAppOffline() || isDownloading) {
                var oSvc = odataSvc.get();
                var apiPath = oSvc.getPathWithParameter('/GetStatementByReviewTeamId', 'reviewTeamId', reviewTeamId)

                oSvc.getSource(apiPath, key).odata()
                    .expand('statementDetailDtos')
                    .expand('statementDetailDtos($orderby=statementCategoryId,statementDetailId)')
                    .orderBy("statementId", "desc")//ensures latest one is first
                    .query(oSvc.onSuccess, oSvc.onFailure);

                promise = oSvc.getDeferred().promise;
            } else {
                // Using $q because I had trouble getting Promises API to interop with $http promises, which follow a slightly different standard
                var statements = getLocalReviewTeamStatements(reviewTeamId);
                var deferred = $q.defer();
                deferred.resolve(statements || []);
                promise = deferred.promise;
            }

            if (isDownloading)
                // only do this when downloading for offline because we don't want review data laying around in localStorage
                promise.then(function (data) {
                    setLocalReviewTeamStatements(reviewTeamId, data);
                    return data;
                });
            else
                storeStatementLocally(promise, null, isHistoryOnly);

            return promise;
        }

        factory.getByStatementId = function (statementId, isAutoSave, isDownloading, skipRebinding) {
            var promise;
            //if ((!statement && !offlineSvc.isAppOffline()) || isDownloading) {
            if (!offlineSvc.isAppOffline() || isDownloading) {
                var oSvc = odataSvc.get();
                var apiPath = oSvc.getPathWithParameter('/GetStatementByStatementId', 'statementId', statementId)

                oSvc.getSource(apiPath, key).odata()
                    .expand('statementDetailDtos($orderby=statementCategoryId,statementDetailId)')
                    .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

                promise = oSvc.getDeferred().promise;
            } else {
                var statement = getLocalStatement(statementId);
                var deferred = $q.defer();
                deferred.resolve(statement || null);
                promise = deferred.promise;
            }
             
            if (isDownloading)
                // only do this when downloading for offline because we don't want review data laying around in localStorage
                promise.then(function (data) {
                    setLocalStatement(statementId, data);
                    return data;
                });
            else 
                storeStatementLocally(promise, isAutoSave, false, skipRebinding);

            return promise;
        }

        factory.getInterimReviewPreviousStatement = function (reviewTeamId) {
            var oSvc = odataSvc.get();
            var path = '/GetInterimReviewPreviousStatement';
            var apiPath = oSvc.getPathWithParameter(path, 'reviewTeamId', reviewTeamId);

            oSvc.getSource(apiPath, key).odata()
                .expand('statementDetailDtos')
                .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise.then(data => {
                // Previous statement should currently exist in AMS for all interim reviews
                if (data.status === 500)
                    throw `Previous statement for interim program reviews missing for review team ${reviewTeamId}`;
                // Return null if not found (i.e. not an interim review so no previous statement with interim review actions exists)
                return !angular.equals(data, {}) && deserializeJson(data) || null;
            });
        }

        function getReviewStatementsKey(reviewTeamId) {
            return REVIEWTEAMSTATEMENTS_KEY_PREFIX + reviewTeamId;
        }

        function setLocalReviewTeamStatements(reviewTeamId, reviewTeamStatements, skipDataSync) {
            // No need to merge statement details because review team statement arrays are never saved--just individual statements
            var currentStatement = reviewTeamStatements.length > 0 ? reviewTeamStatements[0] : { reviewTeamId: reviewTeamId, statementId: 0 };
            localStorage.add(getReviewStatementsKey(reviewTeamId), reviewTeamStatements);
            if (skipDataSync) return;
            // Make sure current statement matches the version in history
            setLocalStatement(currentStatement.statementId, currentStatement, true);
        }

        function getLocalReviewTeamStatements(reviewTeamId) {
            return localStorage.get(getReviewStatementsKey(reviewTeamId));
        }

        factory.getStatementKey = function(statementId) {
            return STATEMENT_KEY_PREFIX + statementId;
        }

        function setLocalStatement(statementId, statement, skipDataSync) {
            // If a version already exists in storage, merge its statement details with this version's
            var previousVersion = getLocalStatement(statementId);
            if (previousVersion) {
                var mergedStatementDetails = previousVersion.statementDetailDtos || [];
                angular.forEach(statement.statementDetailDtos, function (revisedStatementDetail) {
                    var index = mergedStatementDetails.findIndex(function (previousStatementDetail) {
                        return previousStatementDetail.statementDetailId === revisedStatementDetail.statementDetailId;
                    });
                    if (index > -1)
                        mergedStatementDetails[index] = revisedStatementDetail;
                });
                statement.statementDetailDtos = mergedStatementDetails;
            }
            // Store statement in local storage
            localStorage.add(factory.getStatementKey(statementId), statement);
            if (skipDataSync) return;
            // Make sure copy of statement in statement history matches
            var reviewTeamStatements = getLocalReviewTeamStatements(statement.reviewTeamId);
            if (reviewTeamStatements) {
                if (reviewTeamStatements.length > 0) {
                    reviewTeamStatements[0] = statement;
                } else {
                    reviewTeamStatements = [statement];
                }
                setLocalReviewTeamStatements(statement.reviewTeamId, reviewTeamStatements, true);
            }
        }

        function getLocalStatement(statementId) {
            return localStorage.get(factory.getStatementKey(statementId));
        }

        factory.getPEVRA = function (programId) {
            if (programId !== null) {//intro not included for RA
                if (evaluatorReportSvc.data.evaluatorReport && evaluatorReportSvc.data.evaluatorReport.evaluatorReportDetailDtos && evaluatorReportSvc.data.evaluatorReport.evaluatorReportDetailDtos.length > 0) {
                    var reportDetail = evaluatorReportSvc.data.evaluatorReport.evaluatorReportDetailDtos.find(function (detailDto) {
                        return programId === detailDto.programId;
                    });

                    if (reportDetail)
                        return reportDetail.recommendedAction ? reportDetail.recommendedAction : null;
                }
            }
        }

        factory.loadActiveData = function (skipRebinding) {
            if (factory.data.statement === null || (Array.isArray(factory.data.statement) && factory.data.statement.length === 0)) {
                factory.data.statementHistory = null;//clean previously viewing statement history info.
                return;
            }

            //the isSave is to ensure the page isn't realigned by reloading the currently selecetd data
            var currentlySelected = factory.getSelectedSectionAndFinding(factory.data.statement)

            // Don't reassign if skipBinding is true (e.g. when selecting and scrolling to a section)
            if (!skipRebinding) {
                factory.data.currentStatementDetailDtos = factory.data.statement.statementDetailDtos;
            }

            if (currentlySelected.section) {
                var item = currentlySelected.finding ? currentlySelected.finding : (currentlySelected.findingType ? currentlySelected.findingType : currentlySelected.section);
                var parent = currentlySelected.findingType ? currentlySelected.section : null;
                
                factory.data.viewingAll = false;
                setItem(item, parent);
            }
            else {
                factory.data.viewingAll = true;
            }
        }

        var setItem = function (item, parent, activate) {
            var propName = activate ? 'isActive' : 'isHighlighted';
            //var selectedItemName = activate ? 'activeSection' : 'highlightedSection';

            if (item[propName]) return;

            //factory.data[selectedItemName] = item;

            // Reset any previous activation or highlighting
            factory.data.statement.statementDetailDtos.forEach(function (section) {
                section[propName] = false;

                if (section.statementJson) section.statementJson.forEach(function (findingType) {
                    findingType[propName] = false;

                    if (findingType.findings) findingType.findings.forEach(function (finding) {
                        finding[propName] = false;
                    });
                });
            });
            item[propName] = true;

            // After item is highlighted, set parent section as active
            if (parent) setItem(parent, null, true);

            // After section is highlighted, set section as active
            if (item.hasOwnProperty('programId')) setItem(item, null, true);
        };

        //maybe make this 'id' and 'isStatementId or isReviewTeamId flag'
        factory.getAllStatementToolData = function (statementId) {

            return factory.getByStatementId(statementId).then(function (data) {
                var dataArray = [];

                var programReviewsDataSource = {
                    dataHolder: factory,
                    dataLocationName: 'data.statementProgramReviews',
                    svcCallback: [programReviewSvc.getProgramsByReviewTeamIdOdata, programReviewSvc.getProgramsChildrenByReviewTeamIdOdata],
                    svcCallbackArguments: [factory.data.reviewTeamId],
                    orderByProperty: 'programReviewId',
                    odataResource: true
                }
                dataArray.push(programReviewsDataSource);

                var reviewTeamMemberDataSource = {
                    dataHolder: factory,
                    dataLocationName: 'data.reviewTeamMembers',
                    svcCallback: [reviewTeamMemberSvc.getReviewTeamMembers],
                    svcCallbackArguments: [factory.data.reviewTeamId],
                    orderByProperty: 'teamMemberTypeId',
                    odataResource: true
                }
                dataArray.push(reviewTeamMemberDataSource);

                var userReview;
                if (isAdmin) {
                    userReview = {
                        dataHolder: factory,
                        dataLocationName: 'data.currentReviewTeam',
                        svcCallback: userReviewSvc.getCurrentReviewTeamAdmin,
                        svcCallbackArguments: [factory.data.reviewTeamId],
                        odataResource: true,
                        optionalCallback: function () {
                            if(isAdjunct){
                                factory.data.currentReviewTeam.teamMemberTypeId = teamMemberTypeNames.ADJUNCT;
                            }
                        }
                    }
                    dataArray.push(userReview);

                } else {
                    userReview = {
                        dataHolder: factory,
                        dataLocationName: 'data.currentReviewTeam',
                        svcCallback: userReviewSvc.getCurrentReviewTeam,
                        svcCallbackArguments: [factory.data.reviewTeamId],
                        odataResource: true
                    }
                    dataArray.push(userReview);
                }

                var evaluatorReports = {
                    storeData: false,
                    svcCallback: evaluatorReportSvc.getEvaluatorReportByReviewTeamId,
                    svcCallbackArguments: [factory.data.reviewTeamId]
                }
                dataArray.push(evaluatorReports);

                var statementFindingTypes = {
                    dataHolder: factory,
                    dataLocationName: 'codes.statementFindingTypes',
                    svcCallback: statementSvc.getStatementFindingTypes,
                    odataResource: true
                }
                dataArray.push(statementFindingTypes);

                var criterion = {
                    dataHolder: factory,
                    dataLocationName: 'codes.criterion',
                    svcCallback: readinessSvc.getCriteria,
                    svcCallbackArguments: [[criteriaTypes.CRITERIA, criteriaTypes.MASTERSGENERALCRITERIA, criteriaTypes.APPM]],
                    odataResource: true,
                    optionalCallback: function () {
                        factory.codes.criterionNoMasters = helperSvc.getFilteredArray(factory.codes.criterion, 'criteriaTypeId', [criteriaTypes.CRITERIA, criteriaTypes.APPM], true);
                        factory.codes.mastersCriterion = helperSvc.getFilteredArray(factory.codes.criterion, 'criteriaTypeId', [criteriaTypes.MASTERSGENERALCRITERIA, criteriaTypes.APPM], true);
                    }
                }
                dataArray.push(criterion);

                var statementTypes = {
                    dataHolder: factory,
                    dataLocationName: 'codes.statementTypes',
                    svcCallback: codeSvc.getStatementTypes,
                    helperCallback: helperSvc.getResults
                }
                dataArray.push(statementTypes);

                // Load statement history data when user comes straight into statement tool, skipping review "manage statement" tab.
                if (!factory.data.statementHistory || factory.data.statementHistory.length === 0 || factory.data.statementHistory[0].reviewTeamId !== factory.data.reviewTeamId) {
                    var getStatementHistoryData = {
                        dataHolder: factory,
                        dataLocationName: 'statementHistory',
                        svcCallback: factory.getByReviewTeamId,
                        svcCallbackArguments: [factory.data.reviewTeamId, null, true],
                        odataResource: true,
                        optionalCallback: function (factory) {
                            //remove data location because it was only used as a placeholder
                            delete factory.statementHistory;
                        },
                        optionalCallbackArguments: [factory]
                    }
                    dataArray.push(getStatementHistoryData);
                }

                return helperSvc.getData(dataArray);
            })
        }
        
        function anyLocalStatementsExist () {
            var localStatement = getLocalStatement(statementId);
            return localStatement != null;
        }

        function localStatementExists (statementId) {
            var localStatement = getLocalStatement(statementId);
            return localStatement != null;
        }

        factory.save = function (statement, isAutoSave, skipRebinding) {
            var oSvc = odataSvc.get();
            var updatedStatementCopy = angular.copy(statement);

            factory.clearAutosaving(updatedStatementCopy);
            factory.removeOrphanedCommentsFromStatement(updatedStatementCopy);
            updatedStatementCopy = serializeJson(updatedStatementCopy);
            cleanStatement(updatedStatementCopy);

            evaluatorReportSvc.broadcastIsSaving(updatedStatementCopy.reviewTeamId, true);

            if (offlineSvc.isAppOffline()) {
                setLocalStatement(statement.statementId, updatedStatementCopy);
                var deferred = $q.defer();
                deferred.resolve(updatedStatementCopy);
                return deferred.promise.then(function (data) {
                    // Make sure side-effects of storing statement in this service's data happen...
                    return factory.getByStatementId(statement.statementId, isAutoSave, false, skipRebinding);
                });
            } else {
                var resource = oSvc.instantiate(api, key, updatedStatementCopy);
                return resource.$update(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
                    // Trigger storage event so other tabs will refresh
                    setLocalStatement(statement.statementId, updatedStatementCopy);
                    return factory.getByStatementId(statement.statementId, isAutoSave, false, skipRebinding);
                }).catch(function (error) {
                    console.log('Error while updating', error);
                    evaluatorReportSvc.broadcastIsSaving(updatedStatementCopy.reviewTeamId, false);
                    throw error;
                });
            };
        }


        factory.deletePost30Statements = function (reviewTeamId) {
            var apiPath = amsConst.webApiUrl + '/odata/DeletePost30Statements';
            var data = { "reviewTeamId": reviewTeamId }; 

            return $http.post(apiPath, data).then(function (response) {

            }).catch(function (error) {
                console.log('Error while deleting', error);
            });
        }

        function fixStatementBoilerplate(statement) {
            // This is temporary fix to make sure final statement boilerplate text is in present tense before returning/submitting.
            // This will already be fixed if statement was edited after this fix was deployed. See TFS backlog item 4471.
            if (!statement || !statement.statementDetailDtos || !statement.statementDetailDtos.length || statement.statementTypeId === statementTypeIds.DRAFT) return;
             
            for (var i = 0; i < statement.statementDetailDtos.length; i++) {
                var section = statement.statementDetailDtos[i];
                var match = helperSvc.getFirstMatch(section.statementJson, 'statementFindingTypeId', [statementFindingTypes.INSTITUTIONINTRODUCTION]);
                if (match && match.findings && match.findings.length && match.findings[0].criteria && match.findings[0].criteria.text2) {
                    match.findings[0].criteria.text2.replace(statementBoilerPlate.BODY_DRAFT, statementBoilerPlate.BODY);
                    break;
                }
            }
        }
        
        factory.submit = function (isReturningStatement) {
            var path = amsConst.webApiUrl + '/odata/SubmitStatement';
            var statement = angular.copy(factory.data.statement);

            if(statement.noChangeOption){
                //swap out for the previous statement details?             
                var previousStatement = factory.data.statementHistory[1]; //need to get the last statement before the submitted statement that was returned????ORRR we dont allow the no change option

                angular.forEach(statement.statementDetailDtos, function (detail) {
                    var previousStatementDetails = helperSvc.getFilteredArray(previousStatement.statementDetailDtos, 'programId', detail.programId, true);//get match by program id and if multiple nulls use?

                    if(previousStatementDetails.length > 1){
                        var previousDetail = helperSvc.getFirstMatch(previousStatementDetails, 'statementCategoryId', detail.statementCategoryId);//should only be intro/summary
                        detail.statementJson = previousDetail.statementJson;
                    }else{
                        detail.recommendedAction = previousStatementDetails[0].recommendedAction;
                        if (!detail.recommendedAction && detail.programId) {
                            // RA for program must have been cleared in error; find most recent RA and use that value instead
                            for (let index = 2; index < factory.data.statementHistory.length; index++) {
                                const statementDetail = factory.data.statementHistory[index].statementDetailDtos.find(statementDetail =>
                                    statementDetail.programId === detail.programId
                                );
                                if (statementDetail && statementDetail.recommendedAction) {
                                    detail.recommendedAction = statementDetail.recommendedAction
                                    break;
                                }
                            }
                        }
                        detail.statementJson = previousStatementDetails[0].statementJson;
                    }
                })
            }else {
                statement.noChangeOption = false;
            }

            statement.submittedByVolunteerId = oauth.isAdmin() && !oauth.isAdjunct() ? null : currentUser.profile.volunteerId;
            statement.submittedByTeamMemberTypeId = factory.data.statement.teamMemberTypeId;
            setSubmittedToTeamMemberTypeAndStatus(statement, isReturningStatement);
            statement.submittedTimestamp = new Date();
            fixStatementBoilerplate(statement);
            var updatedStatement = serializeJson(statement);

            var data = { "statement": updatedStatement };

            evaluatorReportSvc.broadcastIsSaving(updatedStatement.reviewTeamId, true);

            return $http.post(path, data).then(function (response) {
                // Trigger refresh on any open tabs
                setLocalStatement(statement.statementId, updatedStatement);
                // Delete local storage copies of submitted statements
                localStorage.remove(factory.getStatementKey(statement.statementId));
                localStorage.remove(getReviewStatementsKey(statement.reviewTeamId));
                localStorage.remove(evaluatorReportSvc.getEvaluatorReportKey(statement.reviewTeamId));
                return factory.getByReviewTeamId(factory.data.reviewTeamId);
            }).catch(function (error) {
                console.log('Error while ' + isReturningStatement ? 'returning' : 'submitting', error);
                evaluatorReportSvc.broadcastIsSaving(updatedStatement.reviewTeamId, false);
                throw error;
            });
        }

        factory.submitFinalForPost30Day = function(){
            var path = amsConst.webApiUrl + '/odata/SubmitFinalStatementForPostThirtyDay';
            var statement = angular.copy(factory.data.statement);
            
            statement.submittedByVolunteerId = oauth.isAdmin() && !oauth.isAdjunct() ? null : currentUser.profile.volunteerId;
            statement.submittedByTeamMemberTypeId = factory.data.statement.teamMemberTypeId;
            setSubmittedToTeamMemberTypeAndStatus(statement);
            statement.submittedTimestamp = new Date();
            fixStatementBoilerplate(statement);
            var updatedStatement = serializeJson(statement);

            var data = { "statement": updatedStatement };

            evaluatorReportSvc.broadcastIsSaving(updatedStatement.reviewTeamId, true);

            return $http.post(path, data).then(function (response) {
                // Trigger refresh on any open tabs
                setLocalStatement(statement.statementId, updatedStatement);
                // Delete local storage copies of submitted statements
                localStorage.remove(factory.getStatementKey(statement.statementId));
                localStorage.remove(getReviewStatementsKey(statement.reviewTeamId));
                localStorage.remove(evaluatorReportSvc.getEvaluatorReportKey(statement.reviewTeamId));
                return factory.getByReviewTeamId(factory.data.reviewTeamId);
            }).catch(function (error) {
                console.log('Error while submitting final statement', error);
                evaluatorReportSvc.broadcastIsSaving(updatedStatement.reviewTeamId, false);
                throw error;
            });

        }

        factory.createNewStatementForResending = function(statement){
            var path = amsConst.webApiUrl + '/odata/CreateNewStatementForResending';
            var statement = angular.copy(statement);
            fixStatementBoilerplate(statement);
            var updatedStatement = serializeJson(statement);

            var data = { "statement": updatedStatement };

            return $http.post(path, data).then(function (response) {
                return factory.getByReviewTeamId(statement.reviewTeamId);
            }).catch(function (error) {
                console.log('Error while creating new statement', error);
                evaluatorReportSvc.broadcastIsSaving(updatedStatement.reviewTeamId, false);
                throw error;
            });

        }

        factory.createFinalStatement = function(statement){
            var path = amsConst.webApiUrl + '/odata/CreateFinalStatement';
            var statement = angular.copy(statement);
            fixStatementBoilerplate(statement);
            var updatedStatement = serializeJson(statement);

            var data = { "statement": updatedStatement };

            return $http.post(path, data).then(function (response) {
                return factory.getByReviewTeamId(statement.reviewTeamId);
            }).catch(function (error) {
                console.log('Error while submitting', error);
                evaluatorReportSvc.broadcastIsSaving(updatedStatement.reviewTeamId, false);
            });
        }

        factory.createPost30DayStatement = function(statement){
            var path = amsConst.webApiUrl + '/odata/CreatePostThirtyDayStatement';
            var statement = angular.copy(statement);
            fixStatementBoilerplate(statement);
            var updatedStatement = serializeJson(statement);

            var data = { "statement": updatedStatement };

            return $http.post(path, data).then(function (response) {
                return factory.getByReviewTeamId(statement.reviewTeamId);
            }).catch(function (error) {
                console.log('Error while creating post 30 day statement', error);
                evaluatorReportSvc.broadcastIsSaving(updatedStatement.reviewTeamId, false);
                throw error;
            });
        }

        factory.createCommissionEditingStatement = function (statement) {
            var path = amsConst.webApiUrl + '/odata/CreateCommissionEditingStatement';
            var statement = angular.copy(statement);
            fixStatementBoilerplate(statement);
            var updatedStatement = serializeJson(statement);

            var data = { "statement": updatedStatement };

            return $http.post(path, data).then(function (response) {
                return factory.getByReviewTeamId(statement.reviewTeamId);
            }).catch(function (error) {
                console.log('Error while creating commission statement', error);
                evaluatorReportSvc.broadcastIsSaving(updatedStatement.reviewTeamId, false);
                throw error;
            });
        }

        function setSubmittedToTeamMemberTypeAndStatus (statement, isReturningStatement) {
            const submitTo = factory.getSubmittedToTeamMemberTypeAndStatus(statement, isReturningStatement);
            statement.submittedToTeamMemberTypeId = submitTo.submittedToTeamMemberTypeId;
            statement.submittedToTeamMemberTypeName = submitTo.submittedToTeamMemberTypeName;
            statement.statementStatusId = submitTo.statementStatusId;
        }

        factory.getSubmittedToTeamMemberTypeAndStatus = function (statement, isReturningStatement) {
            let submittedToTeamMemberTypeId, submittedToTeamMemberTypeName, statementStatusId;

            //need more for adjunct
            if (isReturningStatement) {
                if (factory.data.currentReviewTeam && factory.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.EDITOR1) {
                    if(isReportTC()){
                        submittedToTeamMemberTypeId = teamMemberTypeNames.REPORTTEAMCHAIR;//goes to tc
                        submittedToTeamMemberTypeName = "TC";
                    }else{
                        submittedToTeamMemberTypeId = teamMemberTypeNames.TEAMCHAIR;//goes to tc
                        submittedToTeamMemberTypeName = "TC";
                    }                      
                    statementStatusId = statementStatuses.WAITINGFORTCSUBMIT;
                }
                if (factory.data.currentReviewTeam && factory.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.EDITOR2) {
                    submittedToTeamMemberTypeId = teamMemberTypeNames.EDITOR1;//goes to ed1
                    submittedToTeamMemberTypeName = "ED 1";
                    statementStatusId = statementStatuses.WAITINGFORED1SUBMIT;
                }
                if (factory.data.currentReviewTeam && factory.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.ADJUNCT) {
                    submittedToTeamMemberTypeId = teamMemberTypeNames.EDITOR2;//goes to ed2
                    submittedToTeamMemberTypeName = "ED 2";
                    statementStatusId = statementStatuses.WAITINGFORED2SUBMIT;
                }
                if (factory.data.statement.teamMemberTypeId === teamMemberTypeNames.ADMIN) {
                    submittedToTeamMemberTypeId = teamMemberTypeNames.ADJUNCT;//goes to ed2
                    submittedToTeamMemberTypeName = "ADJ";
                    statementStatusId = statementStatuses.WAITINGFORADJSUBMIT;
                }
                //special skip case for EAC when commission editing
                if (factory.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.ADJUNCT && statement.statementTypeId === statementTypeIds.COMMISSIONEDITING && factory.data.currentReviewTeam.commissionId === commissionIds.EAC) {
                    submittedToTeamMemberTypeId = teamMemberTypeNames.EDITOR1;//goes to adj
                    submittedToTeamMemberTypeName = "ED 1";
                    statementStatusId = statementStatuses.WAITINGFORED1SUBMIT;
                }
            }
            else {
                if (factory.data.currentReviewTeam && (factory.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.TEAMCHAIR || factory.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.REPORTTEAMCHAIR)) {
                    submittedToTeamMemberTypeId = teamMemberTypeNames.EDITOR1;//goes to ED1
                    submittedToTeamMemberTypeName = "ED 1";
                    statementStatusId = statementStatuses.WAITINGFORED1SUBMIT;
                }
                if (factory.data.currentReviewTeam && factory.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.EDITOR1) {
                    submittedToTeamMemberTypeId = teamMemberTypeNames.EDITOR2;//goes to ed2
                    submittedToTeamMemberTypeName = "ED 2";
                    statementStatusId = statementStatuses.WAITINGFORED2SUBMIT;
                }
                if (factory.data.currentReviewTeam && factory.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.EDITOR2) {
                    submittedToTeamMemberTypeId = teamMemberTypeNames.ADJUNCT;//goes to adj
                    submittedToTeamMemberTypeName = "ADJ";
                    statementStatusId = statementStatuses.WAITINGFORADJSUBMIT;
                }
                if (factory.data.currentReviewTeam && factory.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.ADJUNCT) {
                    submittedToTeamMemberTypeId = teamMemberTypeNames.ADMIN;//goes to adj
                    submittedToTeamMemberTypeName = "ADM";
                    statementStatusId = statementStatuses.ADMINPREPFORSEND;
                }
                //special skip case for EAC when commission editing
                if (factory.data.currentReviewTeam.teamMemberTypeId === teamMemberTypeNames.EDITOR1 && statement.statementTypeId === statementTypeIds.COMMISSIONEDITING && factory.data.currentReviewTeam.commissionId === commissionIds.EAC) {
                    submittedToTeamMemberTypeId = teamMemberTypeNames.ADJUNCT;//goes to adj
                    submittedToTeamMemberTypeName = "ADJ";
                    statementStatusId = statementStatuses.WAITINGFORADJSUBMIT;
                }
                
            }

            return { submittedToTeamMemberTypeId, submittedToTeamMemberTypeName, statementStatusId };
        }

        factory.getProgramName = function (programId, includeDegree, statementCategoryId, disambiguate) {
            if (programId === null || programId === undefined) {
                return statementCategoryId === statementCategories.INSTITUTION ? 'Institution' : 'Summary'
            } else {
                var foundProgram = programReviewSvc.data.programs.find(function (program) {
                    return program.programId === programId;
                });
                if (foundProgram && foundProgram.programDto && foundProgram.programDto.programDetailDto) { //issues with having the programReviewSvc return so much data that its in 2 seperate calls and then combined
                    if (disambiguate) {
                        includeDegree = includeDegree || programReviewSvc.data.programs.some(function (program) {
                            return program.programId !== programId && program.programDto && program.programDto.programDetailDto && program.programDto.programDetailDto.programName === foundProgram.programDto.programDetailDto.programName;
                        });
                    }
                    if (includeDegree) {
                        return foundProgram.programDto.programDetailDto.programName + " (" + foundProgram.programDto.programDetailDto.degreeCode + ")";
                    } else {
                        return foundProgram.programDto.programDetailDto.programName;
                    }
                } else {
                    return '';

                }
            }
        }

        factory.statementDetailComparator = function (statementDetail) {
            // Supports up to 99,999 programs on a single review!
            var prefix = (!statementDetail || !statementDetail.statementCategoryId) ? '00000;' : String('00000' + statementDetail.statementCategoryId).slice(-5) + ';'
            var compareString = prefix + ';' + factory.getProgramName(statementDetail.programId, true, statementDetail.statementCategoryId, true);
            return compareString;
        }

        factory.statementDetailComparer = function (a, b) {
            var str1 = factory.statementDetailComparator(a);
            var str2 = factory.statementDetailComparator(b);
            return str1.localeCompare(str2);
        }

        factory.getCriteriaAreaNames = function (programId) {
            var program = factory.data.statementProgramReviews.find(function (program) {
                return program.programId == programId
            });
            return programReviewSvc.concatCriteriaAreas(program);
        }

        factory.getProgramDegreeName = function (programId) {
            if (programId === null || programId === undefined)
                return '';

            var foundProgram = programReviewSvc.data.programs.find(function (program) {
                return program.programId === programId;
            });
            if (foundProgram && foundProgram.programDto && foundProgram.programDto.programDetailDto)//issues with having the programReviewSvc return so much data that its in 2 seperate calls and then combined
                return foundProgram.programDto.programDetailDto.degreeCode;
            else
                return '';
        }

        factory.isInstitutionSection = function (section) {
            return $stateParams.program ? $stateParams.program.toLowerCase() == 'institution' && section.statementCategoryId == statementCategories.INSTITUTION : false;
        };

        factory.isSummarySection = function (section) {
            return $stateParams.program ? $stateParams.program.toLowerCase() == 'summary' && section.statementCategoryId == statementCategories.SUMMARY : false;
        };

        factory.getSelectedSectionAndFinding = function (statement, fromSelection) {
            var section, findingType, finding;
            if ($stateParams.program == 'Institution' || $stateParams.program == 'Summary' || $stateParams.program >= 0) {

                // find and set section
                section = statement.statementDetailDtos.find(function (currentSection) {

                    // find and set findingType
                    if (currentSection.statementJson) {
                        findingType = currentSection.statementJson.find(function (findingType) {

                            // find and set finding
                            if (findingType.findings) {
                                finding = findingType.findings.find(function (finding) {
                                    return finding.criteria ? (finding.criteria.criteriaId == $stateParams.criteria) : false;
                                });
                            }

                            return findingType.statementFindingTypeId == $stateParams.finding;
                        });
                    }

                    return factory.isInstitutionSection(currentSection) || factory.isSummarySection(currentSection) || currentSection.programId == parseInt($stateParams.program);
                });
            }

            return { section: section, findingType: findingType, finding: finding };
        }

        factory.getLatestStatementByCurrentTeamMemberType = function () {//statement history is returned in order from the latest version to the oldest
            if (factory.data.statementHistory)
                return helperSvc.getFirstMatch(factory.data.statementHistory, 'teamMemberTypeId', factory.data.currentReviewTeam.teamMemberTypeId);
            return null;
        }

        factory.getLatestStatementByIsSubmitted = function () {
            for(var i = 0; i < factory.data.statementHistory.length; i++){
                if(factory.data.statementHistory[i].submittedTimestamp !== null)
                    return factory.data.statementHistory[i];
            }
            return null;
        }

        factory.getStatementSubmittedToInstitution = function () {
            // Statement is currently being set to [] when it hasn't been created, should probably be null
            if (factory.data.statementHistory && factory.data.statement && !Array.isArray(factory.data.statement)) {
                var submitted = factory.data.statementHistory.find(function (statement) {
                    return statement.statementTypeId == statementTypeIds.DRAFT && ((statement.statementStatusId == statementStatuses.SENTTOINSTITUTION) || (statement.statementStatusId == statementStatuses.RESENTTOINSTITUTION));
                });
                return submitted;
            }
            return null;
        }

        factory.getFinalStatementSubmittedToInstitution = function () {
            if (factory.data.statementHistory) {
                var submitted = factory.data.statementHistory.find(function (statement) {
                    return statement.statementTypeId == statementTypeIds.COMMISSIONEDITING && ((statement.statementStatusId == statementStatuses.SENTTOINSTITUTION) || (statement.statementStatusId == statementStatuses.RESENTTOINSTITUTION));
                });
                return submitted;
            }
            return null;
        }

        factory.isStatementSubmittedToInstitution = function (statement) {
            // This is specifically draft statements
            if (statement) {
                return statement.statementTypeId == statementTypeIds.DRAFT && ((statement.statementStatusId == statementStatuses.SENTTOINSTITUTION) || (statement.statementStatusId == statementStatuses.RESENTTOINSTITUTION));
            } else {
                var draftStatement = factory.getStatementSubmittedToInstitution();
                return draftStatement ? true : false;
            }
        }

        factory.isFinalStatementSubmittedToInstitution = function (statement) {
            if (statement) {
                return statement.statementTypeId == statementTypeIds.COMMISSIONEDITING && ((statement.statementStatusId == statementStatuses.SENTTOINSTITUTION) || (statement.statementStatusId == statementStatuses.RESENTTOINSTITUTION));
            }
        }

        factory.isFinalStatementPendingSubmissionToInstitution = function (statement) {
            return statement.statementTypeId == statementTypeIds.COMMISSIONEDITING && statement.statementStatusId == statementStatuses.ADMINPREPFORSEND;
        }

        factory.isStatementPendingSubmissionToInstitution = function (statement) {
            return statement.statementTypeId == statementTypeIds.DRAFT && statement.statementStatusId == statementStatuses.ADMINPREPFORSEND;
        }

        factory.useCriterionType = function (programId) {
            var program = helperSvc.getFirstMatch(programReviewSvc.data.programs, 'programId', programId);
            if(program.programDto.programDetailDto.isMasterDegreeIntegrated){
                return criteriaIncluded.ALL;
            }
            else if(program.programDto.programDetailDto.degreeLevelCode == 'M'){//degree level code for masters
                return criteriaIncluded.MASTERSONLY;
            }
            else{
                return criteriaIncluded.UNDERGRAD
            }
        }

        factory.setDueResponseData = function () {
            const data = getDueResponseData();
            factory.data.dueResponseStatus = data.dueResponseStatus;
            factory.data.response = data.response
            factory.setAfterReviewText();
        }

        factory.deleteAllDueResponseData = function (responseTypeId) {
            var responseTypeName = dueResponsePropNamesByTypeId[responseTypeId]; 

            if (factory.data.statement && factory.data.statement.statementDetailDtos) {
                //delete from each finding here
                for (var i = 0; i < factory.data.statement.statementDetailDtos.length; i++){
                    var statementDetail = factory.data.statement.statementDetailDtos[i];
                    angular.forEach(statementDetail.statementJson, function (findingType) {
                        angular.forEach(findingType.findings, function (finding) {
                            if (finding.criteria && finding.criteria.response)
                                finding.criteria.response[responseTypeName] = null;
                        });
                    });
                }

                factory.setDueResponseData();

                return factory.save(factory.data.statement);
            }
        }

        factory.weaknessOrDeficiencyExists = function(){
            if (factory.data.statement && factory.data.statement.statementDetailDtos) {
               
                for (var i = 0; i < factory.data.statement.statementDetailDtos.length; i++) {
                    var section = factory.data.statement.statementDetailDtos[i];

                    var match = helperSvc.getFirstMatch(section.statementJson, 'statementFindingTypeId', [statementFindingTypes.PROGRAMDEFICIENCY, statementFindingTypes.PROGRAMWEAKNESS]);
                    if (match) {
                        return true;
                    }
                }
            }
            return false;
        }

        factory.updateSubmittedToTeamMemberType = function(statementId, submittedToTeamMemberTypeId){
            var oSvc = odataSvc.get();
            var apiPath = amsConst.webApiUrl + '/odata/UpdateSubmittedToTeamMemberType';

            var parameters = {
                    statementId: statementId,
                    submittedToTeamMemberTypeId: submittedToTeamMemberTypeId
            }

            return $http.post(apiPath, parameters).then(function(){
                return factory.getByReviewTeamId(factory.data.reviewTeamId);
            });
        }


        function serializeJson(statement) {
            var statementCopy = angular.copy(statement);
            if (statement) {
                //need to do for each program...
                if (statementCopy.statementDetailDtos && statementCopy.statementDetailDtos.length > 0) {
                    angular.forEach(statementCopy.statementDetailDtos, function (detail) {
                        detail.statementJson = angular.toJson(detail.statementJson);
                    })
                }
            }
            return statementCopy;
        }

        function deserializeJson(statement) {
            var statementCopy = angular.copy(statement);
            if (statement) {
                //need to do for each program...
                if (statementCopy.statementDetailDtos && statementCopy.statementDetailDtos.length > 0) {
                    angular.forEach(statementCopy.statementDetailDtos, function (detail) {
                        detail.newProgramStartDate = detail.newProgramStartDate ? new Date(detail.newProgramStartDate) : null;
                        detail.terminationDate = detail.terminationDate ? new Date(detail.terminationDate) : null;
                        detail.statementJson = angular.fromJson(detail.statementJson);
                    })
                }
            }
            return statementCopy;
        }

        function storeStatementLocally(promise, isAutoSave, isHistoryOnly, skipRebinding) {
            promise.then(function (data) {

                var newestStatement;
                if (Array.isArray(data) && data.length > 0) {
                    newestStatement = data[0];
                    factory.data.statementHistory = data.map(function (statement) {
                        return deserializeJson(statement);
                    });
                } else {
                    // This branch is executed when loading all statements by review team id but nothing is returned (not created  yet).
                    // Statement is currently being set to [] when it hasn't been created; it should probably be null and history should be empty.
                    // NOTE: the line below is a bug because it ends up saving an empty array (Array(0)) when statement hasn't been created yet.
                    // Should replace with something like: newestStatement = Array.isArray(data) ? null : data;
                    // Leaving until we can thoroughly test; any code assuming an object at statementStorageSvc.data.statement will need to be updated as well
                    newestStatement = data;

                    if (factory.data.statementHistory && factory.data.statementHistory.length > 0) {
                        var index = factory.data.statementHistory.findIndex(function (statement) {
                            return newestStatement != null && statement.statementId === newestStatement.statementId;
                        });
                        if (index > -1) factory.data.statementHistory.splice(index, 1, deserializeJson(newestStatement));
                    }
                }
                if (!isHistoryOnly) {//checks that when statements come back from reviewTeamId if it's only the history, don't assign current statement
                    var statement = newestStatement != null ? deserializeJson(newestStatement) : null;
                    // Only draft statements are allowed to be edited offline so it should be okay to only fix boiler plate here.
                    fixStatementBoilerplate(statement);
                    factory.data.statement = statement;
                }

                factory.data.reviewTeamId = statement != null ? statement.reviewTeamId : -1;
                //only used for getByStatement becuase thats what the actual tool uses and it needs to load the currently active data
                if (!isAutoSave)
                    factory.loadActiveData(skipRebinding);
            });
        }

        function cleanStatement(statement) {
            // not sure if we need this any longer
            if (!statement.statementDetailDtos) return;

            factory.clearAutosaving(statement);

            angular.forEach(statement.statementDetailDtos, function (detailDto) {
                if (detailDto.hasOwnProperty('pevRecommendedAction')) {
                    delete detailDto.pevRecommendedAction;
                }
                if (detailDto.hasOwnProperty('isActive')) {
                    delete detailDto.isActive;
                }
                if (detailDto.hasOwnProperty('isHighlighted')) {
                    delete detailDto.isHighlighted;
                }
                if (detailDto.hasOwnProperty('hasComments')) {
                    delete detailDto.hasComments;
                }
            })
        }

        factory.removeOrphanedCommentsFromStatement = function (statement) {
            if (!statement || !Array.isArray(statement.statementDetailDtos)) return;
            angular.forEach(statement.statementDetailDtos, function (statementDetailDto) {
                if (!Array.isArray(statementDetailDto.statementJson)) return;
                angular.forEach(statementDetailDto.statementJson, function (statementJson) {
                    if (!Array.isArray(statementJson.findings)) return;
                    angular.forEach(statementJson.findings, function (finding) {
                        factory.removeOrphanedCommentsFromFinding(finding);
                    });
                });
            });
        }

        factory.removeOrphanedCommentsFromFinding = function (finding) {
            var text = finding.criteria.text;
            // Find all <mark></mark> elements
            var findMarkElements = /(<\s*mark[^>]*data-id="([a-zA-Z0-9]+)"[^>]*>)([^]*?)(<\s*\/\s*mark>)/gi;
            var markElementMatches = [];
            var markElementMatch = findMarkElements.exec(text);
            while (markElementMatch) {
                markElementMatches.push(markElementMatch);
                markElementMatch = findMarkElements.exec(text);
            }
            // Remove markup not found in comments
            if (markElementMatches.length > 0) {
                var markIds = !finding.criteria.comments ? [] : finding.criteria.comments.map(function (comment) { return comment.markId; });
                for (index = markElementMatches.length - 1; index >= 0; index--) {
                    var match = markElementMatches[index];
                    var markId = match[2]; // mark id captured in second group
                    if (markIds.indexOf(markId) < 0) {
                        text = text.slice(0, match.index) + match[3] + text.slice(match[0].length + match.index);
                    }
                }
                if (finding.criteria.text !== text) {
                    finding.criteria.text = text;
                }
            }
            // Keeping comments without mark up in criteria text for now, leaving this code
            // in case we change our minds. It would be unadvisable to delete visible comments
            // even if we are unable to find and highlight their location.
            //
            //////// Remove comments not found in markup
            //////if (finding.criteria.comments && finding.criteria.comments.length > 0) {
            //////    //var actualMarkIds = markElementMatches.map(function (match) { return match[2]; });
            //////    var actualMarkIds = [];
            //////    markElementMatches.forEach(function (match) {
            //////        var matchId = match[2];
            //////        if (actualMarkIds.indexOf(matchId) < 0)
            //////            actualMarkIds.push(matchId);
            //////    });
            //////    if (actualMarkIds.length !== markElementMatches.length) {
            //////        COUNT++;
            //////        console.log('oops! resused mark ID found!', finding.key);
            //////        console.log('');
            //////    }
            //////    var actualComments = finding.criteria.comments.filter(function (comment) { return actualMarkIds.indexOf(comment.markId) >= 0; });
            //////    if (actualComments.length !== finding.criteria.comments.length) {
            //////        finding.criteria.comments = actualComments;
            //////    }
            //////}

            return finding;
        }

        factory.getOfflineReviews = function () {
            return localStorage.get(OFFLINE_REVIEWS_KEY);
        }

        var DYNAMIC_CACHE = 'amsCache-dynamic'; // local cache for dynamic data that is to be saved as needed and deleted on sync

        factory.downloadOfflineData = function (selectedReviews) {
            // Create review statements as necessary
            var statementlessReviews = selectedReviews.filter(function (review) {
                return !review.statementStatusId;
            });
            var createStatementPromises = statementlessReviews.map(function (review) {
                return createStatementFromReview(review);
            });
            return $q.all(createStatementPromises).then(function (data) {
                // Download reviews, statements, and any other support data to local storage
                return downloadSelectedStatements(selectedReviews);
            });
        };

        function createStatementFromReview(review) {
            var reviewTeamId = review.reviewTeamId;
            var noStoredData = true;
            var isDownloading = true;

            const dataLoadPromises = [
                evaluatorReportSvc.getEvaluatorReportByReviewTeamId(reviewTeamId, noStoredData, isDownloading),
                loadProgramData(reviewTeamId),
                reviewTeamMemberSvc.getReviewTeamMembers(reviewTeamId),
                programAuditSvc.getProgramAuditByReviewTeamId(reviewTeamId).then(data => data ? data.find(programAudit => programAudit.isCurrent && programAudit.isLocked) : null),
                programAuditAccessSvc.getProgramAuditAccessByReviewTeamId(reviewTeamId),
                factory.getInterimReviewPreviousStatement(reviewTeamId)
            ];

            return $q.all(dataLoadPromises).then(function (data) {
                var commissionId = review.commissionId;
                var reviewTypeCode = review.reviewTypeCode;
                const [evaluatorReportData, programs, reviewTeamMembers, programAudit, programAuditAccess, previousStatement] = data;

                return factory.createInitialStatement(reviewTeamId, commissionId, reviewTypeCode, evaluatorReportData, programs, reviewTeamMembers, programAudit, programAuditAccess, previousStatement, noStoredData, isDownloading);
            });

            function loadProgramData(reviewTeamId) {
                const results = {};
                const programReviewsDataSource = {
                    dataHolder: results,
                    dataLocationName: 'programData',
                    svcCallback: [programReviewSvc.getProgramsByReviewTeamIdOdata, programReviewSvc.getProgramsChildrenByReviewTeamIdOdata],
                    svcCallbackArguments: [reviewTeamId],
                    orderByProperty: 'programReviewId',
                    odataResource: true
                }

                return helperSvc.getData([programReviewsDataSource]).then(function () {
                    var filteredPrograms = results.programData.filter(function (programReview) {
                        if (programReview.programReviewTypeCode.toLowerCase() === 'tr') {
                            if (programReview.accreditationEndDate === null ||
                                programReview.accreditationEndDate < review.reviewYear + '-10-01T00:00:00Z')
                                return false;
                        }
                        return true;
                    });
                     
                    return filteredPrograms;
                });
            }
        }

        function downloadSelectedStatements(selectedReviews) {
            return caches.open(DYNAMIC_CACHE).then(function (cache) {
                var promises = [];
                cacheRequest(cache, '/webapi/odata/Criteria?$filter=criteriaTypeId%20eq%202&$orderby=criteriaTypeId%20asc,criteriaName%20asc', promises);
                cacheRequest(cache, '/webapi/odata/Criteria?$filter=(criteriaTypeId%20eq%202)%20or%20(criteriaTypeId%20eq%209)&$orderby=criteriaTypeId%20asc,criteriaName%20asc', promises);
                cacheRequest(cache, '/webapi/odata/Criteria?$filter=((criteriaTypeId%20eq%202)%20or%20(criteriaTypeId%20eq%209))%20or%20(criteriaTypeId%20eq%2010)&$orderby=criteriaTypeId%20asc,criteriaName%20asc', promises);
                cacheRequest(cache, '/webapi/odata/GetOrganizationTypes()?$orderby=codeKey', promises);
                cacheRequest(cache, '/webapi/odata/GetStatementFindingTypes', promises);
                cacheRequest(cache, '/webapi/odata/GetStatementTypes()', promises);
                cacheRequest(cache, '/webapi/odata/GetCountries()?$orderby=codeName', promises);
                cacheRequest(cache, '/webapi/odata/GetNamePrefixes()?$orderby=codeKey', promises);
                cacheRequest(cache, '/webapi/odata/GetNameSuffixes()?$orderby=codeKey', promises);
                cacheRequest(cache, '/webapi/odata/GetProfessionalSuffixes()?$orderby=codeKey', promises);
                cacheRequest(cache, '/webapi/odata/GetStates()?$orderby=codeKey', promises);
                cacheRequest(cache, '/webapi/odata/Action', promises);               
                var noStoredData = true;
                var isDownloading = true;              
                angular.forEach(selectedReviews, function (review) {
                    // Evaluator report service needs to work offline as well to accomodate saving of recommended actions
                    //cacheRequest(cache, '/webapi/odata/EvaluatorReport?$filter=reviewTeamId%20eq%20{{reviewTeamId}}&$expand=evaluatorReportDetailDtos', promises, review);
                    cacheRequest(cache, '/webapi/odata/GetCurrentReviewTeamByReviewTeamId(reviewTeamId={{reviewTeamId}})', promises, review);
                    cacheRequest(cache, '/webapi/odata/GetReviewTeamMembers(reviewTeamId={{reviewTeamId}})', promises, review);
                    cacheRequest(cache, '/webapi/odata/GetProgramReviewMoreChild?$filter=reviewTeamId%20eq%20{{reviewTeamId}}&$expand=programDto($expand=programDetailDto),reviewTeamDto($expand=reviewDto),programReviewChangeDtos,selfStudyCriteriaDtos', promises, review);
                    cacheRequest(cache, '/webapi/odata/ProgramReview?$filter=reviewTeamId%20eq%20{{reviewTeamId}}&$expand=programReviewCampusDtos($expand=addressDto),programReviewDisciplineDtos($expand=reviewTeamMemberDtos)', promises, review);
                    cacheRequest(cache, '/webapi/odata/GetDueResponseByReviewTeamId(reviewTeamId={{reviewTeamId}})', promises, review);
                    cacheRequest(cache, '/webapi/odata/GetMyReviewTeamMembers(reviewTeamId={{reviewTeamId}})', promises, review);
                    cacheRequest(cache, '/webapi/odata/ReviewTeam({{reviewTeamId}})?$expand=reviewDto', promises, review);
                    // Using local storage instead... 
                    // cacheRequest(cache, '/webapi/odata/GetStatementByStatementId(statementId={{statementId}})?$expand=statementDetailDtos', promises, review);
                    // cacheRequest(cache, '/webapi/odata/GetStatementByReviewTeamId(reviewTeamId={{reviewTeamId}})?$orderby=statementId%20desc&$expand=statementDetailDtos', promises, review);
                    promises.push(factory.getByReviewTeamId(review.reviewTeamId, isDownloading).then(function (data) {
                        if (data && data.length > 0)
                            // Need statementId to open statement editor tool
                            review.statementId = data[0].statementId;
                        return data;
                    }));
                    promises.push(evaluatorReportSvc.getEvaluatorReportByReviewTeamId(review.reviewTeamId, noStoredData, isDownloading));
                    promises.push(
                        reviewTeamSvc.getSingleReviewTeamById(review.reviewTeamId, true).then(reviewTeam =>
                            cacheRequest(cache, `/webapi/odata/Review(${reviewTeam.reviewId})?$expand=organizationDto($expand=currentOrganizationDetailDto),organizationDto($expand=currentOrganizationAddressDto),reviewSimultaneousOptionDtos($expand=reviewSimultaneousOptionCommissionDtos),reviewJointOptionDtos($expand=reviewJointOptionProgramDtos),reviewTeamDtos`)
                        )
                    );
                    promises.push(
                        reviewTeamMemberSvc.getMyReviewTeamMembers(review.reviewTeamId).then(data => {
                            const leadSocietyIdsSet = new Set();
                            data.forEach(teamMember => {
                                if (teamMember.leadSocietyId !== null && teamMember.leadSocietyId !== undefined) {
                                    leadSocietyIdsSet.add(teamMember.leadSocietyId);
                                }
                            })
                            const leadSocietyIds = Array.from(leadSocietyIdsSet);
                            const getOrgAbbrvPromises = leadSocietyIds.map(leadSocietyId =>
                                cacheRequest(cache, `/webapi/odata/GetGlobalVariable(variableName='OrganizationAbbreviation',inArg='${leadSocietyId}')`)
                            );

                            return $q.all(getOrgAbbrvPromises);
                        })
                    );
                });
                return $q.all(promises).then(function () {
                    localStorage.add(OFFLINE_REVIEWS_KEY, selectedReviews);
                });
            });
        }

        function cacheRequest(cache, url, promises, review) {
            if (review) {
                url = url.replace("{{reviewTeamId}}", review.reviewTeamId);
                url = url.replace("{{statementId}}", review.statementId);
            }
            if (environmentSvc.isDev())
                url = environmentSvc.getDevApiUrl() + url;
            var amsHeaders = new Headers({
                'Authorization': 'Bearer ' + currentUser.profile.token,
                'OrganizationId': currentOrganization.profile.organizationId
            });
            var request = new Request(url, { headers: amsHeaders });
            var promise = cache.add(request);
            if (promises) {
                promises.push(promise);
            }
            return promise;
        }

        factory.isSyncing = false;

        factory.syncOfflineData = function () {
            var reviews = factory.getOfflineReviews() || [];
            if (factory.isSyncing === true || !reviews.length) {
                // No data exists from offline mode; sync was successful and cleaned up after itself
                // Either that or syncing is being performed in a different tab.
                return $q.when(true);
            } else {
                // Set flag to prevent multiple tabs from trying to sync at same time
                factory.isSyncing = true;
                try {
                    // Perform sync of offline edits
                    return factory.uploadOfflineData(reviews).then(function (data) {
                        // Clean up offline data
                        return factory.removeOfflineData().then(function (data) {
                            factory.isSyncing = false;
                            return data;
                        });
                    }).catch(function (error) {
                        // Catch errors that occur inside a promise, then raise again to be caught later 
                        return handleError(error);
                    });
                } catch (error) {
                    // Catch errors that occur outside of a promise
                    return handleError(error);
                }
            }

            function handleError(error) {
                factory.isSyncing = false;
                try {
                    console.log('Sync Offline Data Error', error);
                    logClientSvc.insert('statementStorageSvc', error.message, error.stack);
                } finally {
                    return $q.reject(error);
                }
            }
        };

        factory.uploadOfflineData = function (reviews) {
            var promises = [];

            angular.forEach(reviews, function (review) {
                var statement = getLocalStatement(review.statementId);
                if (!statement) {
                    console.log('No statement for review?', review, statement);
                    return;
                }
                statement = deserializeJson(statement); // stored locally in original format
                var oSvc = odataSvc.get();
                factory.clearAutosaving(statement);
                factory.removeOrphanedCommentsFromStatement(statement);
                var updatedStatement = serializeJson(statement);
                cleanStatement(updatedStatement);
                var resource = oSvc.instantiate(api, key, updatedStatement);
                promises.push(resource.$update(null, oSvc.onSuccess, oSvc.onFailure));
            });

            // Upload evaluator report data
            promises.push(evaluatorReportSvc.uploadOfflineData(reviews));

            return $q.all(promises);
        }

        factory.removeOfflineData = function () {
            // Delete working data saved to working storage
            var storageKeys = localStorage.getKeys();
            var statementDataKeys = storageKeys.filter(function (key) {
                return key.startsWith(REVIEWTEAMSTATEMENTS_KEY_PREFIX) || key.startsWith(STATEMENT_KEY_PREFIX);
            });
            statementDataKeys.forEach(function (key) {
                localStorage.remove(key);
            });
            // Remove evaluator report data
            evaluatorReportSvc.removeOfflineData();
            // Remove list of reviews selected for offline editing
            localStorage.remove(OFFLINE_REVIEWS_KEY);
            // Delete cache of dynamic web API data responses
            // (return empty promise is cache storage is not implemented or unavailable)
            var promise;
            try {
                if (typeof caches !== "undefined")
                    promise = caches.delete(DYNAMIC_CACHE);
            } catch (error) {
                console.log(error);
                promise = $q.when(true);
            } 
            return promise;
        };

        function isReportTC(){
            if(factory.data.currentReviewTeam && factory.data.currentReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMREPORT){
                return true;
            }
            return false;
        }

        factory.getSubmissionStatus = function(statement, index){
            if((statement.statementTypeId === statementTypeIds.FINAL || statement.statementTypeId === statementTypeIds.POSTTHIRTYDAY) && statement.teamMemberTypeId === teamMemberTypeNames.ADMIN){
                return "Admin Received"
            }
            if (statement.submittedTimestamp) {
                if (statement.submittedToTeamMemberTypeId < statement.submittedByTeamMemberTypeId && statement.statementStatusId < statementStatuses.ADMINPREPFORSEND) {
                    return "Returned";
                }
                else if(statement.noChangeOption === true){
                    return "Submitted (No Changes)";
                }
                return "Submitted";        
            } else {
                return "Pending Submission"
            }
        }

        factory.createInitialStatement = function (reviewTeamId, commissionId, reviewTypeCode, evaluatorReportData, programs, reviewTeamMembers, programAudit, programAuditAccess, previousStatement, noStoredData, isDownloading) {
            var loadReferenceData;

            if (factory.codes && factory.codes.statementFindingTypes) {
                loadReferenceData = $q.when(true);
            } else {
                loadReferenceData = statementSvc.getStatementFindingTypes().then(data => {
                    factory.codes = factory.codes || {};
                    factory.codes.statementFindingTypes = data;
                })
            }

            return loadReferenceData.then(function () {
                var statement = {
                    reviewTeamId: reviewTeamId,
                    statementId: 0,
                    statementTypeId: 1,
                    statementStatusId: 1,
                    teamMemberTypeId: reviewTypeCode === reviewTypeIds.INTERIMREPORT ? teamMemberTypeNames.REPORTTEAMCHAIR : teamMemberTypeNames.TEAMCHAIR,
                    submittedTimestamp: null,
                    submittedByVolunteerId: null,
                    submittedByTeamMemberTypeId: null,
                    submittedToTeamMemberTypeId: null,
                    noChangeOption: null,
                    statementDetailDtos: []
                }

                if (!evaluatorReportData || angular.equals(evaluatorReportData, {})) {
                    var evaluatorReport = {
                        evaluatorReportId: 0,
                        reviewTeamId: reviewTeamId,
                        evaluatorReportDetailDtos: []
                    };
                }

                var insitutionIntroCode = helperSvc.getFirstMatch(factory.codes.statementFindingTypes, 'statementFindingTypeId', statementFindingTypes.INSTITUTIONINTRODUCTION);
                var insitutionAfterVisitCode = helperSvc.getFirstMatch(factory.codes.statementFindingTypes, 'statementFindingTypeId', statementFindingTypes.INSTITUTIONAFTERVISIT);
                var intitutionSummaryCode = helperSvc.getFirstMatch(factory.codes.statementFindingTypes, 'statementFindingTypeId', statementFindingTypes.INSTITUTIONSUMMARY);
                var intitutionStrengthCode = helperSvc.getFirstMatch(factory.codes.statementFindingTypes, 'statementFindingTypeId', statementFindingTypes.INSTITUTIONSTRENGTH);
                var reviewTeamCode = helperSvc.getFirstMatch(factory.codes.statementFindingTypes, 'statementFindingTypeId', statementFindingTypes.REVIEWTEAM);
                var programIntroCode = helperSvc.getFirstMatch(factory.codes.statementFindingTypes, 'statementFindingTypeId', statementFindingTypes.PROGRAMINTRODUCTION);

                var institutionSection = {
                    // statementId: 0,
                    programId: null,
                    statementCategoryName: "Institution",
                    statementCategoryId: statementCategories.INSTITUTION,
                    statementJson: [
                        createSection(
                            insitutionIntroCode.statementFindingTypeId,
                            insitutionIntroCode.typeName,
                            insitutionIntroCode.orderNumber
                        ),
                        createSection(
                            insitutionAfterVisitCode.statementFindingTypeId,
                            insitutionAfterVisitCode.typeName,
                            insitutionAfterVisitCode.orderNumber
                        )
                    ]
                }

                //Add institutional summary and strength sections

                let instFindingTypes = [];
                
                if ((programAudit && programAudit.programAuditDetailDtos.length) || (reviewTypeCode == 'IR' || reviewTypeCode == 'IV'))
                    instFindingTypes = angular.fromJson(programAudit.programAuditInstitutionalSummaryDtos[0].institutionalSummaryJson);

                const instSummary = instFindingTypes.find(t => t.statementFindingTypeId === statementFindingTypes.INSTITUTIONSUMMARY);
                if (instSummary) {
                    instSummary.findings = instSummary.findings.map(finding =>
                        convertAuditFinding(finding, factory.isShortcoming(instSummary.statementFindingTypeId))
                    );
                    institutionSection.statementJson.push(instSummary);
                }

                if (reviewTypeCode !== 'IR') {
                    const instStrength = instFindingTypes.find(t => t.statementFindingTypeId === statementFindingTypes.INSTITUTIONSTRENGTH);
                    if (instStrength) {
                        instStrength.findings = instStrength.findings.map(finding =>
                            convertAuditFinding(finding, factory.isShortcoming(instStrength.statementFindingTypeId))
                        );
                        institutionSection.statementJson.push(instStrength);
                    }
                }

                //Add Review Team Section for CAC
                if (commissionId === commissionIds.CAC) {
                    var section = createSection(
                        reviewTeamCode.statementFindingTypeId,
                        reviewTeamCode.typeName,
                        reviewTeamCode.orderNumber
                    );
                    institutionSection.statementJson.splice(1, 0, section);
                }

                statement.statementDetailDtos.push(institutionSection);

                angular.forEach(programs, function (program) {

                    //do not include cancelled,withdrawn, and terminating programs
                    if (program.programReviewTypeCode === programReviewTypeIds.TERMINATIONREPORT || program.programReviewTypeCode === programReviewTypeIds.TERMINATIONVISIT ||
                                                            program.actionCode === programReviewActionCodes.CANCELLED || program.actionCode === programReviewActionCodes.WITHDRAWN)
                        return;

                    var programSection = {
                        // statementId: 0,
                        programId: program.programId,
                        statementCategoryId: statementCategories.PROGRAM,
                        statementCategoryName: "Program",
                        recommendedAction: null,
                        newProgramStartDate: null,
                        terminationDate: null,
                        statementJson: [
                            createSection(
                                statementFindingTypes.PROGRAMINTRODUCTION,
                                statementFindingTypeNames[statementFindingTypes.PROGRAMINTRODUCTION],
                                programIntroCode.orderNumber
                            )
                        ]
                    }

                    var evaluatorReportDetail;
                    if (!evaluatorReportData || angular.equals(evaluatorReportData, {})) {
                        evaluatorReportDetail = {
                            evaluatorReportDetailId: 0,
                            programId: program.programId,
                            recommendedAction: null,
                            statementJson: null
                        }
                        evaluatorReport.evaluatorReportDetailDtos.push(evaluatorReportDetail);
                    }

                    statement.statementDetailDtos.push(programSection);

                    // Transfer findings from program audit
                    transferProgramAuditData(programAudit, program, programAuditAccess, reviewTeamMembers, programSection, evaluatorReportDetail);
                    // Transfer findings from previous statement
                    transferPreviousStatementData(previousStatement, program, programSection);
                    // Set isNormalFindingForIR to true for any finding that was transferred from program audit which was not on previous statement
                    setFindingStatus(program, programSection);
                });

                // Statement generation complete, create on server
                var promiseArray = [];
                var createStatementPromise = factory.create(statement, noStoredData, isDownloading);
                promiseArray.push(createStatementPromise);

                if (!evaluatorReportData || angular.equals(evaluatorReportData, {})) {
                    var createEvaluatorReportPromise = evaluatorReportSvc.create(evaluatorReport, noStoredData, isDownloading);
                    promiseArray.push(createEvaluatorReportPromise);
                }
                return $q.all(promiseArray);
            });

            function cleanText(text) {
                // Remove comments from text
                return text.replace(/<\/?mark.*?>/gi, "");
            }

            function convertAuditFinding(auditFinding, isShortcoming) {
                const finding = createBlankFinding();
                // Set fields applicable to shortcomings (i.e. when "criteria needed")
                if (isShortcoming) {
                    finding.criteria.progress = []; // progress may be unused
                    finding.criteria.editable = true; // not sure why shortcomings use finding.criteria.editable and other findings use finding.editable
                    delete finding.editable;
                }
                if (auditFinding.criteria.criteriaId) finding.criteria.criteriaId = auditFinding.criteria.criteriaId;
                if (auditFinding.criteria.criteriaName) finding.criteria.criteriaName = auditFinding.criteria.criteriaName;
                finding.criteria.text = cleanText(auditFinding.criteria.text);

                return finding;
            }

            function convertPreviousStatementFinding(previousStatementFinding, isShortcoming, commissionId) {
                const finding = createBlankFinding();
                // Set fields applicable to shortcomings (i.e. when "criteria needed")
                if (isShortcoming) {
                    finding.criteria.progress = []; // progress may be unused
                    finding.criteria.editable = true; // not sure why shortcomings use finding.criteria.editable and other findings use finding.editable
                    delete finding.editable;
                }
                // Set criteria if it exists on previous finding
                if (previousStatementFinding.criteria.criteriaId)
                    finding.criteria.criteriaId = previousStatementFinding.criteria.criteriaId;
                if (previousStatementFinding.criteria.criteriaName)
                    finding.criteria.criteriaName = previousStatementFinding.criteria.criteriaName;
                // Create placeholder for interim response 
                finding.criteria.response.interimStatus = createBlankFindingResponseTemplate();
                // Status of "isNormalFindingForIR" is false for previous statement findings
                finding.criteria.status = createFindingStatus(false);
                // Set last visit text based on previous finding text and responses
                let lastVisitText = "";
                // Do not transfer last visit text for CAC but leave a placeholder
                if (commissionId !== commissionIds.CAC) {
                    lastVisitText = previousStatementFinding.criteria.text;
                    // Add due response text as applicable
                    for (const dueResponseTypeId in dueResponsePropNamesByTypeId) {
                        const dueResponsePropName = dueResponsePropNamesByTypeId[dueResponseTypeId];
                        if (dueResponsePropName === dueResponsePropNames.SEVENDAY ||
                            !previousStatementFinding.criteria.response ||
                            !previousStatementFinding.criteria.response[dueResponsePropName] ||
                            !previousStatementFinding.criteria.response[dueResponsePropName].text)
                            continue;
                        lastVisitText +=
                            `<p>${dueResponseHeadings[dueResponseTypeId]}</p>` +
                            `${previousStatementFinding.criteria.response[dueResponsePropName].text}`;
                    }
                }
                finding.criteria.lastVisitText = cleanText(lastVisitText);

                return finding;
            }

            function createSection(statementFindingTypeId, statementFindingTypeName, orderNumber, findings) {
                return {
                    statementFindingTypeId: statementFindingTypeId,
                    statementFindingTypeName: statementFindingTypeName,
                    orderNumber: orderNumber,
                    findings: findings || [createBlankFinding()]
                }
            }

            function createBlankFinding() {
                return { editable: true, criteria: { text: '', comments: [], response: createBlankFindingResponse() }, key: helperSvc.getNextKey() };
            }

            function createBlankFindingResponse() {
                return { sevenDay: null, interimStatus: null, thirtyDay: null, postThirtyDay: null };
            }

            function createBlankFindingResponseTemplate() {
                return { findingStatusTypeId: null, updatedFindingTypeId: null, updatedFindingTypeName: null, text: null };
            }

            function createFindingStatus(isNormalFindingForIR) {
                isNormalFindingForIR = !!isNormalFindingForIR;
                return { editable: true, isNormalFindingForIR: isNormalFindingForIR, isResolved: false, selectedOptionText: '', detailText: null };
            }

            function extractPreviousFindings(previousStatementProgram, program) {
                // Build map of previous finding types to maps of criteria to arays of findings
                const previousFindingTypesMap = previousStatementProgram.statementJson.filter(sourceFindingType =>
                    factory.isShortcoming(sourceFindingType.statementFindingTypeId)
                ).reduce((previousFindingTypesMap, sourceFindingType) => {
                    sourceFindingType.findings.forEach(sourceFinding => {
                        // Look at responses to see if previous finding was resolved or changed type
                        let finalSourceFindingTypeId = sourceFindingType.statementFindingTypeId;
                        const interimResponse = sourceFinding.criteria.response && sourceFinding.criteria.response[dueResponsePropNames.INTERIM];
                        const thirtyDayResponse = sourceFinding.criteria.response && sourceFinding.criteria.response[dueResponsePropNames.THIRTYDAY];
                        const postThirtyDayResponse = sourceFinding.criteria.response && sourceFinding.criteria.response[dueResponsePropNames.POSTTHIRTYDAY];
                        // Don't transfer finding if it was resolved
                        const mostRecentResponse = postThirtyDayResponse || thirtyDayResponse || interimResponse;
                        if (mostRecentResponse && mostRecentResponse.findingStatusTypeId === findingStatusTypeIds.RESOLVED)
                            return;
                        // Transfer finding as the most recent finding type it was changed to
                        const mostRecentChange = [postThirtyDayResponse, thirtyDayResponse, interimResponse].find(response =>
                            response && response.findingStatusTypeId === findingStatusTypeIds.CHANGED
                        );
                        finalSourceFindingTypeId = mostRecentChange && mostRecentChange.updatedFindingTypeId || finalSourceFindingTypeId;
                        // Transfer finding to new statement
                        const previousFindingsMap = previousFindingTypesMap.get(finalSourceFindingTypeId) ||
                            previousFindingTypesMap.set(finalSourceFindingTypeId, new Map()).get(finalSourceFindingTypeId);
                        const sourceCriteriaKey = sourceFinding.criteria.criteriaId || 0; // program intro, strengths, and observations have no criteria id
                        const previousFindings = previousFindingsMap.get(sourceCriteriaKey) ||
                            previousFindingsMap.set(sourceCriteriaKey, []).get(sourceCriteriaKey);
                        const convertedFinding = convertPreviousStatementFinding(sourceFinding, true, program.programDto.commissionId);
                        convertedFinding.criteria.progress = []; // only previous shortcomings are transferred, will always have progress (field may be unused)
                        previousFindings.push(convertedFinding);
                    });

                    return previousFindingTypesMap;
                }, new Map());

                return previousFindingTypesMap;
            }

            function extractProgramAuditFindings(programAuditDetail, program) {
                // Build map of program audit finding types to maps of criteria to arays of findings
                const isInterimReview = factory.isInterimReview(program.programReviewTypeCode);
                const programAuditJson = (typeof programAuditDetail.programAuditJson === 'string') ?
                    angular.fromJson(programAuditDetail.programAuditJson) :
                    programAuditDetail.programAuditJson;
                const auditFindingTypesMap = programAuditJson.auditDetails.reduce((auditFindingTypesMap, sourceFindingType) => {
                    sourceFindingType.findings.forEach(sourceFinding => {
                        const sourceFindingTypeId = sourceFindingType.statementFindingTypeId;
                        const auditFindingsMap = auditFindingTypesMap.get(sourceFindingTypeId) ||
                            auditFindingTypesMap.set(sourceFindingTypeId, new Map()).get(sourceFindingTypeId);
                        const sourceCriteriaKey = sourceFinding.criteria.criteriaId || 0; // program intro, strengths, and observations have no criteria id
                        const auditFindings = auditFindingsMap.get(sourceCriteriaKey) ||
                            auditFindingsMap.set(sourceCriteriaKey, []).get(sourceCriteriaKey);
                        const convertedFinding = convertAuditFinding(sourceFinding, factory.isShortcoming(sourceFindingTypeId));
                        if (isInterimReview && auditFindings.length && sourceCriteriaKey)
                            // Combine multiple findings in interim reviews against same shortcoming & criteria into one
                            // Note: may need to revist if ability to add multiple findings against same shortcoming & criteria is added to statement tool.
                            auditFindings[0].criteria.text += convertedFinding.criteria.text;
                        else
                            auditFindings.push(convertedFinding);
                    });

                    return auditFindingTypesMap;
                }, new Map());

                return auditFindingTypesMap;
            }

            function mergeFindings(sourceFindingTypesMap, programSection) {
                // Transfer findings data to existing program section
                sourceFindingTypesMap.forEach((sourceFindingsMap, sourceFindingTypeId) => {
                    // Find current section for this finding type or create if not found
                    let destinationFindingType = programSection.statementJson.find(destinationFindingType =>
                        destinationFindingType.statementFindingTypeId === sourceFindingTypeId
                    );
                    if (!destinationFindingType) {
                        const statementFindingType = helperSvc.getFirstMatch(factory.codes.statementFindingTypes, 'statementFindingTypeId', sourceFindingTypeId);

                        destinationFindingType = createSection(
                            statementFindingType.statementFindingTypeId,
                            statementFindingTypeNames[statementFindingType.statementFindingTypeId],
                            statementFindingType.orderNumber,
                            []
                        );
                        programSection.statementJson.push(destinationFindingType);
                    }
                    // Transfer findings for specific criteria
                    sourceFindingsMap.forEach((sourceFindings, sourceFindingsCriteriaId) => {
                        const destinationFindings = destinationFindingType.findings.filter(destinationFinding =>
                            sourceFindingsCriteriaId ?
                                destinationFinding.criteria.criteriaId === sourceFindingsCriteriaId :
                                !destinationFinding.criteria.criteriaId // intro, strengths, observations do not specify criteria Id
                        );
                        if (!destinationFindings.length) {
                            // Add source findings for criteria to current findings
                            destinationFindingType.findings.push(...sourceFindings);
                        } else if (destinationFindings.length === 1 && sourceFindings.length === 1) {
                            // Merge source finding data to destination finding 
                            const sourceFinding = sourceFindings[0];
                            const destinationFinding = destinationFindings[0];
                            if (sourceFinding.criteria.text !== undefined &&
                                (sourceFinding.criteria.text.length || destinationFinding.criteria.text === undefined))
                                destinationFinding.criteria.text = sourceFinding.criteria.text;
                            if (sourceFinding.criteria.lastVisitText !== undefined &&
                                (sourceFinding.criteria.lastVisitText.length || destinationFinding.criteria.lastVisitText === undefined))
                                destinationFinding.criteria.lastVisitText = sourceFinding.criteria.lastVisitText;
                            if (sourceFinding.criteria.status && !destinationFinding.criteria.status)
                                destinationFinding.criteria.status = angular.copy(sourceFinding.criteria.status);
                            if (sourceFinding.criteria.response) {
                                destinationFinding.criteria.response = destinationFinding.criteria.response || createBlankFindingResponse();
                                for (let dueResponsePropNameEntry in dueResponsePropNames) {
                                    const dueResponsePropName = dueResponsePropNames[dueResponsePropNameEntry];
                                    if (sourceFinding.criteria.response[dueResponsePropName] && !destinationFinding.criteria.response[dueResponsePropName])
                                        destinationFinding.criteria.response[dueResponsePropName] = angular.copy(sourceFinding.criteria.response[dueResponsePropName]);
                                }
                            }
                        }
                        // Unable to merge multiple findings against same criteria without a means of uniquely identifying indiviudal findings.
                        // E.g. can't tell which of many APPM findings in PAF are referred to by single APPM finding in previous statement without further delimiting information.
                    });

                    destinationFindingType.findings.sort(factory.findingsComparer);
                });

                programSection.statementJson.sort((a, b) => a.orderNumber - b.orderNumber);
            }

            function setFindingStatus(program, programSection) {
                // Set "isNormalFindingForIR for interim program review findings where it is no longer ambiguous whether finding is new or from previous review
                // Only set "isNormalFindingForIR" status for interim reviews
                if (!factory.isInterimReview(program.programReviewTypeCode)) return;
                // Set status "isNormalFindingForIR" to true for findings not merged from previous interim review
                programSection.statementJson.filter(statementFindingType =>
                    factory.isShortcoming(statementFindingType.statementFindingTypeId)
                ).forEach(statementFindingType => {
                    statementFindingType.findings.filter(finding =>
                        !finding.criteria.status
                    ).forEach((finding, index, findings) => {
                        finding.criteria.status = createFindingStatus(true);
                    })
                });
            }

            function transferPreviousStatementData(previousStatement, program, programSection) {
                // Find previous statement program section for given program                
                const previousStatementProgram = factory.isInterimReview(program.programReviewTypeCode) &&
                    previousStatement && previousStatement.statementDetailDtos.find(statementDetail =>
                        statementDetail.programId === program.programId
                    );
                if (!previousStatementProgram) return;
                // Extract relevant findings and group by finding type, criteria
                const previousFindingTypesMap = extractPreviousFindings(previousStatementProgram, program);
                // Merge findings into existing program section
                mergeFindings(previousFindingTypesMap, programSection);
            }

            function transferProgramAuditData(programAudit, program, programAuditAccess, reviewTeamMembers, programSection, evaluatorReportDetail) {
                // Find program audit detail data for given program
                const programAuditDetail = programAudit && programAudit.programAuditDetailDtos.find(programAuditDetail =>
                    programAuditDetail.programId === program.programId && programAuditDetail.isCurrent
                );
                if (!programAuditDetail) return;
                // Extract findings and group by finding type, criteria
                const auditFindingTypesMap = extractProgramAuditFindings(programAuditDetail, program);
                // Merge findings into existing program section
                mergeFindings(auditFindingTypesMap, programSection);
                // Transfer recommended action
                transferRecommendedAction(programAuditDetail, program, programAuditAccess, evaluatorReportDetail);
            }

            function transferRecommendedAction(programAuditDetail, program, programAuditAccess, evaluatorReportDetail) {
                if (!programAuditDetail.programAuditDetailEvaluatorDtos || !programAuditDetail.programAuditDetailEvaluatorDtos.length) return;
                // For now, we can only save one PEV recommended action; try to find RA from PEV assigned to program audit
                const pevProgramAuditAccess = programAuditAccess && programAuditAccess.find(programAuditAccess =>
                    programAuditAccess.programId === program.programId && programAuditAccess.accessType === 'PEV'
                );
                const pevReviewTeamMember = pevProgramAuditAccess && reviewTeamMembers && reviewTeamMembers.find(reviewTeamMember =>
                    reviewTeamMember.reviewTeamMemberId === pevProgramAuditAccess.reviewTeamMemberId
                );
                const pevEvaluator = pevReviewTeamMember && programAuditDetail.programAuditDetailEvaluatorDtos.find(programAuditDetailEvaluator =>
                    programAuditDetailEvaluator.volunteerId === pevReviewTeamMember.volunteerId
                );
                evaluatorReportDetail.recommendedAction = pevEvaluator ?
                    pevEvaluator.recommendedAction :
                    programAuditDetail.programAuditDetailEvaluatorDtos[0].recommendedAction;
            }
 
        }

        factory.getReviewTeamSectionText = function(programs) {
                var multiplePrograms = programs && programs.filter(t => t.actionCode !== 'W' && t.actionCode !== 'C').length > 1;

                var text = '<p>The program{0} listed above {1} evaluated by the peer review team shown below.</p>'.format(multiplePrograms ? 's' : '', multiplePrograms ? 'were' : 'was');
                var list = '<ul>';
                var visitStartDate = factory.data.currentReviewTeam.visitStartDate;
                var visitEndDate = factory.data.currentReviewTeam.visitEndDate;

                //fliter out observers
                var filteredMembers = factory.data.reviewTeamMembers.filter(function (member) {
                    // return team chairs that are active throughout visit range, 
                    // or start within visit range,
                    // or end within visit range.
                    if (teamMemberTypeNames.TEAMCHAIR === member.teamMemberTypeId) {
                        return (member.startDate <= visitEndDate && member.endDate >= visitStartDate) ||
                            (member.startDate <= visitEndDate && member.startDate >= visitStartDate) ||
                            (member.endDate <= visitEndDate && member.endDate >= visitStartDate);
                    }
                    return teamMemberTypes.OBSERVERS.indexOf(member.teamMemberTypeId) === -1 && member.teamMemberStatusId === teamMemberStatusTypes.ASSIGNMENTAPPROVED &&
                        !(factory.data.statementProgramReviews.some(pr => [programReviewActionCodes.CANCELLED, programReviewActionCodes.WITHDRAWN].includes(pr.actionCode) && pr.programReviewDisciplineDtos?.some(d => d.programReviewDisciplineId === member.programReviewDisciplineId)));
                });

                filteredMembers.sort(function(a,b){return a.teamMemberTypeId - b.teamMemberTypeId});
                angular.forEach(filteredMembers, function (member) {
                    var listItem = '<li><label>{0}</label> &nbsp;{1}{2}</li>'.format(member.teamMemberTypeName, member.normalizedName, member.currentEmploymentName && member.currentEmploymentName !== '' ? ', ' + member.currentEmploymentName : '');
                    list += listItem;
                });
                            
                list += '</ul>';

                text += list;
                text += '<p>Please note that program accreditation decisions are made solely by the respective Commissions of ABET.  Reference to the professional affiliations of the volunteer peer evaluators in no way constitutes or implies endorsement or recommendation of the programs by the listed professional affiliations.</p>'


                return list !== '<ul></ul>' ? text : '';

                function addSpacing(teamMemberTypeId) {
                    return teamMemberTypeId === teamMemberTypeNames.PEV ? '&#9;' : teamMemberTypeId === teamMemberTypeNames.EDITOR1 || teamMemberTypeId === teamMemberTypeNames.EDITOR2 ? '&#9;&#9;&#9;' : '&#9;&#9;';
                }
    
        }

        factory.hideFindingSubtitle = function (findingType) {
            var id = findingType.statementFindingTypeId;
            var hasNoCriteria = (id == statementFindingTypes.INSTITUTIONSTRENGTH) || (id == statementFindingTypes.PROGRAMSTRENGTH) || (id == statementFindingTypes.PROGRAMOBSERVATION) || (id == statementFindingTypes.TERMINATIONPLAN);
            return (id == statementFindingTypes.INSTITUTIONINTRODUCTION || id == statementFindingTypes.INSTITUTIONAFTERVISIT || id == statementFindingTypes.REVIEWTEAM || id == statementFindingTypes.INSTITUTIONSUMMARY || id == statementFindingTypes.PROGRAMINTRODUCTION || id == statementFindingTypes.PROGRAMSUMMARY) || (hasNoCriteria && findingType.findings.length < 2);
        };

        factory.sectionHasText = function (str) {
            var strippedStr = str ? str.replace(/(<([^>]+)>)/ig, "").trim() : str;
            var empty = '&nbsp;';
            var defaultStr = 'Click to add text...';

            return strippedStr ? !(strippedStr == empty || (strippedStr.indexOf(defaultStr) >= 0 && strippedStr.length <= defaultStr.length)) : false;
        };

        factory.clearAutosaving = function (statement) {
            helperSvc.deleteProperties(statement, ['saveComplete', 'threeSecondsElapsed', 'isAutosaving']);
        }

        factory.isNewProgram = function (section) {
            //get the correct program review based on the id  and used the different svc based on if were in the tool or not
            var programReviews = programReviewSvc && programReviewSvc.data && factory.data.statementProgramReviews ? factory.data.statementProgramReviews : programReviewSvc.data.programs;
            if (section.programId && programReviews) {
                var programReview = helperSvc.getFirstMatch(programReviews, 'programId', section.programId);
                if (programReview && programReview.programReviewTypeCode === programReviewTypeIds.INITIALACCREDIATION) {
                    return true;
                }
            }
            return false;
        }

        factory.isTerminationProgram = function (section) {
            //get the correct program review based on the id  and used the different svc based on if were in the tool or not
            var programReviews = programReviewSvc && programReviewSvc.data && factory.data.statementProgramReviews ? factory.data.statementProgramReviews : programReviewSvc.data.programs;
            if (section.programId && programReviews) {
                var programReview = helperSvc.getFirstMatch(programReviews, 'programId', section.programId);
                if (programReview && (programReview.programReviewTypeCode === programReviewTypeIds.TERMINATIONVISIT || programReview.programReviewTypeCode === programReviewTypeIds.TERMINATIONREPORT)) {
                    return true;
                }
            }
            return false;
        }

        factory.removeHighlightsByFinding = function (finding, comment) {
            var allMarks = [];

            finding.criteria.comments.forEach(function (item) {
                var marks = document.querySelectorAll('[data-id="' + item.markId + '"]');
                if (marks && marks.length) {
                    for (var i = 0; i < marks.length; i++)
                        allMarks.push(marks[i]);
                }
            });

            finding.criteria.comments.forEach(function (item) {
                if (item !== comment) {
                    item.isSelected = false;
                }

                

                for (var i = 0; i < allMarks.length; i++) {
                    var mark = allMarks[i];
                    //if (mark.getAttribute('data-id') === comment.markId) {
                        var selectedMark = angular.element(mark);

                        //if (comment.isSelected) {
                        selectedMark.removeAttr('class');
                        selectedMark.addClass('ck-comment-nostyle');
                        //}
                   // }
                }
            });
        }

        factory.highlightSingleComment = function (comment) {
            var markFound = false;
            var allMarks = document.getElementsByTagName('MARK');
            for (var i = 0; i < allMarks.length; i++) {
                var mark = allMarks[i];
                if (mark.getAttribute('data-id') === comment.markId) {
                    var selectedMark = angular.element(mark);
                    markFound = true;
                    if (comment.isSelected) {
                        selectedMark.removeClass('ck-comment-nostyle');
                        selectedMark.addClass('role');
                        selectedMark.addClass(reviewTeamSvc.getTeamMemberTypeStyle(comment.teamMemberTypeId)); // model.getTeamMemberTypeStyle(comment.teamMemberTypeId)
                    }
                }
            }
            if (!markFound) alertSvc.addAlertWarning('Original comment text has been deleted.');

            return markFound;
        }

        factory.isProgramInterimReview = function(programId) {
            var interimReviewTypes = [programReviewTypeIds.FOCUSEDREPORT, programReviewTypeIds.FOCUSEDVISIT, programReviewTypeIds.SHOWCAUSEREPORT, programReviewTypeIds.SHOWCAUSEVISIT];
            var programReviews = programReviewSvc && programReviewSvc.data && programReviewSvc.data.programs ? programReviewSvc.data.programs : factory.data.statementProgramReviews;
            if (programId && programReviews) {
                var programReview = helperSvc.getFirstMatch(programReviews, 'programId', programId);
                if (programReview && (interimReviewTypes.indexOf(programReview.programReviewTypeCode) >= 0)) {
                    return true;
                }
            }
            return false;
        }

        factory.getIVProgramsNotIVReviewType = function(statment){
            var statement = statement ? statement : factory.data.statement;
            var nonIVProgramIds = [];
            if(statement && statement.statementDetailDtos){
                angular.forEach(statement.statementDetailDtos, function(detailDto){
                    if(detailDto.programId){
                        if(!factory.isProgramInterimReview(detailDto.programId)){
                            nonIVProgramIds.push(detailDto.programId);
                        }
                    }
                });
            }
            return nonIVProgramIds;
        }

        factory.isInterimReview = function (programReviewTypeCode) {
            const interimReviewProgramReviewTypes = [
                programReviewTypeIds.FOCUSEDREPORT,
                programReviewTypeIds.FOCUSEDVISIT,
                programReviewTypeIds.SHOWCAUSEREPORT,
                programReviewTypeIds.SHOWCAUSEVISIT
            ];

            return interimReviewProgramReviewTypes.includes(programReviewTypeCode)
        }

        factory.isShortcoming = function (statementFindingTypeId) {
            var shortcomings = [statementFindingTypes.PROGRAMDEFICIENCY, statementFindingTypes.PROGRAMWEAKNESS, statementFindingTypes.PROGRAMCONCERN];
            return shortcomings.indexOf(statementFindingTypeId) > -1;
        }
        
        factory.hasNoFindings = function (section) {
            return section.statementJson.length < 2 && section.statementCategoryId == statementCategories.PROGRAM;
        }

        factory.hasNoNegativeFindings = function (section) {
            if (section.statementCategoryId == statementCategories.PROGRAM) {
                var hasNegativeFinding = false;
                section.statementJson.forEach(function (finding) {
                    if (finding.statementFindingTypeId == statementFindingTypes.PROGRAMCONCERN ||
                        finding.statementFindingTypeId == statementFindingTypes.PROGRAMDEFICIENCY ||
                        finding.statementFindingTypeId == statementFindingTypes.PROGRAMWEAKNESS) {
                        hasNegativeFinding = true;
                    }
                });
                return !hasNegativeFinding;
            }
            return false;
        }

        factory.isInterimReport = function () {
            return factory.data.currentReviewTeam && factory.data.currentReviewTeam.reviewTypeCode == reviewTypeIds.INTERIMREPORT;
        };

        factory.isInterimVisit = function () {
            return factory.data.currentReviewTeam && factory.data.currentReviewTeam.reviewTypeCode === reviewTypeIds.INTERIMVISIT;
        };

        factory.nonIVProgramsExist = function () {
            return factory.isInterimVisit() && factory.getIVProgramsNotIVReviewType().length > 0;
        };

        function getDueResponseData () {
            const isInterimReport = factory.isInterimReport();
            const isInterimVisit = factory.isInterimVisit();

            const data = {
                dueResponseStatus: {
                    sevenDay: {
                        hasNotResponded: null,
                        hasNoResponse: null,
                        hasResponse: null
                    },
                    thirtyDay: {
                        hasNotResponded: null,
                        hasNoResponse: null,
                        hasResponse: null
                    },
                    postThirtyDay: {
                        hasNotResponded: null,
                        hasNoResponse: null,
                        hasResponse: null
                    },
                    interimStatus: {
                        hasNotResponded: null,
                        hasNoResponse: null,
                        hasResponse: null
                    }
                },
                response: {
                    sevenDay: isInterimReport ? false : true,
                    thirtyDay: false,
                    postThirtyDay: false,
                    interimStatus: isInterimReport || isInterimVisit ? true : false
                }
            };

            var visitEndDate = new Date(factory.data.currentReviewTeam.visitEndDate);
            var currentDate = new Date();
            var timeDiff = Math.abs(currentDate.getTime() - visitEndDate.getTime());
            var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

            if (dueResponseSvc.data.interimResponse && dueResponseSvc.data.interimResponse.stream_id && dueResponseSvc.data.interimResponse.responseNeeded) {
                data.dueResponseStatus.interim.hasResponse = true;
                data.response.interimStatus = true;

            } else if (dueResponseSvc.data.interimResponse && !dueResponseSvc.data.interimResponse.responseNeeded) {
                data.dueResponseStatus.interimStatus.hasNoResponse = true;
            } else {
                data.dueResponseStatus.interimStatus.hasNotResponded = true;
            }

            // 7 Day Response
            if (dueResponseSvc.data.sevenDayResponse && dueResponseSvc.data.sevenDayResponse.stream_id && dueResponseSvc.data.sevenDayResponse.responseNeeded) {
                data.dueResponseStatus.sevenDay.hasResponse = true;   // Check for 7 day upload
                data.response.sevenDay = true;                     // Show 7 day UI elements

            } else if (diffDays <= 7 && !dueResponseSvc.data.sevenDayResponse) {
                data.dueResponseStatus.sevenDay.hasNotResponded = true;

            } else {
                data.dueResponseStatus.sevenDay.hasNoResponse = true;
            }

            // 30 Day Response
            if (dueResponseSvc.data.thirtyDayResponse && dueResponseSvc.data.thirtyDayResponse.stream_id && dueResponseSvc.data.thirtyDayResponse.responseNeeded) {
                data.dueResponseStatus.thirtyDay.hasResponse = true;   // Check for 30 day upload
                data.response.thirtyDay = true;                        // Show 30 day UI elements

            } else if (diffDays > 7 && diffDays <= 30 && !dueResponseSvc.data.thirtyDayResponse) {
                data.dueResponseStatus.thirtyDay.hasNotResponded = true;

            } else {
                data.dueResponseStatus.thirtyDay.hasNoResponse = true;
            }

            // Post-30 Day Response
            if (dueResponseSvc.data.thirtyDayPostResponse) {
                if (dueResponseSvc.data.thirtyDayPostResponse.stream_id && dueResponseSvc.data.thirtyDayPostResponse.responseNeeded) {
                    data.dueResponseStatus.postThirtyDay.hasResponse = true;   // Check for Post-30-Day upload
                    data.response.postThirtyDay = true;                        // Show Post-30-Day UI elements

                } else if (diffDays > 30 && !dueResponseSvc.data.thirtyDayPostResponse) {
                    data.dueResponseStatus.postThirtyDay.hasNotResponded = true;

                } else {
                    data.dueResponseStatus.postThirtyDay.hasNoResponse = true;
                }
            }
 
            return data;
        }

        factory.getFindingIndexFromFindingType = function (findingType, finding) {
            for (var i = 0; i < findingType.findings.length; i++) {
                var currentFinding = findingType.findings[i];
                if (finding.key === currentFinding.key)
                    return i;
            }
            return null;
        }

        factory.getFindingIndex = function (findings, findingTypeId) {
            for (var i = 0; i < findings.length; i++) {
                if (findings[i].statementFindingTypeId === findingTypeId)
                    return i;
            }
            //this will mean an error exists in the data
            return null;
        }

        factory.getSectionIndex = function (statement, section) {
            for (var i = 0; i < statement.statementDetailDtos.length; i++) {
                if (statement.statementDetailDtos[i].programId === section.programId)
                    return i;
            }
            //this will mean an error exists in the data
            return null;
        }

        function noDueResponseExists(typeId) {
            var dueResponseData = helperSvc.getFirstMatch(dueResponseSvc.data.dueResponses, 'dueResponseTypeId', typeId)
            return !dueResponseData || !dueResponseData.responseNeeded;
        }

        factory.getResponseLabel = function (propName) {
            if (propName == dueResponsePropNames.INTERIM) return "Interim Response";
            if (propName == dueResponsePropNames.SEVENDAY) return "Seven-Day Response";
            if (propName == dueResponsePropNames.THIRTYDAY) return "30-Day Due-Process Response";

            return "Post-30-Day Due-Process Response";
        };

        function getInsitutionIndices(sectionId) {
            for (var i = 0; i < factory.data.statement.statementDetailDtos.length; i++) {

                // If institution section
                if (factory.data.statement.statementDetailDtos[i].statementCategoryId == statementCategories.INSTITUTION) {
                    for (var j = 0; j < factory.data.statement.statementDetailDtos[i].statementJson.length; j++) {

                        // If selected section
                        if (factory.data.statement.statementDetailDtos[i].statementJson[j].statementFindingTypeId == sectionId) {
                            return [i, j];
                        }
                    }
                }
            }
        }

        factory.setBoilerplateText = function (text, text2) {
            var indices = getInsitutionIndices(statementFindingTypes.INSTITUTIONINTRODUCTION)

            if (indices) {
                var originalText = factory.data.statement.statementDetailDtos[indices[0]].statementJson[indices[1]].findings[0].criteria.text || '';
                if (originalText.length === 0 || originalText !== text) {
                    factory.data.statement.statementDetailDtos[indices[0]].statementJson[indices[1]].findings[0].criteria.text = text;
                }
                if (factory.data.statement.statementDetailDtos[indices[0]].statementJson[indices[1]].findings[0].criteria.text2 !== text2) {
                    factory.data.statement.statementDetailDtos[indices[0]].statementJson[indices[1]].findings[0].criteria.text2 = text2;
                }
            }
        }

        factory.setAfterReviewText = function () {
            if (!(factory.data.statement && factory.data.statement.statementDetailDtos)) return;

            const isInterimReport = factory.isInterimReport();
            const isInterimVisit = factory.isInterimVisit();
            const nonIVProgramsExist = factory.nonIVProgramsExist();

            const text = getAfterReviewText();
            var indices = getInsitutionIndices(statementFindingTypes.INSTITUTIONAFTERVISIT)

            if (indices) {
                if (factory.data.statement.statementDetailDtos[indices[0]].statementJson[indices[1]].findings[0].criteria.text !== text) {
                    factory.data.statement.statementDetailDtos[indices[0]].statementJson[indices[1]].findings[0].criteria.text = text;
                }
            }

            function getAfterReviewText() {
                var text = "<ul>"
                var institutionSectionIndex = factory.data.statement.statementDetailDtos.map(function (e) { return e.statementCategoryId; }).indexOf(1);
                var afterVisitIndex = factory.getFindingIndex(factory.data.statement.statementDetailDtos[institutionSectionIndex].statementJson, statementFindingTypes.INSTITUTIONAFTERVISIT);//get the after visit section
                var afterVisitFindingType = factory.data.statement.statementDetailDtos[institutionSectionIndex].statementJson[afterVisitIndex];

                if (!afterVisitFindingType.findings[0].criteria.responseDetails || afterVisitFindingType.findings[0].criteria.responseDetails.length < 1) {
                    createAfterVisitFindingResponseDetails();
                }
                getResponseTextDetails();

                function addListItem(label, text) {
                    return "<li><label>{0}</label> {1}</li>".format(label, text);
                }

                if ((!isInterimReport || (isInterimVisit && nonIVProgramsExist)) && factory.data.statement.statementTypeId >= statementTypeIds.DRAFT) {
                    text += addListItem('Seven-Day Response', getResponseStatus(dueResponsePropNames.SEVENDAY) + afterVisitFindingType.findings[0].criteria.responseDetails[dueResponsePropNames.SEVENDAY].responseText);
                }

                if (factory.data.statement.statementTypeId >= statementTypeIds.FINAL) {
                    text += addListItem('30-Day Due-Process Response', getResponseStatus(dueResponsePropNames.THIRTYDAY) + afterVisitFindingType.findings[0].criteria.responseDetails[dueResponsePropNames.THIRTYDAY].responseText);
                }

                if (factory.data.statement.statementTypeId >= statementTypeIds.POSTTHIRTYDAY && !noDueResponseExists(dueResponseTypeIds.THIRTYDAYPOST)) {
                    text += addListItem('Post-30-Day Due-Process Response', getResponseStatus(dueResponsePropNames.POSTTHIRTYDAY) + afterVisitFindingType.findings[0].criteria.responseDetails[dueResponsePropNames.POSTTHIRTYDAY].responseText);
                }

                text += "</ul>";

                if (text == "<ul></ul>") {
                    text = "<p>No information has been received from the institution.</p>";
                }
                return text;
            }

            function createAfterVisitFindingResponseDetails() {
                var institutionSectionIndex = factory.data.statement.statementDetailDtos.map(function (e) { return e.statementCategoryId; }).indexOf(1);
                var afterVisitIndex = factory.getFindingIndex(factory.data.statement.statementDetailDtos[institutionSectionIndex].statementJson, statementFindingTypes.INSTITUTIONAFTERVISIT);//get the after visit section
                var afterVisitFindingType = factory.data.statement.statementDetailDtos[institutionSectionIndex].statementJson[afterVisitIndex];

                if (!afterVisitFindingType.findings[0].criteria.responseDetails) {
                    afterVisitFindingType.findings[0].criteria.responseDetails = {};
                    //create all of them here
                    angular.forEach(dueResponsePropNames, function (propName) {
                        if (propName !== dueResponsePropNames.INTERIM)
                            afterVisitFindingType.findings[0].criteria.responseDetails[propName] = { responseText: '' };
                    });
                }
            }

            function getResponseTextDetails() {
                // Return string that will be appeneded to the following sentence:
                // "Information was received in the [RESPONSE LABEL] period"
                // Should start with a blank space (" ") and end with no punctuation
                // If there are no details to append return an empty string ("")

                // Single response detail should use one of the following formats:
                // Concern, weakness, or deficiency: " relative to the cited Weakness in Criterion 4 for Computer Engineering"
                // Summary, strength, or observation: " relative to a Strength for Computer Engineering" or " relative to the Institutional Summary"

                // Multiple response details should use one of the following formats:
                // One or more findings in multiple programs, no summary: " relative to the Civil Engineering, Mechanical Engineering, and Petroleum Engineering program(s)"
                // Multiple findings in one program: " relative to the Civil Engineering program"
                // One or more findings in one program, plus summary: " relative to the Institutional Summary and Civil Engineering program"
                // One or more findings in multiple programs, plus summary: " relative to the Institutional Summary, Civil Engineering, and Mechanical Engineering program(s)"

                var responseArray = { sevenDay: [], thirtyDay: [], postThirtyDay: [] }
                //need to get statementDetailIndex first so we dont run into [0] not being the institution section
                var institutionSectionIndex = factory.data.statement.statementDetailDtos.map(function (e) { return e.statementCategoryId; }).indexOf(1);
                var afterVisitIndex = factory.getFindingIndex(factory.data.statement.statementDetailDtos[institutionSectionIndex].statementJson, statementFindingTypes.INSTITUTIONAFTERVISIT);//get the after visit section
                var afterVisitFindingType = factory.data.statement.statementDetailDtos[institutionSectionIndex].statementJson[afterVisitIndex];

                for (var a = 0; a < factory.data.statement.statementDetailDtos.length; a++) {
                    var statementDetail = factory.data.statement.statementDetailDtos[a];
                    for (var b = 0; b < statementDetail.statementJson.length; b++) {
                        var statementDetailFindingType = statementDetail.statementJson[b];
                        for (var c = 0; c < statementDetailFindingType.findings.length; c++) {
                            var finding = statementDetailFindingType.findings[c];
                            if (finding.criteria && finding.criteria.response) {
                                var responses = finding.criteria.response;
                                angular.forEach(dueResponsePropNames, function (propName) {
                                    if (propName !== dueResponsePropNames.INTERIM && responses[propName] != null) {
                                        if (propName !== dueResponsePropNames.THIRTYDAY)
                                            responseArray[propName].push({ programName: factory.getProgramName(statementDetail.programId, true, null, true), programId: statementDetail.programId, criteriaId: finding.criteria.criteriaId, criteriaName: finding.criteria.criteriaName, statementFindingTypeId: statementDetailFindingType.statementFindingTypeId, statementFindingTypeName: statementDetailFindingType.statementFindingTypeName })
                                        else if (propName === dueResponsePropNames.THIRTYDAY && !noDueResponseExists(dueResponseTypeIds.THIRTYDAY))
                                            responseArray[propName].push({ programName: factory.getProgramName(statementDetail.programId, true, null, true), programId: statementDetail.programId, criteriaId: finding.criteria.criteriaId, criteriaName: finding.criteria.criteriaName, statementFindingTypeId: statementDetailFindingType.statementFindingTypeId, statementFindingTypeName: statementDetailFindingType.statementFindingTypeName })
                                    }
                                });
                            }
                        }
                    }
                }

                angular.forEach(dueResponsePropNames, function (propName) {
                    if (propName !== dueResponsePropNames.INTERIM) {
                        if (propName !== dueResponsePropNames.THIRTYDAY || (propName === dueResponsePropNames.THIRTYDAY && !noDueResponseExists(dueResponseTypeIds.THIRTYDAY)))
                            afterVisitFindingType.findings[0].criteria.responseDetails[propName].responseText = getText(responseArray[propName]);
                        else //there will be no program info added
                            afterVisitFindingType.findings[0].criteria.responseDetails[propName].responseText = "";
                    }
                });

                function getText(responses) {
                    let text = ".";
                    if (responses.length === 0) {
                        return text;
                    }

                    responses.sort(factory.statementDetailComparer);

                    const institutionFindingTypes = [statementFindingTypes.INSTITUTIONSUMMARY, statementFindingTypes.INSTITUTIONSTRENGTH];
                    const programFindingTypes = [statementFindingTypes.PROGRAMSTRENGTH, statementFindingTypes.PROGRAMOBSERVATION, statementFindingTypes.PROGRAMINTRODUCTION, statementFindingTypes.PROGRAMDEFICIENCY, statementFindingTypes.PROGRAMWEAKNESS, statementFindingTypes.PROGRAMCONCERN];

                    if (responses.length === 1) {
                        var singleResponse = responses[0];

                        if (institutionFindingTypes.includes(singleResponse.statementFindingTypeId)) {
                            text = " relative to the " + singleResponse.statementFindingTypeName + ".";
                        } else if (programFindingTypes.includes(singleResponse.statementFindingTypeId)) {
                            text = " relative to the " + singleResponse.programName + " program.";
                        }
                    } else {
                        text = " relative to the "
                        var summaryCheck, multipleSameProgramCheck = false;
                        var programNameList = [];
                        for (var i = 0; i < responses.length; i++) {
                            var responseDetail = responses[i];
                            if (institutionFindingTypes.includes(responseDetail.statementFindingTypeId)) {
                                summaryCheck = true;
                                continue;
                            }
                            if (programNameList.indexOf(responseDetail.programName) === -1) {
                                programNameList.push(responseDetail.programName);
                                continue;
                            }
                            if (programNameList.indexOf(responseDetail.programName) > -1) {
                                multipleSameProgramCheck = true;
                            }
                        }

                        if (summaryCheck) {
                            text += programNameList.length === 1 ? "Institutional Summary " : "Institutional Summary, ";
                        }
                        if (multipleSameProgramCheck && programNameList.length === 1) {//there are not multiple programs just 1 with multiple responses
                            text += summaryCheck ? 'and ' + programNameList[0] + " program." : programNameList[0] + " program.";
                        } else {
                            for (var x = 0; x < programNameList.length; x++) {
                                var programName = programNameList[x];
                                if (x === programNameList.length - 1) {
                                    text += "and " + programName + " program" + (programNameList.length === 1 ? "" : "s") + ".";
                                } else if (programNameList.length === 2 && x === 0) {
                                    text += programName + " "
                                } else {
                                    text += programName + ", "
                                }
                            }
                        }
                    }
                    return text;
                }
            }

            function getResponseStatus(propName) {//This function shouldnt be ran for every prop, do the logic exclusion outside of this.
                if (propName === dueResponsePropNames.THIRTYDAY && noDueResponseExists(dueResponseTypeIds.THIRTYDAY))
                    return "No information was received in the 30-day due-process response period.";

                if (factory.data.dueResponseStatus[propName].hasNotResponded) {
                    return "Information has not been received in the " + factory.getResponseLabel(propName).toLowerCase() + " period";
                } else if (factory.data.dueResponseStatus[propName].hasNoResponse) {
                    return "No information was received in the " + factory.getResponseLabel(propName).toLowerCase() + " period";
                } else if (factory.data.dueResponseStatus[propName].hasResponse) {
                    return "Information was received in the " + factory.getResponseLabel(propName).toLowerCase() + " period";
                }
            };

            
        }

        factory.findingsComparator = function (finding) {
            if (!finding.criteria?.criteriaName) return finding.criteria.criteriaId;
            return finding.criteria.criteriaName.toLowerCase().startsWith('general criteria for master') ||
                   finding.criteria.criteriaName.toLowerCase().startsWith('accreditation policy and procedure manual') ?
                       finding.criteria.criteriaId + 100 :
                       finding.criteria.criteriaId
        }

        factory.findingsComparer = function (a, b) {
            // Newer "Criterion MS#" master criteria come before old "General Criter for Master's" and that APPM comes last
            [[id1, key1], [id2, key2]] = [a, b].map(finding => [factory.findingsComparator(finding), finding.key]);

            return id1 && id2 ? id1 - id2 : key1 - key2;
        }

        return {
            codes: factory.codes,
            data: factory.data,
            save: factory.save,
            create: factory.create,
            submit: factory.submit,
            getByReviewTeamId: factory.getByReviewTeamId,
            getByStatementId: factory.getByStatementId,
            getInterimReviewPreviousStatement: factory.getInterimReviewPreviousStatement,
            getProgramName: factory.getProgramName,
            statementDetailComparator: factory.statementDetailComparator,
            statementDetailComparer: factory.statementDetailComparer,
            getCriteriaAreaNames: factory.getCriteriaAreaNames,
            getProgramDegreeName: factory.getProgramDegreeName,
            getFindingTypeName: factory.getFindingTypeName,
            getAllStatementToolData: factory.getAllStatementToolData,
            loadActiveData: factory.loadActiveData,
            isInstitutionSection: factory.isInstitutionSection,
            isSummarySection: factory.isSummarySection,
            getSelectedSectionAndFinding: factory.getSelectedSectionAndFinding,
            setPEVRecommendedActions: factory.setPEVRecommendedActions,
            getPEVRA: factory.getPEVRA,
            getLatestStatementByCurrentTeamMemberType: factory.getLatestStatementByCurrentTeamMemberType,
            isStatementSubmittedToInstitution: factory.isStatementSubmittedToInstitution,
            isStatementPendingSubmissionToInstitution: factory.isStatementPendingSubmissionToInstitution, 
            isFinalStatementSubmittedToInstitution: factory.isFinalStatementSubmittedToInstitution,
            isFinalStatementPendingSubmissionToInstitution: factory.isFinalStatementPendingSubmissionToInstitution,
            useCriterionType: factory.useCriterionType,
            onlyConcernForThirtyDayResponseExist: factory.onlyConcernForThirtyDayResponseExist,
            getOfflineReviews: factory.getOfflineReviews,
            removeOrphanedCommentsFromStatement: factory.removeOrphanedCommentsFromStatement,
            removeOrphanedCommentsFromFinding: factory.removeOrphanedCommentsFromFinding,
            downloadOfflineData: factory.downloadOfflineData,
            uploadOfflineData: factory.uploadOfflineData,
            syncOfflineData: factory.syncOfflineData,
            createInitialStatement: factory.createInitialStatement,
            removeOfflineData: factory.removeOfflineData,
            getStatementKey: factory.getStatementKey,
            getSubmissionStatus: factory.getSubmissionStatus,
            getLatestStatementByIsSubmitted: factory.getLatestStatementByIsSubmitted,
            hideFindingSubtitle: factory.hideFindingSubtitle,
            sectionHasText: factory.sectionHasText,
            clearAutosaving: factory.clearAutosaving,
            setDueResponseData: factory.setDueResponseData,
            deleteAllDueResponseData: factory.deleteAllDueResponseData,
            isNewProgram: factory.isNewProgram,
            isTerminationProgram: factory.isTerminationProgram,
            removeHighlightsByFinding: factory.removeHighlightsByFinding,
            highlightSingleComment: factory.highlightSingleComment,
            isProgramInterimReview: factory.isProgramInterimReview,
            getStatementSubmittedToInstitution: factory.getStatementSubmittedToInstitution,
            getFinalStatementSubmittedToInstitution: factory.getFinalStatementSubmittedToInstitution,
            isInterimReview: factory.isInterimReview,
            isShortcoming: factory.isShortcoming,
            getReviewTeamSectionText: factory.getReviewTeamSectionText,
            createNewStatementForResending: factory.createNewStatementForResending,
            weaknessOrDeficiencyExists: factory.weaknessOrDeficiencyExists,
            createFinalStatement: factory.createFinalStatement,
            createPost30DayStatement: factory.createPost30DayStatement,
            updateSubmittedToTeamMemberType: factory.updateSubmittedToTeamMemberType,
            hasNoFindings: factory.hasNoFindings,
            hasNoNegativeFindings: factory.hasNoNegativeFindings,
            getIVProgramsNotIVReviewType: factory.getIVProgramsNotIVReviewType,
            submitFinalForPost30Day: factory.submitFinalForPost30Day,
            submitCommissionEditingStatement: factory.submitCommissionEditingStatement,
            isInterimReport: factory.isInterimReport,
            isInterimVisit: factory.isInterimVisit,
            nonIVProgramsExist: factory.nonIVProgramsExist,
            getFindingIndexFromFindingType: factory.getFindingIndexFromFindingType,
            getFindingIndex: factory.getFindingIndex,
            getSectionIndex: factory.getSectionIndex,
            getResponseLabel: factory.getResponseLabel,
            setBoilerplateText: factory.setBoilerplateText,
            setAfterReviewText: factory.setAfterReviewText,
            deletePost30Statements: factory.deletePost30Statements,
            getSubmittedToTeamMemberTypeAndStatus: factory.getSubmittedToTeamMemberTypeAndStatus,
            findingsComparer: factory.findingsComparer,
            findingsComparator: factory.findingsComparator
        };
    };

    module.factory('statementStorageSvc', statementStorageSvc);

})(angular.module('statement'));