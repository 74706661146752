(function (module) {

    var pevAppValidationSvc = function (eventSvc, $q, pevAppSvc, surveyTemplateSvc, helperSvc, orgConstants, currentUser, personSvc, educationInfoSvc, employmentInfoSvc, addressTypes, contactTypes) {

        pevAppSvc.data.errors = pevAppSvc.data.errors || {};

        var factory = {};

        factory.invokeValidation = function () {
            eventSvc.broadcast('ValidatePevApp');
        };

        factory.listenToValidate = function (callback, scope) {
            eventSvc.listen(callback, 'ValidatePevApp', scope);
        };

        factory.validate = function (application) {
            var results = [];

            var serverPromise = validateFromServer(application).then(function (serverResults) {
                results.push.apply(results, serverResults);
            });

            var clientPromise = validateFromClient(application).then(function (clientResults) {
                results.push.apply(results, clientResults);
            });

            return $q.all([serverPromise, clientPromise]).then(function () {
                return results;
            });
        }

        function validateFromServer(application) {
            // Replace empty promise below with actual call to server-side validation.
            var serverResults = [];
            var deferred = $q.defer();
            promise = deferred.promise;
            deferred.resolve(serverResults);
            return promise;
        }

        function validateFromClient(application) {
            return loadAdditionalData(application).then(function (data) {
                var clientResults = []
                var personalInfoResults = validatePersonalInfo(data);
                clientResults.push.apply(clientResults, personalInfoResults);
                var societyResults = validateSocieties(data);
                clientResults.push.apply(clientResults, societyResults);
                var questionnaireResults = validateQuestionnaire(data);
                clientResults.push.apply(clientResults, questionnaireResults);
                var referenceResults = validateReferences(data);
                clientResults.push.apply(clientResults, referenceResults);
                var submitResults = validateSubmitTab(data);
                clientResults.push.apply(clientResults, submitResults);
                return clientResults;
            });
        }

        function loadAdditionalData(application) {
            var volunteerId = application.applicationJson.volunteer ?  application.applicationJson.volunteer.volunteerId : null;
            var isVolunteer = volunteerId > 0;

            // Wrap application data along with additional data needed for validation.
            var data = {
                isVolunteer: isVolunteer,
                application: application,
                personData: {},
                educationInfo: [],
                employmentInfo: [],
                requiredReferences: 0
            };

            var personObjDataSource = {
                dataHolder: this,
                dataLocationName: 'personObj',
                svcCallback: personSvc.getPersonMdlById,
                svcCallbackArguments: [application.personId, data.personData, excludeNullContactTypeIds = true],
                optionalCallback: function (scope) {
                    //remove personObj from scope because it was only used as a placeholder
                    delete scope.personObj;
                },
                optionalCallbackArguments: [this]
            };

            var educationInfoDataSource = {
                dataHolder: data,
                dataLocationName: 'educationInfo',
                svcCallback: educationInfoSvc.getByVolunteerId,
                svcCallbackArguments: [volunteerId],
                odataResource: true
            }

            var employmentInfoDataSource = {
                dataHolder: data,
                dataLocationName: 'employmentInfo',
                svcCallback: employmentInfoSvc.getByVolunteerId,
                svcCallbackArguments: [volunteerId],
                odataResource: true
            };

            var societyIds = pevAppSvc.getSocietyIds(application);

            var requiredReferencesDataSource = {
                dataHolder: data,
                dataLocationName: 'societyReferences',
                svcCallback: pevAppSvc.getNumberOfRequiredReferences,
                svcCallbackArguments: [societyIds],
                helperCallback: function (results) {
                    var arr = results.map(function (society) {
                        return society.referenceRequired;
                    });
                    var max = Math.max.apply(null, arr);
                    data.requiredReferences = max;
                    delete data.societyReferences;
                }
            }

            var dataSourceArray = [personObjDataSource];
            if (isVolunteer) {
                dataSourceArray.push(educationInfoDataSource);
                dataSourceArray.push(employmentInfoDataSource);
            }
            if (societyIds && societyIds.length > 0) {
                dataSourceArray.push(requiredReferencesDataSource);
            }

            return helperSvc.getData(dataSourceArray).then(function () {
                return data;
            });
        }       

        function validatePersonalInfo(data) {
            var application = data.application;
            var errors = [];
            var results = [];
            validatePersonalInfoAccount(data, errors, results);
            validatePersonalInfoEmployment(data, errors, results);
            validatePersonalInfoEducation(data, errors, results);
            pevAppSvc.data.errors[pevAppSvc.slugs.INFO] = errors;
            return results;
        }

        function validatePersonalInfoAccount(data, errors, results) {
            var isValid = true;
            isValid = validatePersonalInfoAccountName(data, errors) && isValid;
            isValid = validatePersonalInfoAccountEmail(data, errors) && isValid;
            isValid = validatePersonalInfoAccountPhone(data, errors) && isValid;
            
            if (!isValid) {
                results.push({ slug: pevAppSvc.slugs.INFO, message: 'General personal info contains errors.' });
            }
        }

        function validatePersonalInfoAccountName(data, errors) {
            var person = data.personData.person;
            var isValid = validateRequiredField(person, 'firstName', errors, 'First name is required under "General"');
            isValid = validateRequiredField(person, 'lastName', errors, 'Last name is required under "General"') && isValid;
            return isValid;
        }

        function validatePersonalInfoAccountAddress(data, errors) {
            var isValid = true;
            var addresses = data.personData.addresses || [];
            isValid = addresses.some(function (address) {
                return address.addressTypeId === addressTypes.HOME && address.countryCode &&
                       (address.contactTypeId === contactTypes.ALLABETACTIVITIES || address.contactTypeId === contactTypes.VOLUNTEERACTIVITYONLY);
            });
            if (!isValid) {
                errors.push('A home address with country and contact type of "All ABET Activities" or "Volunteer Activity Only" is required under "General"');
            } 
            return isValid;
         }

        function validatePersonalInfoAccountEmail(data, errors) {
            var emails = data.personData.emailAddresses || [];
            isValid = emails.some(function (email) {
                return email.emailAddress && (email.contactTypeId === contactTypes.ALLABETACTIVITIES || email.contactTypeId === contactTypes.VOLUNTEERACTIVITYONLY);
            });
            if (!isValid) {
                errors.push('Email address with contact type of "All ABET Activities" or "Volunteer Activity Only" is required under "General"');
            }
            return isValid;
        }

        function validatePersonalInfoAccountPhone(data, errors) {
            var phoneNumbers = data.personData.telephones || [];
            isValid = phoneNumbers.some(function (phoneNumber) {
                return phoneNumber.telephoneNumber && (phoneNumber.contactTypeId === contactTypes.ALLABETACTIVITIES || phoneNumber.contactTypeId === contactTypes.VOLUNTEERACTIVITYONLY);
            });
            if (!isValid) {
                errors.push('Phone number with contact type of "All ABET Activities" or "Volunteer Activity Only" is required under "General"');
            }
            return isValid;
        }
        
        function validatePersonalInfoEmployment(data, errors, results) {
            var container = data.isVolunteer ? data : (data.application.applicationJson ? data.application.applicationJson : null);
            var employmentField = data.isVolunteer ? 'employmentInfo' : 'volunteerEmployment';
            if (!validateRequiredField(container, employmentField, errors, 'At least one employment entry is required under Employment Information.')) {
                results.push({ slug: pevAppSvc.slugs.INFO, message: 'Employment Information is incomplete' });
            }
        }

        function validatePersonalInfoEducation(data, errors, results) {
            var container = data.isVolunteer ? data : (data.application.applicationJson ? data.application.applicationJson : null);
            var educationField = data.isVolunteer ? 'educationInfo' : 'volunteerEducation';
            if (!validateRequiredField(container, educationField, errors, 'At least one education entry is required under Education Information.')) {
                results.push({ slug: pevAppSvc.slugs.INFO, message: 'Education Information is incomplete' });
            }
        }

        function validateRequiredField(entity, fieldName, errors, errorMessage) {
            var isValid = entity != null && entity[fieldName] != null &&
                          (((typeof entity[fieldName]) === 'number' && entity[fieldName] > 0) || entity[fieldName].length > 0);

            if (!isValid) {
                errors.push(errorMessage);
            }

            return isValid;
        }

        function validateSocieties(data) {
            var application = data.application;
            var errors = [];
            var results = [];
            validateSocietiesMembership(application, errors, results);
            validateSocietiesProgramAreas(application, errors, results);
            pevAppSvc.data.errors[pevAppSvc.slugs.SOCIETY] = errors;
            return results;
        }

        function validateSocietiesProgramAreas(application, errors, results) {
            var applicationJson = application.applicationJson;
            var volunteerDisciplines = applicationJson ? applicationJson.volunteerDiscipline : null;
            
            var isValid = true;

            if (volunteerDisciplines == null || !Array.isArray(volunteerDisciplines.disciplines) || volunteerDisciplines.disciplines.length === 0) {
                isValid = false;
                errors.push('At least one program area is required.');
            } else {
                if (volunteerDisciplines.disciplines.some(function (volunteerDiscipline) {
                    return volunteerDiscipline == null
                })) {
                    isValid = false;
                    errors.push('One or more program areas is invalid.');
                }
               
                var listOfCommissionIds = pevAppSvc.getListOfDisciplineCommissionIdsForIEEE(pevAppSvc.data.application);               
                if (listOfCommissionIds.length > 1) {
                    isValid = false;
                    errors.push('IEEE does not allow multiple commissions for a set of program areas.');
                }
                      
            }
            
            if (!isValid) {
                results.push({ slug: pevAppSvc.slugs.SOCIETY, message: 'Program Area information is incomplete' });
            }
        }


        function validateSocietiesMembership(application, errors, results) {
            var applicationJson = application.applicationJson;
            var volunteerDiscipline = applicationJson.volunteerDiscipline || null;
            var societies = volunteerDiscipline ? volunteerDiscipline.societies || [] : [];
            var disciplines = volunteerDiscipline ? volunteerDiscipline.disciplines || [] : [];
            var memberships = applicationJson.volunteerSocietyMembership || [];
            var acm = { societyId: orgConstants.ACM, societyAbbreviatedName: "ACM" };
            var ieee_cs = { societyId: orgConstants.IEEE_CS, societyAbbreviatedName: "IEEE-CS" };
            var asa = { societyId: orgConstants.ASA, societyAbbreviatedName: "ASA" };
            var validatedDisciplineIds = [];

            var isValid = true;
            // Validate each program area is led by a society (or society with a supporting society) which pev belongs to.
            for (var index = 0; index < disciplines.length; index++) {
                var currentDiscipline = disciplines[index];
                // Keep track for when discipline is listed twice with different societies.
                if (validatedDisciplineIds.indexOf(currentDiscipline.disciplineId) > -1) {
                    continue;
                }
                validatedDisciplineIds.push(currentDiscipline.disciplineId);
                // Get societies selected or otherwise eligible to be lead for this discipline.
                var leadSocieties = societies.filter(function (society) {
                    return disciplines.some(function (discipline) {
                        return discipline.disciplineId === currentDiscipline.disciplineId && discipline.societyId === society.societyId;
                    });
                });
                // Include IEEE-CS and ACM along with CSAB. ASA also added.(5/26/2023)
                if (leadSocieties.some(function (leadSociety) { return leadSociety.societyId === orgConstants.CSAB; })) {
                    leadSocieties = [acm, ieee_cs, asa];
                }
                // See if pev is member of any of these approving societies.
                var isMember = memberships.some(function (membership) {
                    return leadSocieties.some(function (society) {
                        return society.societyId === membership.societyId;
                    });
                });
                if (!isMember) {
                    // If not a member, construct error message and add it to the list of errors.
                    isValid = false;
                    var leadSocietyNamesList = leadSocieties.map(function (leadSociety) { return leadSociety.societyAbbreviatedName; });
                    if (leadSocietyNamesList.length > 1) {
                        leadSocietyNamesList[leadSocietyNamesList.length - 1] = 'or ' + leadSocietyNamesList[leadSocietyNamesList.length - 1];
                    };
                    var leadSocietyNames = leadSocietyNamesList.sort().join(', ');
                    errors.push('Membership is required in ' + leadSocietyNames + ' for program area "' + currentDiscipline.disciplineName + '"');
                }
            }

            if (!isValid) {
                results.push({
                    slug: pevAppSvc.slugs.SOCIETY, message: 'Society Membership Information is incomplete'
                });
            }
        }

        function validateQuestionnaire(data) {
            var application = data.application;
            var results = [];

            var errors = surveyTemplateSvc.validateSurvey(application.surveyJson);             

            pevAppSvc.data.errors[pevAppSvc.slugs.QUESTIONNAIRE] = errors;
            if (!application.surveyJson || !application.surveyJson.groups || errors.length > 0) {
                results.push({ slug: pevAppSvc.slugs.QUESTIONNAIRE, message: 'Questionnaire is incomplete' });
            }

            return results;
        }

        function validateReferences(data) {
            var application = data.application;
            var references = application.referenceJson;
            var applicationJson = application.applicationJson || {};
            var errors = [];
            var results = [];
            var isMissingReferences = false;
            var hasEmptyFields = false;

            if (data.requiredReferences > 0) {
                if (references.length === 0) {
                    isMissingReferences = true;
                } else {
                    var sequence = 1;
                    angular.forEach(references, function (reference) {
                        var emptyFields = [];
                        if (reference.personName == null || reference.personName.length === 0) {
                            emptyFields.push('name');
                        }
                        if (reference.email == null || reference.email.length === 0) {
                            emptyFields.push('email');
                        }
                        if (reference.employer == null || reference.employer.length === 0) {
                            emptyFields.push('organization');
                        }
                        if (emptyFields.length === 3) {
                            errors.push(convertNumberToPosition(sequence) + ' reference is not specified.');
                            isMissingReferences = true;
                        } else if (emptyFields.length > 0) {
                            if (emptyFields.length > 2) {
                                emptyFields[missingFielemptyFieldsds.length - 1] = 'and ' + emptyFields[emptyFields.length - 1];
                            }
                            errors.push(convertNumberToPosition(sequence) + ' reference is missing ' + emptyFields.join(', '));
                            hasEmptyFields = true;
                        }
                        ++sequence;
                    });
                }
            }

            pevAppSvc.data.errors[pevAppSvc.slugs.REFERENCES] = errors;

            if (isMissingReferences) {
                results.push({ slug: pevAppSvc.slugs.REFERENCES, message: 'One or more required references are missing.' });
            }
            if (hasEmptyFields) {
                results.push({ slug: pevAppSvc.slugs.REFERENCES, message: 'One or more references are incomplete.' });
            }

            return results;
        }

        function convertNumberToPosition(sequence) {            
            var lastDigit = parseInt(sequence.toString().slice(-1));
            switch (lastDigit) {
                case 1:
                    return sequence + 'st';
                case 2:
                    return sequence + 'nd';
                case 3:
                    return sequence + 'rd';
                default:
                    return sequence + 'th';
            }
        }

        function validateSubmitTab(data) {
            var application = data.application;
            var errors = [];
            var results = [];

            if (application.stream_id == null) {
                var errorMessage = 'Uploaded CV or r\xE9sum\xE9 file is required.';
                errors.push(errorMessage);
                results.push({ slug: pevAppSvc.slugs.SUBMIT, message: errorMessage });
            }

            pevAppSvc.data.errors[pevAppSvc.slugs.SUBMIT] = errors;

            return results;
        }

        factory.populateWarnings = function (navigation) {
            factory.validate(pevAppSvc.data.application).then(function (results) {
                for (var i = 0; i < navigation.length; i++) {
                    navigation[i].warningMessages = [];
                }

                for (var i = 0; i < results.length; i++) {
                    var slug = results[i].slug;

                    for (var j = 0; j < navigation.length; j++) {
                        var tab = navigation[j];

                        if (tab.slug === slug) {
                            tab.warningMessages.push(results[i].message);
                        }
                    }
                }
            });
        }

        return {
            invokeValidation: factory.invokeValidation,
            listenToValidate: factory.listenToValidate,
            validate: factory.validate,
            populateWarnings: factory.populateWarnings
        };
    };

    module.factory('pevAppValidationSvc', pevAppValidationSvc);

})(angular.module('pevApp'));