(function (module) {

    var pevAssignmentToolCtrl = function ($scope, $stateParams, assignmentSvc, pevAssignmentSvc, alertSvc, $filter, $timeout, onsiteAvailabilityTypes) {
        var model = this;

        model.title = 'PEV Assignment Tool';
        model.isDataReady = false;
        model.showMatrix = false;
        model.currentSocietyId = $stateParams.societyId ? parseInt($stateParams.societyId) : null;
        model.filteredReviewPrograms = [];
        model.filteredVolunteers = [];
        model.onsiteAvailabilityTypes = onsiteAvailabilityTypes;
        model.programAreas = [];
        model.hasResults = {
            left: false,
            right: false,
            all: false
        };

        model.open = [];

        model.reviewProgramFilters = pevAssignmentSvc.filters.reviewProgramFilters; 
        model.volunteerFilters = pevAssignmentSvc.filters.volunteerFilters; 

        let programAreasChanged = false;
        let approvedProgramAreasChanged = false;

        model.onProgramAreasChange = function () {
            programAreasChanged = true;
        }

        model.onProgramAreasClose = function () {
            // Do nothing if program areas didn't change (i.e. use clicked close button without selecting a program area)
            if (!programAreasChanged)
                return;
            programAreasChanged = false;
            // Do nothing if program areas and volunteer approved program areas already match
            if (model.reviewProgramFilters.programAreas.every(programArea =>
                programArea.disciplineName.toLowerCase().contains('general criteria') ||
                model.volunteerFilters.programAreas.some(volunteerProgramArea => volunteerProgramArea.disciplineId === programArea.disciplineId)) &&
                model.volunteerFilters.programAreas.every(volunteerProgramArea =>
                    model.reviewProgramFilters.programAreas.some(programArea => programArea.disciplineId === volunteerProgramArea.disciplineId)))
                return;
            // Prompt user before changing if volunteer filter selections were made that differ from program filter selections
            if (approvedProgramAreasChanged) {
                alertSvc.confirm('Program area filters are different from volunteer approved program area fitlers.  Do you want to overwrite "Approved Program Areas" under "Select Volunteers" to match "Program Areas" under "Select Programs"?', overwrite);
            } else {
                overwrite();
            }

            function overwrite() {
                approvedProgramAreasChanged = false;
                // User made no changes to volunteer approved program areas or confirmed overwrite
                model.volunteerFilters.programAreas = model.data.pevProgramAreaOptions.filter(pevProgamAreaOption =>
                    model.reviewProgramFilters.programAreas.some(programArea => programArea.disciplineId === pevProgamAreaOption.disciplineId)
                );
            }
        }

        model.onApprovedProgramAreasChange = function () {
            approvedProgramAreasChanged = true;
        }

        model.getFilteredReviewPrograms = function () {
            var reviewProgramsFilter = $filter('reviewPrograms');

            model.filteredReviewPrograms = reviewProgramsFilter(model.data.reviewPrograms, model.reviewProgramFilters);
            model.hasResults.left = true;

            if (model.hasResults.left && model.hasResults.right) model.hasResults.all = true;
        };

        model.getFilteredVolunteers = function () {
            var volunteersFilters = $filter('volunteers');

            model.filteredVolunteers = volunteersFilters(model.data.pevs, model.volunteerFilters);
            model.hasResults.right = true;

            if (model.hasResults.left && model.hasResults.right) model.hasResults.all = true;
        };

        model.assignPEV = function (volunteer, teamIndex) {
            assignmentSvc.assign(volunteer, assignmentSvc.assignmentTypes.PEV, model.selectedPrograms[teamIndex], model.selectedVolunteers);
        };

        model.assignObserver = function (volunteer, teamIndex) {
            assignmentSvc.assign(volunteer, assignmentSvc.assignmentTypes.OBSERVER, model.selectedPrograms[teamIndex], model.selectedVolunteers);
        };

        model.unassign = function (volunteer, teamMemberTypeId, teamIndex) {
            assignmentSvc.unassign(volunteer, teamMemberTypeId, model.selectedPrograms[teamIndex], model.selectedVolunteers);
        }

        activate();

        function activate() {
            assignmentSvc.addBodyClass();

            pevAssignmentSvc.resetFilters();

            pevAssignmentSvc.loadAllData(model.currentSocietyId).then(function () {
                model.data = pevAssignmentSvc.data;
                model.isDataReady = true;
            });
        }

        assignmentSvc.removeBodyClassOnPageChange($scope);
    };

    module.controller('pevAssignmentToolCtrl', pevAssignmentToolCtrl);

}(angular.module('assignment')));