(function (module) {

    var upcomingCountryAvailabilityEditCtrl = function ($scope, $stateParams, $uibModalInstance, countries, parent, helperSvc, upcomingAvailabilitySvc, alertSvc, availabilityTypes) {
        var model = $scope;
        model.parent = parent;
        model.volunteerId = parseInt($stateParams.volunteerId);
        model.currentYear = parent.currentYear;
        model.title = 'Edit Country Availability';
        model.countries = countries;
        model.errors = [];
        model.availabilityTypes = availabilityTypes;
        model.usOnlyAvailabilityType = availabilityTypes.SOME + '1';
        model.someSelectionAvailabilityType = availabilityTypes.SOME + '2';

        model.save = function () {
            helperSvc.validateForm(validateCountryAvailability);

            if (model.errors.length > 0) return;

            updateCountryAvailability();
            resetErrors();
        };

        model.selectNoneOrAll = function () {
            resetErrors();
            model.selectedInformation.volunteerAvailabilityCountryList = [];
        };

        //different because we could implement a future input of theyre previously selected countries
        model.selectSomeAvailable = function () {
            resetErrors();
            model.selectedInformation.volunteerAvailabilityCountryList = [];
        };

        model.selectUSOnly = function () {
            resetErrors();
            model.selectedInformation.volunteerAvailabilityCountryList = model.countries.filter(function (country) { return country.countryCode.toLowerCase() !== 'us'; });
        };

        model.close = function () {
            $uibModalInstance.close();
        };

        model.delete = function (country) {
            var index = model.selectedInformation.volunteerAvailabilityCountryList.indexOf(country);
            model.selectedInformation.volunteerAvailabilityCountryList.splice(index, 1);
        };

        // "US Availability Only" and "Some Countries Unavailable" are two versions of the same availabilityTypeId that have different UIs. To handle showing/hiding in the template...
        // "US Availability Only" has a '1' appended to its availabilityTypeId
        // "Some Countries Unavailable" has a '2' appended to its availabilityTypeId
        model.hasSomeAvailability = function () {
            return (model.selectedInformation.availabilityTypeId === model.usOnlyAvailabilityType) || (model.selectedInformation.availabilityTypeId === model.someSelectionAvailabilityType);
        }

        model.activate = function () {
            reset();
            resetErrors();

            if (model.selectedInformation.availabilityTypeId === model.availabilityTypes.SOME) {
                model.selectedInformation.availabilityTypeId = (model.selectedInformation.volunteerAvailabilityCountryList.length == model.countries.length - 1) ? model.usOnlyAvailabilityType : model.someSelectionAvailabilityType;
            }
        }();

        
        function reset() {
            model.selectedInformation = {};

            if (upcomingAvailabilitySvc.data.countryData) {
                var countryData = upcomingAvailabilitySvc.data.countryData;

                model.selectedInformation.availabilityTypeId = countryData.availabilityTypeId || 0;
                model.selectedInformation.volunteerAvailabilityCountryList = angular.copy(countryData.volunteerAvailabilityCountryListDtos) || [];

            } else {
                model.selectedInformation.availabilityTypeId = 0;
                model.selectedInformation.volunteerAvailabilityCountryList = [];
            }

            model.countryAvailabilityId = upcomingAvailabilitySvc.data.countryData.volunteerAvailabilityCountryId || 0;
        }

        function validateCountryAvailability() {
            resetErrors();

            // Reset "US Availability Only" and "Some Countries Unavailable" options to their correct, shared availabilityTypeId
            if (model.hasSomeAvailability()) model.selectedInformation.availabilityTypeId = model.availabilityTypes.SOME;

            if (model.selectedInformation.availabilityTypeId === 0) {
                model.errors.push('Please select your availability.');
            }
            if (model.selectedInformation.availabilityTypeId === model.availabilityTypes.SOME && model.selectedInformation.volunteerAvailabilityCountryList.length < 1) {
                model.errors.push('Please select your list of unavailable countries or select all available.');
            }
        }

        function updateCountryAvailability() {
            var volunteerAvailabilityCountryDto = {
                volunteerId: model.volunteerId,
                year: model.currentYear,
                volunteerAvailabilityCountryId: model.countryAvailabilityId,
                availabilityTypeId: model.selectedInformation.availabilityTypeId
            };

            if (model.countryAvailabilityId === 0) {
                
                volunteerAvailabilityCountryDto.volunteerAvailabilityCountryListDtos = convertCountriesToUnavailableCountryObject(model.selectedInformation.volunteerAvailabilityCountryList, 0);

                upcomingAvailabilitySvc.createCountryAvailability(volunteerAvailabilityCountryDto).then(function () {
                    alertSvc.addAlertSuccess('Country availability updated.');
                    model.close();
                });

            } else {
                volunteerAvailabilityCountryDto.volunteerAvailabilityCountryListDtos = convertCountriesToUnavailableCountryObject(model.selectedInformation.volunteerAvailabilityCountryList, volunteerAvailabilityCountryDto.volunteerAvailabilityCountryId);

                upcomingAvailabilitySvc.updateCountryAvailability(volunteerAvailabilityCountryDto).then(function () {
                    alertSvc.addAlertSuccess('Country availability updated.');
                    model.close();
                });
            }
        }

        function convertCountriesToUnavailableCountryObject(countries, id) {
            convertedCountries = [];

            angular.forEach(countries, function (value, key) {
                convertedCountries.push({ volunteerAvailabilityCountryId: id, countryCode: value.countryCode, countryName: value.countryName})
            });

            return convertedCountries;
        }

        function resetErrors() {
            model.errors = [];
        }
    };

    module.controller('upcomingCountryAvailabilityEditCtrl', upcomingCountryAvailabilityEditCtrl);

}(angular.module('volunteer')));