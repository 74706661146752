(function (module) {
    'use strict';

    var rrAdminSvc = function ($http, $q, odataSvc, amsConst, $odata) {
        var factory = {};
        var searchApi = '/SearchReadinessReviews';
        var exportApi = '/GetReadinessExportList';

        factory.advancedSearch = function (searchOptions) {
            var oSvc = odataSvc.get();
            
            var hasRequestYear = searchOptions.requestYear ? true : false;
            var hasStatusId = searchOptions.statusId ? true : false;
            //var hasCommissions = searchOptions.commissions ? searchOptions.commissions.length > 0 : false;
            var hasLocation = searchOptions.location ? true : false;
            var hasOrganizationName = searchOptions.organizationName ? true : false;

            var hasSearchOptions = hasRequestYear || hasStatusId || hasLocation || hasOrganizationName;

            if (hasSearchOptions) {
                var predicates = [];
                var combinedPredicate;

                if (hasRequestYear)
                    predicates.push(new $odata.Predicate('requestYear', '=', searchOptions.requestYear));
                if (hasStatusId)
                    predicates.push(new $odata.Predicate('readinessStatusId', '=', searchOptions.statusId));
                if (hasOrganizationName)
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'organizationName', searchOptions.organizationName)), '>', -1));
                //if (hasCommissions) {
                //var combinedCommissionPredicate = [];
                     
                //for(var i=0; i < searchOptions.commissions.length; i++){
                //    combinedCommissionPredicate.push(new $odata.Predicate('commissionId', '=', parseInt(searchOptions.commissions[i].codeKey)))
                //}

                //if (combinedCommissionPredicate.length > 0) {
                //    predicates.push($odata.Predicate.or(combinedCommissionPredicate))
                //}
                //}
                if (hasLocation) {
                if (parseInt(searchOptions.location) === 0) {
                    predicates.push(new $odata.Predicate('countryCode', 'eq', 'US'));
                } else {
                    predicates.push(new $odata.Predicate('countryCode', 'ne', 'US'));
                }
                }

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(searchApi).odata()
                    .filter(combinedPredicate)
                    .orderBy("organizationName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(searchApi).odata()
                    .orderBy("organizationName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            return oSvc.getDeferred().promise;
        };

        factory.exportList = function (searchOptions) {
            var oSvc = odataSvc.get();

            var hasRequestYear = searchOptions.requestYear ? true : false;
            var hasStatusId = searchOptions.statusId ? true : false;
            //var hasCommissions = searchOptions.commissions ? searchOptions.commissions.length > 0 : false;
            var hasLocation = searchOptions.location ? true : false;
            var hasOrganizationName = searchOptions.organizationName ? true : false;

            var hasSearchOptions = hasRequestYear || hasStatusId || hasLocation || hasOrganizationName;

            if (hasSearchOptions) {
                var predicates = [];
                var combinedPredicate;

                if (hasRequestYear)
                    predicates.push(new $odata.Predicate('requestYear', '=', searchOptions.requestYear));
                if (hasStatusId)
                    predicates.push(new $odata.Predicate('latestStatusId', '=', searchOptions.statusId));
                if (hasOrganizationName)
                    predicates.push(new $odata.Predicate((new $odata.Func('indexof', 'institutionName', searchOptions.organizationName)), '>', -1));
                //if (hasCommissions) {
                //var combinedCommissionPredicate = [];

                //for(var i=0; i < searchOptions.commissions.length; i++){
                //    combinedCommissionPredicate.push(new $odata.Predicate('commissionId', '=', parseInt(searchOptions.commissions[i].codeKey)))
                //}

                //if (combinedCommissionPredicate.length > 0) {
                //    predicates.push($odata.Predicate.or(combinedCommissionPredicate))
                //}
                //}
                if (hasLocation) {
                    if (parseInt(searchOptions.location) === 0) {
                        predicates.push(new $odata.Predicate('countryCode', 'eq', 'US'));
                    } else {
                        predicates.push(new $odata.Predicate('countryCode', 'ne', 'US'));
                    }
                }

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(exportApi).odata()
                    .filter(combinedPredicate)
                    .orderBy("institutionName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(exportApi).odata()
                    .orderBy("institutionName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            return oSvc.getDeferred().promise;
        };

         return {
             advancedSearch: factory.advancedSearch,
             exportList: factory.exportList
         };
     };

     module.factory('rrAdminSvc', rrAdminSvc);

})(angular.module('readinessReview'));