
(function (module) {

    var orgConstants = {
        CSAB: 1208,
        IEEE_CS: 1184,
        IEEE: 1074,
        ACM: 1232,
        ASA: 1842,
        ABET: 1235,
        ABET_BOD: 1236,
        ABET_BOD_DELEGATE: 1572
    };
    module.constant('orgConstants', orgConstants);

    var organizationTypes = {
        ABET: 1,
        INSTITUTION: 2,
        MEMBERSOCIETY: 3,
        ASSOCIATESOCIETY: 4,
        COMMISSION: 5,
        BOARDOFDIRECTORS: 6,
        COMMITTEE: 7,
        EXECUTIVECOMMITTEE: 8,
        STATEBOARD: 9,
        USREGIONALAGENCY: 10,
        NONUSREGIONALAGENCY: 11,
        OTHER: 12,
        READINESSREVIEWUSERGROUP : 13,
        SUPPORTINGSOCIETY: 14
    }
    module.constant('organizationTypes', organizationTypes);

    var telephoneTypes = {
        WORK: 1,
        HOME: 2,
        MOBILE: 3,
        OTHER: 4
    }
    module.constant('telephoneTypes', telephoneTypes);

    var addressTypes = {
        WORK: 1,
        HOME: 2,
        OTHER: 3
    }
    module.constant('addressTypes', addressTypes);

    var noteTypes = {
        PERSON: 1,
        ORGANIZATION: 2,
        REVIEW: 3,
        PROGRAM: 4,
        TRANSCRIPTSTATUS: 5,
        VOLUNTEER: 6,
        READINESS: 7
    }
    module.constant('noteTypes', noteTypes);

    var noteCategories = {
        INFO: 1,
        ALERT: 2
    }
    module.constant('noteCategories', noteCategories);

    var teamMemberTypes = {
        TEAMCHAIRS: [2, 3, 4],
        PEVS: [1],
        EDITORS: [5, 6],
        OBSERVERS: [7, 8, 9]
    }
    module.constant('teamMemberTypes', teamMemberTypes);

    var teamMemberStatusesFor = {
        Pev: [2, 3, 4, 5, 6, 7, 8, 9],
        TeamChair: [1, 4, 5, 7, 8, 9, 10]
    }
    module.constant('teamMemberStatusesFor', teamMemberStatusesFor);
    
    var teamMemberTypeNames = {
        PEV: 1,
        TEAMCHAIR: 2,
        COTEAMCHAIR: 3,
        REPORTTEAMCHAIR: 4,
        EDITOR1: 5,
        EDITOR2: 6,
        PROGRAMOBSERVER: 7,
        VISITOBSERVER: 8,
        VISITSTATEOBSERVER: 9,
        ADJUNCT: 10,
        ADMIN: 11,
        NPCREVIEWER: 12,
        NPCFVREVIEWER: 13,
        NPCEXCOM: 14,
        NPCFVADJ:15

    }
    module.constant('teamMemberTypeNames', teamMemberTypeNames);

    var teamMemberTypeIds = {
        1: "Program Evaluator",
        2: "Visit Team Chair",
        3: "Visit Co-Team Chair",
        4: "Report Team Chair",
        5: "Editor 1",
        6: "Editor 2",
        7: "Program Observer",
        8: "Visit Observer",
        9: "Visit State Observer",
        10: "Adjunct",
        11: 'Admin',
        12: 'NPC Reviewer',
        13: 'NPC FV Reviewer',
        14: 'NPC Excom',
        15: 'NPC FV Adjunct'
    }
    module.constant('teamMemberTypeIds', teamMemberTypeIds);

    var teamMemberTypeAbbrvIds = {
        1: "PEV",
        2: "TC",
        3: "CO-TC",
        4: "RC",
        5: "ED1",
        6: "ED2",
        7: "OBS",
        8: "OBS",
        9: "OBS",
        10: "ADJ",
        11: 'ADM',
        12: 'NPC'

    }
    module.constant('teamMemberTypeAbbrvIds', teamMemberTypeAbbrvIds);

    var teamMemberStatusTypes = {
        PENDINGTCASSIGNMENT: 1,
        PENDINGPEVASSIGNMENTTCACCEPT: 2,
        PENDINGPEVASSIGNMENTINSTITUTION: 3,
        APPROVEDBYINSTITUTIONWAITINGFORVOLUNTEERACCEPT: 4,
        ASSIGNMENTAPPROVED: 5,
        DECLINEDBYTC: 6,
        DISAPPROVEDBYTC: 7,
        DECLINEDBYVOLUNTEER: 8,
        ASSIGNMENTCANCELLEDBYASSIGNOR: 9
    }
    module.constant('teamMemberStatusTypes', teamMemberStatusTypes);

    var commissionTypes = [
        { id: 1, abrv: 'ANSAC', name: 'Applied and Natural Science Accreditation Commission' },
        { id: 2, abrv: 'CAC', name: 'Computing Accreditation Commission' },
        { id: 3, abrv: 'EAC', name: 'Engineering Accreditation Commission' },
        { id: 4, abrv: 'ETAC', name: 'Engineering Technology Accreditation Commission' }
    ];
    module.constant('commissionTypes', commissionTypes);

    var commissionAbbreviations = {
        1: 'ANSAC',
        2: 'CAC',
        3: 'EAC',
        4: 'ETAC'
    }
    module.constant('commissionAbbreviations', commissionAbbreviations);

    var commissionIds = {
        ANSAC: 1,
        CAC: 2,
        EAC: 3,
        ETAC: 4
    };
    module.constant('commissionIds', commissionIds);

    var jointCommissions = [
        ['EAC', 'CAC'],
        ['ANSAC', 'EAC'],
        ['ANSAC', 'ETAC'],
        ['ETAC', 'CAC']
    ]
    module.constant('jointCommissions', jointCommissions);

    var commissionIndexes = {
        ANSAC: 0,
        CAC: 1,
        EAC: 2,
        ETAC: 3
    };
    module.constant('commissionIndexes', commissionIndexes);

    var designationTypes = {

        COMMISSION: 1,
        PROGRAM: 2,
        PERSON: 3
    }
    module.constant('designationTypes', designationTypes);

    var changeTypeCategories = {

        ACCREDITATIONDATES: 1,
        DISTANCELEARNING: 2,
        DEGREEDESIGNATION: 3,
        CAMPUSLOCATION: 4
    }
    module.constant('changeTypeCategories', changeTypeCategories);

    // Note: the values below appear to actually be RoleID's, not RoleTypeID's, and used as such in the app
    var contactRoleTypes = {

        ABETSTAFF: 7,
        PRESIDENT: 100,
        PROVOST: 101,
        DELEGATEFORPRESIDENT: 102,
        PRIMARY: 110,
        SECONDARY: 111,
        DELEGATEFORPRIMARY: 112,
        PROGRAM: 120,
        SECONDARYPROGRAM: 121,
        BILLING: 130,
        REGISTRAR: 131,
        STAFF: 132,
        PROGRAMEVALUATOR: 140,
        PROGRAMOBSERVER: 141,
        TEAMCHAIR: 142,
        COTEAMCHAIR: 143,
        EDITOR1: 144,
        EDITOR2: 145,
        VISITOBSERVER: 150,
        READINESSREVIEWER: 160,
        NPCREVIEWER: 171,
        NPCTEAMCHAIR: 172,
        NPCEXCOMEDITOR: 173,
        EDUCATIONLIASON: 200,
        MEMBERPRESIDENT: 202,
        TRAININGMANAGER: 203,
        PEVRECRUITINGMANAGER: 204,
        MEMBERPROGRAMEVALUATOR: 210,
        MEMBERPROGRAMEVALUATORCANDIDATEINACTIVE: 211,
        MEMBERPROGRAMEVALUATORCANDIDATE: 212,
        PEVASSIGNOR: 220,
        OTHERMEMBERSOCIETY: 230,
        OTHERASSOCIATESOCIETY: 254,
        CHAIR: 300,
        PASTCHAIR: 302,
        COMMISSIONMEMBER: 303,
        VICECHAIROFOPERATIONS: 306,
        ABETPRESIDENT: 400,
        STATEBOARDLIAISON: 700,
        OTHERSTATEBOARD: 701
    };
    module.constant('contactRoleTypes', contactRoleTypes);

    var abetRoles = {
        ABETSTAFF: 7,
        ITDIRECTOR: 10,
        ITPERSONNEL: 11,
        ACCREDITATIONDIRECTOR: 21,
        ACCREDITATIONADJUNCTDIRECTOR: 22,
        ACCREDITATIONADMIN: 23,
        HEADOFACCREDITATIONADJUNCTDIRECTOR: 24,
        ACCOUNTINGDIRECTOR: 30,
        ACCOUNTINGADMIN: 31,
        OWNER: 41,
        ADMINRECOMMENDEDACTIONAUDIT: 51,
        ADJUNCTRECOMMENDEDACTIONAUDIT: 52
    };
    module.constant('abetRoles', abetRoles);

    var itRoles = {
        ITDIRECTOR: 10,
        ITPERSONNEL: 11
    };
    module.constant('itRoles', itRoles);

    var rfrProcessStatuses = {
        WAITINGFORSUBMISSIONTOABET: 1,
        WAITINGFORABETPREREVIEW: 2,
        WAITINGFORPRESIDENTSIGNATURE: 3,
        WAITINGFORABETACCEPTANCE: 4,
        ABETACCEPTED: 5,
        WAITINGFORREVISEDRFRSUBMISSION: 6,
        PROJECTEDRFR: 7,
        CANCELLED: 8,
    }
    module.constant('rfrProcessStatuses', rfrProcessStatuses);

    var programReviewTypes = {
        BR: 'BOD Focused Report',
        GR: 'Comprehensive Visit',
        IGR: 'Initial Accreditation',
        IR: 'Focused Report',
        IV: 'Focused Visit',
        SC: 'Focused Show Cause Visit',
        SCR: 'Show Cause Report',
        SCV: 'Show Cause Visit',
        TR: 'Termination Report',
        TV: 'Termination Visit',
        XR: 'ExCom Focused Report',
        XV: 'ExCom Focused Visit'
    }
    module.constant('programReviewTypes', programReviewTypes);

    var programReviewTypeIds = {
        BODFOCUSED: 'BR',
        COMPREHENSIVEVISIT: 'GR',
        INITIALACCREDIATION: 'IGR',
        FOCUSEDREPORT: 'IR',
        FOCUSEDVISIT: 'IV',
        FOCUSEDSHOWCAUSE: 'SC',
        SHOWCAUSEREPORT: 'SCR',
        SHOWCAUSEVISIT: 'SCV',
        TERMINATIONREPORT: 'TR',
        TERMINATIONVISIT: 'TV',
        TERMINATION: 'T',
        EXCOMFOCUSEDREPORT: 'XR',
        EXCOMFOCUSEDVISIT: 'XV'
    }
    module.constant('programReviewTypeIds', programReviewTypeIds);

    var programCampusTypes = [
        { programCampusTypeId: 1, programCampusTypeName: 'Main Location Only' },
        { programCampusTypeId: 2, programCampusTypeName: 'Main and Other Location(s)' },
        { programCampusTypeId: 3, programCampusTypeName: 'Other Location(s) Only' },
        { programCampusTypeId: 4, programCampusTypeName: 'Online Only' }
    ];
    module.constant('programCampusTypes', programCampusTypes);

    var programCampusTypeIds = {
        MAINLOCATIONONLY: 1,
        MAINANDOTHERLOCATIONS: 2,
        OTHERLOCATIONSONLY: 3,
        ONLINEONLY: 4
    }
    module.constant('programCampusTypeIds', programCampusTypeIds);

    var rfrProgramRequestTypes = {
        RENEWAL: 'Renewal',
        NEWPROGRAM: 'New Program',
        CHANGENAME: 'Change Name Request',
        TERMINATEPROGRAM: 'Program Termination',
        TERMINATEACCREDITATION: 'Accreditation Termination',
        TERMINATEOPTION1: 'Terminate Request (Extension) [Deprecated]',
        TERMINATEOPTION2: 'Terminate Request (Phase Out) [Deprecated]',
        TERMINATEOPTION3: 'Terminate Request (Lapse) [Deprecated]',
        TERMINATION: 'Termination'
    }
    module.constant('rfrProgramRequestTypes', rfrProgramRequestTypes);

    var degreeLevels = {
        A: 'Associate Degree',
        B: 'Bachelor Degree',
        M: 'Master Degree'
    }
    module.constant('degreeLevels', degreeLevels);

    var availabilityTypes = {
        ALL: 1,
        NONE: 2,
        SOME: 3,
    }
    module.constant('availabilityTypes', availabilityTypes);

    var messageTemplateTypes = {
        RFRSTATECHANGE: 1,
        FORGOTUSERNAME: 2,
        REQUESTUSERPASSWORD: 3,
        RFRPREREVIEWREQUEST: 4,
        RFRREQUESTPRESIDENTSIGNATURE: 5,
        RFRREQUESTABETACCEPTANCE: 6,
        RFRACCEPTED: 7,
        RFRRETURNEDTOCOMMISSION: 8,
        TCASSIGNMENTRELEASED: 9,
        TCASSIGNMENTAPPROVED: 10,
        VISITDATECHANGED: 11,
        VISITDATECONFIRMED: 37,
        PEVASSIGNMENTRELEASED: 12,
        PEVASSIGNMENTAPPROVEDBYTC: 13,
        PEVASSIGNMENTREJECTEDBYTC: 14,
        PEVASSIGNMENTAPPROVEDBYIU: 15,
        PEVACCEPTED: 16,
        PEVCANCELLED: 17,
        REQUESTNEWACCOUNT: 19,
        RRRESULT: 20,
        RRNOTREQUIRED: 21,
        RRWAITINGFORSUBMISSION: 22,
        RRSUBMISSIONCONFIRMATION: 33,
        RRACCEPTED: 34,
        PEVAPPCONFIRMSUBMISSION: 27,
        PEVAPPSOCIETYNOTIFICATION: 28,
        PEVAPPREFERENCERESPONSENOTIFICATION: 29,
        CREATEDRFEFORNEWINSTITUTIONCOMMISSION: 31,
        SELFSTUDYSUBMITTED: 35,
        STATEMENTSUBMITTED: 39,
        STATEMENTRETURNED: 40,
        STATEMENTDUERESPONSE: 41,
        COMMISSIONERNOMINATIONSSUBMITTED: 46,
        PEVCREGISTERED: 47,
        MENTORASSIGNMENT: 50,
        PEVAPPROVEDFORVISIT: 51,
        STATEMENTTHIRTYDAYRESPONSEUPLOADLATER: 52,
        STATEMENTTHIRTYDAYRESPONSESUBMITTED: 53,
        STATEMENTTHIRTYDAYRESPONSENOTTOSUBMIT: 56,
        PEVCACCEPTEDTRAININGFORSOCIETY: 66
    };
    module.constant('messageTemplateTypes', messageTemplateTypes);

    var contactTypes = {
        ALLABETACTIVITIES: 1,
        VOLUNTEERACTIVITYONLY: 2,
        INSTITUTIONCONTACTONLY: 3,
        SOCIETYCONTACTONLY: 4
    };
    module.constant('contactTypes', contactTypes);

    var collegeVerificationTypes = {
        INITIALACCREDITATION: 1,
        VOLUNTEEREDUCATION: 2,
        VOLUNTEEREMPLOYMENT: 3,
        VOLUNTEEROTHERCONFLICT: 4
    };
    module.constant('collegeVerificationTypes', collegeVerificationTypes);

    var taskTypes = {
        USER: 'user',
        ADMIN: 'admin',
        RFR: 'rfr',
        CONTACTS: 'contacts',
        ORGANIZATION: 'organization',
        VOLUNTEER: 'volunteer',
        TCASSIGNMENT: 'tc assignment',
        MANAGEVOLUNTEER: 'manage volunteer'
    };
    module.constant('taskTypes', taskTypes);

    var volunteerDataAccessTypes = {
        ADMINONLY: 1,
        SOCIETYANDADMIN: 2,
        VOLUNTEERANDADMIN: 3,
        SOCIETYLIAISONANDADMIN: 4,
        SOCIETYLIASONANDTRNMGRANDADMIN: 5
    };
    module.constant('volunteerDataAccessTypes', volunteerDataAccessTypes);

    var reviewTypes = {
        GR: 'General Review',
        INIT: 'Initial Review',
        IR: 'Interim Report',
        IV: 'Interim Visit',      
    }
    module.constant('reviewTypes', reviewTypes);

    var reviewTypeIds = {
        GENERALREVIEW: 'GR',
        INITIALREVIEW: 'INIT',
        INTERIMREPORT: 'IR',
        INTERIMVISIT: 'IV'
    }
    module.constant('reviewTypeIds', reviewTypeIds);

    var documentCategories = {
        RFR: 1,
        ORGANIZATION: 2,
        READINESS_REVIEW: 3
    }
    module.constant('documentCategories', documentCategories);

    var documentTypes = {
        TRANSCRIPT: { documentTypeId: 1, documentTypeName: 'Transcript', documentCategoryId: 1 },
        FINAL_STATEMENT: { documentTypeId: 2, documentTypeName: 'Historical Final Statement', documentCategoryId: 2 },
        DRAFT_STATEMENT_SENT_TO_INSTITUTION: { documentTypeId: 3, documentTypeName: 'Statement - Sent to Institution', documentCategoryId: 4 },
        FINAL_STATEMENT_SENT_TO_INSTITUTION: { documentTypeId: 4, documentTypeName: 'Final Statement Sent to Institution', documentCategoryId: 4 },
        STATEMENT_MESSAGE_DEANS_LETTER: { documentTypeId: 5, documentTypeName: 'Statement - Deans Letter', documentCategoryId: 4 },
        STATEMENT_MESSAGE_PRESIDENT_LETTER: { documentTypeId: 6, documentTypeName: 'Statement - President Letter', documentCategoryId: 4 },
        STATEMENT_MESSAGE_EXECUTIVE_LETTER: { documentTypeId: 7, documentTypeName: 'Statement - Executive Letter', documentCategoryId: 4 }
    };
    module.constant('documentTypes', documentTypes);

    var readinessStatuses = {
        WAITING_FOR_ABET_ACKNOWLEDGEMENT: 1,
        WAITING_FOR_READINESS_REVIEW_SUBMISSION: 2,
        SUBMITTED_WAITING_FOR_ACCEPTANCE: 3,
        REVIEW_IN_PROGRESS: 4,
        INFORMED_REVIEW_RESULT: 5,
        WITHDRAWN_BY_INSTITUTION: 6,
        CANCELLED_BY_ABET: 7,
        READINESS_REVIEW_NOT_REQUIRED: 8,
        READINESS_REVIEW_ACCEPTED: 9
    }
    module.constant('readinessStatuses', readinessStatuses);

    var readinessStatusNames = [
        { readinessStatusId:1 , statusName: 'Waiting for ABET acknowledgement', },
        { readinessStatusId:2 , statusName: 'Waiting for Readiness Review Submission'},
        { readinessStatusId:3 , statusName: 'Submitted/Waiting for ABET Acceptance'},
        { readinessStatusId: 4, statusName: 'Review in Progress' },
        { readinessStatusId: 5, statusName: 'Informed Review Result' },
        { readinessStatusId: 6, statusName: 'Withdrawn by institution' },
        { readinessStatusId: 7, statusName: 'Cancelled by ABET' },
        { readinessStatusId: 8, statusName: 'Readiness Review Not Required' },
        { readinessStatusId: 9, statusName: 'Readiness Review Accepted' }
    ]
    module.constant('readinessStatusNames', readinessStatusNames);

    var reviewProcessStatuses = [
        { reviewProcessStatusId: 1, statusName: 'Pending TC Assignment', orderNumber: 1 },
        { reviewProcessStatusId: 2, statusName: 'Pending PEV Assignment', orderNumber: 2 },
        { reviewProcessStatusId: 3, statusName: 'Site Visit/Report Evaluation', orderNumber: 3 },
        { reviewProcessStatusId: 4, statusName: 'Sent Draft Statement to Institution', orderNumber: 4 },
        { reviewProcessStatusId: 5, statusName: 'Sent Final Statement to Institution', orderNumber: 5 }
    ];
    module.constant('reviewProcessStatuses', reviewProcessStatuses);

    var reviewProcessStatus = {
        PENDING_TC_ASSIGNMENT: 1,
        PENDING_PEV_ASSIGNMENT: 2,
        SITE_VISIT_REPORT_EVALUATION: 3,
        SENT_DRAFT_STATEMENT_TO_INSTITUTION: 4,
        SENT_FINAL_STATEMENT_TO_INSTITUTION: 5
    };
    module.constant('reviewProcessStatus', reviewProcessStatus);

    var surveyTemplateTypes = {
        TEAMCHAIR_EVALUATION_OF_PEV: 1,     
        PEV_EVALUATION_OF_TEAMCHAIR: 2,
        PEER_EVALUATION_OF_PEV:3,
        EDITOR_EVALUATION_OF_TEAMCHAIR:4,
        DEAN_EVALUATION_OF_TEAMCHAIR: 5,
        DEPARTMENTHEAD_EVALUATION_OF_PEV:6,
        PEVAPPLICATION: 7,
        PEVREFERENCE: 8,
        PEER_EVALUATION_OF_TC: 9
    }
    module.constant('surveyTemplateTypes', surveyTemplateTypes);

    var appraisalTypes = {
        TEAMCHAIR_APPRAISAL_OF_PEV:1,
        PEV_APPRAISAL_OF_TEAMCHAIR:2,
        PEV_APPRAISAL_OF_PEV:3,
        EDITOR_APPRAISAL_OF_TEAMCHAIR:4,
        DEANS_APPRAISAL_OF_TEAMCHAIR:5,
        DEPARTMENTHEAD_APPRAISAL_OF_PEV: 6,
        TC_APPRAISAL_OF_TC: 7
    }
    module.constant('appraisalTypes', appraisalTypes)

    var pevApplicationStatuses = {
        WAITING_FOR_SUBMISSION: 1,
        SUBMITTED: 2,
        CANCELED_BY_PEV: 3,
        CANCELED_BY_ABET: 4
    }
    module.constant('pevApplicationStatuses', pevApplicationStatuses)

    var pevApplicationSocietyStatuses = {
        WAITING_FOR_SOCIETY_APPROVAL: 1,
        APPROVED_FOR_TRAINING: 2,
        APPROVED_FOR_OBSERVER_VISIT_ONLY: 3,
        APPROVED_FOR_VISIT: 4,
        DISAPPROVED_BY_SOCIETY: 5, 
        WITHDRAWN_BY_VOLUNTEER: 6,
        CANCELLED_BY_ABET: 7,
        DISSAPROVED_BY_VISIT_SOCIETY: 8,
        DEFERRED: 9
    }
    module.constant('pevApplicationSocietyStatuses', pevApplicationSocietyStatuses)

    var pevDisciplineStatuses = {
        APPROVED: 1,
        DISAPPROVED: 2,
        PENDING: 3,
        INACTIVE: 4
    }
    module.constant('pevDisciplineStatuses', pevDisciplineStatuses)

    var pevApplicationSocietyStatusName = [
        { statusId: 1, statusName: 'Waiting for Society Approval' },
        { statusId: 2, statusName: 'Approved for Training' },
        { statusId: 3, statusName: 'Approved for Observer Visit Only' },
        { statusId: 4, statusName: 'Approved for Visit' },
        { statusId: 5, statusName: 'Disapproved by Society' },
        { statusId: 6, statusName: 'Withdrawn by Volunteer' },
        { statusId: 7, statusName: 'Cancelled by ABET' },  
        { statusId: 8, statusName: 'Dissaproved by Visit Society' },   
        { statusId: 9, statusName: 'Deferred' },   
 
    ]
    module.constant('pevApplicationSocietyStatusName', pevApplicationSocietyStatusName)


    var pevApplicationDisciplineStatusName = [
        { statusId: 1, statusName: 'Approved' },
        { statusId: 2, statusName: 'Disapproved' },
        { statusId: 3, statusName: 'Pending' },
        { statusId: 4, statusName: 'Inactive' },
    ]
    module.constant('pevApplicationDisciplineStatusName', pevApplicationDisciplineStatusName)

    // In Dec 2019 options 1–3 were deprecated and options 4 and 5 were added
    var terminationOptions = {
        EXTENSION: 1,
        PHASEOUT: 2,
        LAPSE: 3,
        PROGRAMTERMINATION: 4,
        ACCREDITATIONTERMINATION: 5
    };
    module.constant('terminationOptions', terminationOptions)

    var criteriaTypes = {
        BACKGROUND: 1,
        CRITERIA: 2,
        INTERIMREPORT: 3,
        APPENDIX: 4,
        TRANSCRIPT: 5,
        OPTIONAL: 6,
        ADDITIONAL: 7,
        TERMINATIONPLAN: 8,
        MASTERSGENERALCRITERIA: 9,
        APPM: 10,
        SELFSTUDY: 11
    };
    module.constant('criteriaTypes', criteriaTypes)

    var selfStudyUploadOptionTypes = {
        UNINITIALIZED: 0,
        SINGLEDOCUMENT: 1,
        MULTIPLEDOCUMENTS: 2
    };
    module.constant('selfStudyUploadOptionTypes', selfStudyUploadOptionTypes)

    var statementFindingTypes = {
        INSTITUTIONINTRODUCTION: 1,
        INSTITUTIONAFTERVISIT: 2,
        INSTITUTIONSUMMARY: 3,
        INSTITUTIONSTRENGTH: 4,
        PROGRAMINTRODUCTION: 5,
        PROGRAMSTRENGTH: 6,
        PROGRAMDEFICIENCY: 7,
        PROGRAMWEAKNESS: 8,
        PROGRAMCONCERN: 9,
        PROGRAMOBSERVATION: 10,
        PROGRAMSUMMARY: 11, 
        TERMINATIONPLAN: 12,
        REVIEWTEAM: 13
    }
    module.constant('statementFindingTypes', statementFindingTypes);


 var statementFindingTypeNames = {
        1: 'Introduction & Discussion of Statement Construct',
        2: 'Information Received After the Review',
        3: 'Institutional Summary',
        4: 'Institutional Strength',
        5: 'Introduction',
        6: 'Program Strength',
        7: 'Program Deficiency',
        8: 'Program Weakness',
        9: 'Program Concern',
        10: 'Program Observation',
        11: 'Summary',
        12: 'Termination Plan',
        13: 'Review Team'

    }
    module.constant('statementFindingTypeNames', statementFindingTypeNames);

    var statementStatuses = {
        WAITINGFORTCSUBMIT: 1,
        WAITINGFORED1SUBMIT: 2,
        WAITINGFORED2SUBMIT: 3,
        WAITINGFORADJSUBMIT: 4,
        ADMINPREPFORSEND: 5,
        SENTTOINSTITUTION: 6,
        RESENTTOINSTITUTION: 7,
        FINALSTATEMENTCOMPLETE: 8
    }
    module.constant('statementStatuses', statementStatuses);

 var statementStatusNames = { //int is the statementStauts iD
        1: 'Waiting for TC Submission',
        2: 'Waiting for ED1 Submission',
        3: 'Waiting for ED2 Submission',
        4: 'Waiting for ADJ Submission',
        5: 'Admin Preparing to Send to Institution',
        6: 'Sent to Institution',
        7: 'Resent to Institution',
        8: 'Final Statement Complete'
    }
    module.constant('statementStatusNames', statementStatusNames);

    var statementTypeNames = {
        DRAFT: 'Draft',
        FINAL: 'Final',
        POST30DAY: 'Post-30-Day',
        COMMISSIONEDITING: 'Commission Editing'
    }
    module.constant('statementTypeNames', statementTypeNames);

    var statementTypeIds = {
        DRAFT: 1,
        FINAL: 2,
        POSTTHIRTYDAY: 3,
        COMMISSIONEDITING: 4
    }
    module.constant('statementTypeIds', statementTypeIds);

    var statementTypeNamesById = {
        1: 'Draft',
        2: 'Final',
        3: 'Post-30-Day',
        4: 'Commission Editing'
    }
    module.constant('statementTypeNamesById', statementTypeNamesById);

    var actionCodes = {
        IGR: 'IGR',
        NGR: 'NGR',
        IR: 'IR',
        IV: 'IV',
        NA: 'NA',
        SC: 'SC',
        SCR: 'SCR',
        SCV: 'SCV',
        VE: 'VE',
        RE: 'RE',
        SE: 'SE',
        T: 'T',
        W: 'W',
        C: 'C'
    }
    module.constant('actionCodes', actionCodes);

    var programReviewActionCodes = {
        CANCELLED: 'C',
        WITHDRAWN: 'W',
    }
    module.constant('programReviewActionCodes', programReviewActionCodes);

    var programReviewActionNames = {
        C: 'Cancelled',
        W: 'Withdrawn'
    }
    module.constant('programReviewActionNames', programReviewActionNames);


    var dueResponseTypeIds = {
        SEVENDAY: 1,
        INTERIM: 2,
        THIRTYDAY: 3,
        THIRTYDAYPOST: 4
    }
    module.constant('dueResponseTypeIds', dueResponseTypeIds);

    const dueResponseStatusIds = {
        NO_RESPONSE: 1,
        UPLOAD_LATER: 2,
        UPLOAD_NOW: 3
    };
    module.constant('dueResponseStatusIds', dueResponseStatusIds);

    var dueResponseNames = {
        1: '7-Day Response',
        2: 'Interim',
        3: '30-Day Response',
        4: 'Post-30-Day Response'
    }
    module.constant('dueResponseNames', dueResponseNames);

    var dueResponsePropNames = {
        SEVENDAY: 'sevenDay',
        INTERIM: 'interimStatus',
        THIRTYDAY: 'thirtyDay',
        POSTTHIRTYDAY: 'postThirtyDay'
        
    }
    module.constant('dueResponsePropNames', dueResponsePropNames);
    
    var dueResponsePropNamesByTypeId = {
        1: 'sevenDay',
        2: 'interimStatus',
        3: 'thirtyDay',
        4: 'postThirtyDay'
    }
    module.constant('dueResponsePropNamesByTypeId', dueResponsePropNamesByTypeId);

    var findingStatusTypeIds = {
        RESOLVED: 1,
        REMAINS: 2,
        CHANGED: 3
    };
    module.constant('findingStatusTypeIds', findingStatusTypeIds);

    var serviceWorkerCommands = {
        GO_OFFLINE: "goOffline",
        GO_ONLINE: "goOnline"
    }
    module.constant('serviceWorkerCommands', serviceWorkerCommands);

    var monthNames = ["January", "February", "March", "April", "May", "June",
                    "July", "August", "September", "October", "November", "December"];
    module.constant('monthNames', monthNames);

    var criteriaIncluded = {
        ALL: 1,
        UNDERGRAD: 2,
        MASTERSONLY: 3
    };
    module.constant('criteriaIncluded', criteriaIncluded);

    var statementTypeLabel = {
        1: "D",
        2: "F",
        3: "30",
        4: "C"
    };
    module.constant('statementTypeLabel', statementTypeLabel);

    module.constant('READINESS_REVIEW_DATES', {
        START: new Date((new Date).getFullYear(), 2, 1), //March 1
        END: new Date((new Date).getFullYear(),9,1) //October 1
    });

    var statementCategories = {
        INSTITUTION: 1,
        PROGRAM: 2,
        SUMMARY: 3
    };
    module.constant('statementCategories', statementCategories);

    var trainingStatusIds = {
        INVITED: 1,
        REGISTERED: 2,
        ATTENDED: 3,
        CANCELLED: 4,
        NOTATTENDED: 5,
        DEFERRED: 6
    };
    module.constant('trainingStatusIds', trainingStatusIds);

    var trainingStatusNames = {
        1: "Invited",
        2: "Registered",
        3: "Attended",
        4: "Cancelled",
        5: "Not Attended",
        6: "Deferred"
    };
    module.constant('trainingStatusNames', trainingStatusNames);

    var trainingTypeIds = {
        PEVCTRAINING: 1,
        PEVCRECERT: 2
    };
    module.constant('trainingTypeIds', trainingTypeIds);

    var trainingTypeNames = {
        1: 'PEVC Training',
        2: 'PEV Recertification Training'
    };
    module.constant('trainingTypeNames', trainingTypeNames);

    const shortcomingTypeCodes = {
        DEFICIENCY: 'D',
        WEAKNESS: 'W',
        CONCERN: 'C'
    }
    module.constant('shortcomingTypeCodes', shortcomingTypeCodes);

    const npcTypes = {
        CHANGE: 'Change',
        TERMINATION: 'Termination'
    }
    module.constant('npcTypes', npcTypes);

    const npcStatusNames = {
        1: 'Pending Submission',
        2: 'Notification Submitted(Waiting for ABET Acceptance)',
        3: 'Accepted Submitted Notification',
        4: 'Sent Acknowlegement without Commission Review',
        5: 'Commission Reviewing Submitted Notification',
        6: 'Sent Final Action to Institution after Commission Review',
        7: 'Conducting Focused Visit',
        8: 'Sent Final Action to Institution After Focused Visit'
    }
    module.constant('npcStatusNames', npcStatusNames);

    const npcStatusIds = {
        PENDINGSUBMISSION: 1,
        NOTIFICATIONSUBMITTED: 2,
        ACCEPTEDNOTIFICATION: 3,
        SENTACKWITHOUTCOMMISSIONREVIEW: 4,
        COMMISSIONREVIEWING: 5,
        SENTFINALACTIONAFTERCOMMISSION: 6,
        FOCUSEDVISIT: 7,
        SENTFINALACTIONAFTERVISIT: 8
    }
    module.constant('npcStatusIds', npcStatusIds);

    const npcChangeTypeIds = {
        CRITERIA: 1,
        NAME: 2,
        DISTANCE: 3,
        CAMPUS: 4,
        TERMINATION: 5
    }
    module.constant('npcChangeTypeIds', npcChangeTypeIds);

    const npcDocumentTypes = {
        NPC: 8,
        TERMINATIONPLAN: 9,
        NPCFINALACTIONSTATEMENT: 10

    }
    module.constant('npcDocumentTypes', npcDocumentTypes);

    var npcSectionTypes = {
        INTRODUCTION: 1,
        SUMMARYOFCHANGES: 2,
        MATERIALSREVIEWED: 3,
        FINDINGS: 4,
        RECOMMENDATION: 5,
        TERMINATIONPLAN: 6,
        INSTITUTION: 7,
        PROGRAM: 8,
        SUMMARY: 9
    }
    module.constant('npcSectionTypes', npcSectionTypes);

    var npcSectionTypeNames = {
        1:	'Introduction',
        2:	'Summary of Changes',
        3:	'Materials Reviewed',
        4:	'Findings',
        5:	'Recommendation',
        6: 'Termination Plan',
        7: 'Institution',
        8: 'Program',
        9: 'Summary'
    }
    module.constant('npcSectionTypeNames', npcSectionTypeNames);

    var npcReportTypes = {
        REVIEWREPORT: 'Review',
        STATEMENT: 'Statement'
    }
    module.constant('npcReportTypes', npcReportTypes);

    var npcFinalActionTypeNames = {
        1: 'Continue Accreditation',
        2: 'Conduct a Focused Visit',
        3: 'APPM requirements for termination have been met.',
        4: 'IR',
        5: 'IV',
        6: 'SCR',
        7: 'SCV'
    }
    module.constant('npcFinalActionTypeNames', npcFinalActionTypeNames);

    var npcFinalActionTypeIds = {
        CONTINUEACCREDITATION: 1,
        CONDUCTAFOCUSEDVISIT: 2,
        TERMINATIONREQUIREMENTSMET: 3,
        NPCIR: 4,
        NPCIV: 5,
        NPCSCR: 6,
        NPCSCV: 7
    }
    module.constant('npcFinalActionTypeIds', npcFinalActionTypeIds);

    var onsiteVisitExpectations = {
        ALLOWED: 1,
        AllowedConditionally: 2,
        NotAllowed: 3
    }
    module.constant('onsiteVisitExpectations', onsiteVisitExpectations);

    var onsiteVisitExpectationNames = {
        1: "Allowed",
        2: "Allowed Conditionally",
        3: "Not Allowed"
    }
    module.constant('onsiteVisitExpectationNames', onsiteVisitExpectationNames);

    var onsiteAvailabilityTypes = [
        { id: 1, type: 'Yes, both US and non-US' },
        { id: 2, type: 'Yes, US only' },
        { id: 3, type: 'Yes, non-US only' },
        { id: 4, type: 'No' }
    ]
    module.constant('onsiteAvailabilityTypes', onsiteAvailabilityTypes);

    var onsiteVisitRestrictionStatuses = {
        WITHOUTRESTRICTION: 1,
        WITHRESTRICTION: 2,
        VIRTUALVISIT: 3,
        UNSURE: 4,
        CANCELLED: 5
    }
    module.constant('onsiteVisitRestrictionStatuses', onsiteVisitRestrictionStatuses);

    var personMediaTypes = {
        SIGNATURE: 1,
        PORTRAIT: 2
    }
    module.constant('personMediaTypes', personMediaTypes);

    var personMediaTypeNames = {
        1: "Signature",
        2: "Portrait"
    }
    module.constant('personMediaTypeNames', personMediaTypeNames);

}(angular.module("common")));