(function (module) {

    var trainingSvc = function (odataSvc, helperSvc, $http, amsConst, $odata, trainingTypeIds, contactRoleTypes, oauth, trainingStatusIds, alertSvc) {
        var factory = {};
        var key = 'trainingSessionId';
        var trainingPath = '/Training';
        var trainingPersonPath = '/TrainingPerson';
        var trainingPersonKey = 'trainingPersonId'
        var trainingMentorPath = '/TrainingMentor';
        var trainingFacilitatorPath = '/TrainingFacilitator'
        var trainingSessionPath = '/TrainingSession'
        var trainingPersonFacilitatorPath = '/TrainingPersonFacilitator'
        var trainingPersonFacilitatorKey = 'trainingPersonFacilitatorId'
        var trainingMentorKey = 'trainingMentorId';
        var trainingFacilitatorKey = 'trainingFacilitatorId';
        var trainingMenteePath = '/TrainingMentee';
        var trainingMenteeKey = 'trainingMenteeId';
        var onSuccess = function (response) {
            if (response.status == 200) {
                return response.data;
            }
        };

        var onFailure = function (response) {
            console.error(response.status);
            return $q.reject(response);
        };

        factory.data = {
            trainingSessions: [],
            mySocieties: [],
            trainingFacilitatorList: [],
            mentorList: [],
            pevcs: [],
            observerList: [],
            trainingPersonList: []
        }

        factory.getMyTrainingRoles = function(){
            var mySocieties = [];
            mySocieties = oauth.getOrganizationsByUserRoleId(contactRoleTypes.EDUCATIONLIASON).map(function(organization){
                return organization.organizationId;
            });
            mySocieties = mySocieties.concat(oauth.getOrganizationsByUserRoleId(contactRoleTypes.TRAININGMANAGER).map(function(organization){
                return organization.organizationId;
            }));
            mySocieties = mySocieties.concat(oauth.getOrganizationsByUserRoleId(contactRoleTypes.PEVRECRUITINGMANAGER).map(function(organization){
                return organization.organizationId;
            }));

            factory.data.mySocieties = mySocieties;

            return mySocieties;
        }

        factory.getTrainings = function (searchOptions, noStoredData) {
            var oSvc = odataSvc.get();
            var trainingByUserPath = '/GetTrainingByUser';

            if (searchOptions.year || searchOptions.typeId) {
                var predicates = [];
                var combinedPredicate;

                if (searchOptions.year)
                    predicates.push(new $odata.Predicate('trainingYear', '=', searchOptions.year));

                if (searchOptions.typeId)
                    predicates.push(new $odata.Predicate('trainingTypeId', '=', parseInt(searchOptions.typeId)));

                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(trainingByUserPath).odata()
                    .filter(combinedPredicate)
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            } else {
                oSvc.getSource(trainingByUserPath).odata()
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            if (!noStoredData) {
                resolveData(oSvc, 'trainingList');
            }
            return oSvc.getDeferred().promise;
        };

        factory.getPEVCTrainingSessions = function (trainingYear, noStoredData) {
            var oSvc = odataSvc.get();

            var apiPath = oSvc.getPathWithParameter('/GetPEVCTrainingSessions', 'trainingYear', trainingYear)

            oSvc.getSource(apiPath, key).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveData(oSvc, 'trainingSessions');
            }

            return oSvc.getDeferred().promise;
        };

        factory.getPEVCTrainingSessionsAdmin = function (trainingId, noStoredData) {//may need to add case to include trainingTypeId query 
            var oSvc = odataSvc.get();

            var apiPath = oSvc.getPathWithParameter('/GetPEVCTrainingSessionsForAdmin', 'trainingId', trainingId)

            oSvc.getSource(apiPath, key).odata().query(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveData(oSvc, 'trainingSessions');
            }

            return oSvc.getDeferred().promise;
        };

        factory.getPEVCTrainingPersons = function (trainingSessionId, noStoredData) {
            var oSvc = odataSvc.get();

            var apiPath = oSvc.getPathWithParameter('/GetPEVCTrainingPersons', 'trainingSessionId', trainingSessionId)

            oSvc.getSource(apiPath, key).odata()
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getPevcTrainingsByPersonId = function (personId, noStoredData) {
            var oSvc = odataSvc.get();

            var apiPath = oSvc.getPathWithParameter('/GetPevcTrainingsByPersonId', 'personId', personId)

            oSvc.getSource(apiPath, key).odata()
                .filter('trainingStatusId', '=', trainingStatusIds.ATTENDED)    
                .query(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.getCurrentUsersTrainingPersonDto = function(){
            var oSvc = odataSvc.get();

            var apiPath = "/GetCurrentUsersTrainingPersonDto";

            oSvc.getSource(apiPath).odata()
                    .query(oSvc.onArrayToSingleSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.addTraining = function (trainingDto) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingPath, null, trainingDto);
            var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
            })
            return promise;
        }

        factory.updateTraining = function (trainingViewDto) {

            var oSvc = odataSvc.get();

            var trainingDto = {
                trainingId: trainingViewDto.trainingId,
                trainingName: trainingViewDto.trainingName,
                trainingTypeId: trainingViewDto.trainingTypeId,
                trainingYear: trainingViewDto.trainingYear,
                autoImportFlag: trainingViewDto.AutoImportFlag
            };

            var resource = oSvc.instantiate(trainingPath, 'trainingId', trainingDto);
            var promise = resource.$update(oSvc.onSuccess, oSvc.onFailure).then(function () {
            });

            return promise;
        }

        factory.deleteTraining = function (trainingDto) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingPath, 'trainingId', trainingDto);
            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
            });
            return promise;
        }

        factory.addSession = function (trainingSessionDto) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingSessionPath, null, trainingSessionDto);
            var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
            })
            return promise;
        }

        factory.updateSession = function (trainingSessionDto) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingSessionPath, 'trainingSessionId', trainingSessionDto);
            var promise = resource.$update(oSvc.onSuccess, oSvc.onFailure).then(function () {
            });
            return promise;
        }

        factory.deleteSession = function (trainingSessionDto) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingSessionPath, 'trainingSessionId', trainingSessionDto);
            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
            });
            return promise;
        }

        factory.addMentees = function(trainingMentorId, personIds){
            var apiPath = amsConst.webApiUrl + '/odata/AddMentees';
            var data = { "trainingMentorId": trainingMentorId, "personIds": personIds };

            return $http.post(apiPath, data).then(function (response) {

            }).catch(function (error) {
                console.log('Error while submitting', error);
            });

        }

        factory.addTrainingMentor = function(trainingMentorDto){
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingMentorPath, trainingMentorKey, trainingMentorDto);

            var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
            //do something or prob nothing
            })

            return promise;
        }

        factory.addTrainingFacilitator = function (trainingFacilitatorDto) {
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingFacilitatorPath, trainingFacilitatorKey, trainingFacilitatorDto);

            var promise = resource.$save(null, oSvc.onSuccess, oSvc.onFailure).then(function () {
            })

            return promise;
        }

        factory.updateTrainingFacilitator = function (trainingFacilitatorDto) {
            var oSvc = odataSvc.get();

            var resource = oSvc.instantiate('/TrainingFacilitator', 'trainingFacilitatorId', trainingFacilitatorDto);
            var promise = resource.$update(oSvc.onSuccess, oSvc.onFailure).then(function () {
            });

            return oSvc.getDeferred().promise;
        }

        factory.addPersonFacilitators = function (trainingFacilitatorId, trainingPersonIds) {
            var apiPath = amsConst.webApiUrl + '/odata/AddTrainingPersonFacilitators';
            var data = { "trainingFacilitatorId": trainingFacilitatorId, "trainingPersonIds": trainingPersonIds };

            return $http.post(apiPath, data).then(function (response) {

            }).catch(function (error) {
                console.log('Error while submitting', error);
            });

        }

        factory.addTrainingObserver = function (trainingObserverDto) {
            var apiPath = amsConst.webApiUrl + '/odata/AddTrainingObserver';
            //var trainingPerson = factory.cleanPevcFromSearch(trainingObserverDto);

            var data = { "dto": trainingObserverDto }; //this is really a trainingpersonDTO

            return $http.post(apiPath, data).then(function (response) {

            }).catch(function (error) {
                console.log('Error while submitting', error);
            });
        }

        factory.deleteTrainingObserver = function (trainingPersonId) {
            var apiPath = amsConst.webApiUrl + '/odata/DeleteTrainingObserver';
            var data = { "trainingPersonId": trainingPersonId }; //this is really a trainingpersonId

            return $http.post(apiPath, data).then(function (response) {

            }).catch(function (error) {
                console.log('Error while submitting', error);
            });
        }

        factory.deleteTrainingPerson = function (trainingPersonId) {
            var model = { trainingPersonId: trainingPersonId };
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingPersonPath, trainingPersonKey, model);
            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
            });

            return promise;
        }

        factory.deleteMentor = function(mentorId){
            var model = { trainingMentorId: mentorId };
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingMentorPath, trainingMentorKey, model);
            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
            });

            return promise;
        }

        factory.deleteFacilitator = function (facilitatorId) {
            var model = { trainingFacilitatorId: facilitatorId };
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingFacilitatorPath, trainingFacilitatorKey, model);
            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
            });

            return promise;
        }

        factory.deleteMentee = function(menteeId){//possibly batched?
            var model = { trainingMenteeId: menteeId };
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingMenteePath, trainingMenteeKey, model);
            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
            });

            return promise;
        }

        factory.deletePersonFacilitator = function (personFacilitatorId) {
            var model = { trainingPersonFacilitatorId: personFacilitatorId };
            var oSvc = odataSvc.get();
            var resource = oSvc.instantiate(trainingPersonFacilitatorPath, trainingPersonFacilitatorKey, model);
            var promise = resource.$delete(oSvc.onSuccess, oSvc.onFailure).then(function () {
            });

            return promise;
        }


        factory.updateTrainingSession = function(trainingPersonDto){
            var apiPath = amsConst.webApiUrl + '/odata/UpdateTrainingPerson';
            var trainingPerson = factory.cleanPevcFromSearch(trainingPersonDto);
            
            var data = { "trainingPerson": trainingPerson };

            return $http.post(apiPath, data).then(function (response) {
               // return factory.getCurrentUsersTrainingPersonDto();
            }).catch(function (error) {
                console.log('Error while submitting', error);
            });
            
        }

        factory.getPEVCTrainingSearch = function (searchOptions, noStoredData) {
            var oSvc = odataSvc.get();
            var apiPath = "/GetPEVCTrainingSearch";
            var hasTrainingYear = searchOptions.trainingYear ? true : false;
            var hasSociety = searchOptions.societyId ? true : false;
            var hasCommission = searchOptions.commissionId ? true : false;
            var hasTrainingSession = searchOptions.trainingSessionId ? true : false;
            var hasSearchOptions = hasTrainingYear || hasSociety || hasTrainingSession;

            if(hasSearchOptions){
                var predicates = [];
                var combinedPredicate;

                if (hasTrainingYear)
                    predicates.push(new $odata.Predicate('trainingYear', '=', searchOptions.trainingYear));

                if (hasSociety) {
                    var societyPreds = [];
                    societyPreds.push(new $odata.Predicate('societyId', '=', searchOptions.societyId));
                    societyPreds.push(new $odata.Predicate('applicationSocietyId', '=', searchOptions.societyId));
                    
                    predicates.push($odata.Predicate.or(societyPreds));
                }

                if (hasCommission) {
                    predicates.push(new $odata.Predicate('commissionId', '=', searchOptions.commissionId));
                }

                if (hasTrainingSession)
                    predicates.push(new $odata.Predicate('trainingSessionId', '=', searchOptions.trainingSessionId));

                predicates.push(new $odata.Predicate('trainingTypeId', '=', trainingTypeIds.PEVCTRAINING));
                
                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(apiPath).odata()
                    .filter(combinedPredicate)                  
                    .orderBy("lastName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);

            }else {
                oSvc.getSource(apiPath).odata()
                    .orderBy("lastName")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }

            if (!noStoredData) {
                resolveData(oSvc, 'pevcs');
            }

            return oSvc.getDeferred().promise;
        }

        factory.getTrainingMentors = function (searchOptions, noStoredData) {
            var oSvc = odataSvc.get();
            var apiPath = "/GetTrainingMentors";
            var hasTrainingYear = searchOptions.trainingYear ? true : false;
            var hasSociety = searchOptions.societyId ? true : false;
            var hasSearchOptions = hasTrainingYear || hasSociety;

            if(hasSearchOptions){
                var predicates = [];
                var combinedPredicate;

                if (hasTrainingYear)
                    predicates.push(new $odata.Predicate('trainingYear', '=', searchOptions.trainingYear));

                if(hasSociety)
                    predicates.push(new $odata.Predicate('societyId', '=', searchOptions.societyId));
                
                if (predicates.length > 0) {
                    combinedPredicate = $odata.Predicate.and(predicates);
                }

                oSvc.getSource(apiPath).odata()
                    .filter(combinedPredicate)
                    .expand("personDto")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);

            }else {
                oSvc.getSource(apiPath).odata()
                    .expand("personDto")
                    .query()
                    .$promise
                    .then(oSvc.onSuccess, oSvc.onFailure);
            }
            
            if (!noStoredData) {
                resolveData(oSvc, 'trainingMentorList');
            }

           return oSvc.getDeferred().promise;
        }

        factory.getTrainingFacilitators = function (searchOptions, noStoredData) {
            var oSvc = odataSvc.get();

            var predicates = [];
            var combinedPredicate;

            predicates.push(new $odata.Predicate('trainingId', '=', searchOptions.trainingId));

            if (searchOptions.commissionId)
                predicates.push(new $odata.Predicate('commissionId', '=', searchOptions.commissionId));

            if (searchOptions.trainingSessionId)
                predicates.push(new $odata.Predicate('trainingSessionId', '=', searchOptions.trainingSessionId));

            combinedPredicate = $odata.Predicate.and(predicates);

            oSvc.getSource(trainingFacilitatorPath).odata()
                .filter(combinedPredicate)
                .expand("personDto")
                .expand("trainingSessionDto")
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveData(oSvc, 'trainingFacilitatorList');
            }

            return oSvc.getDeferred().promise;
        }

        factory.getTrainingObservers = function (searchOptions, noStoredData) {
            var oSvc = odataSvc.get();
            var apiPath = "/GetObserverTrainingSearch";
            var predicates = [];
            var combinedPredicate;

            //predicates.push(new $odata.Predicate('trainingSessionDto/trainingId', '=', searchOptions.trainingId));

            //if (searchOptions.commissionId)
            //    predicates.push(new $odata.Predicate('commissionId', '=', searchOptions.commissionId));

            if (searchOptions.trainingSessionId)
                predicates.push(new $odata.Predicate('trainingSessionId', '=', searchOptions.trainingSessionId));

            if (searchOptions.trainingYear)
                predicates.push(new $odata.Predicate('trainingYear', '=', searchOptions.trainingYear));

            if (searchOptions.trainingId)
                predicates.push(new $odata.Predicate('trainingId', '=', searchOptions.trainingId));

            combinedPredicate = $odata.Predicate.and(predicates);

            oSvc.getSource(apiPath).odata()
                .filter(combinedPredicate)
                //.expand("personDto")
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveData(oSvc, 'trainingObserverList');
            }

            return oSvc.getDeferred().promise;

        }

        factory.getOtherTrainingPersons = function (searchOptions, noStoredData) {
            var oSvc = odataSvc.get();
            var apiPath = oSvc.getPathWithParameter('/GetOtherTrainingPersonSearch', 'trainingId', searchOptions.trainingId)

            oSvc.getSource(apiPath).odata()
                .query(oSvc.onSuccess, oSvc.onFailure)

            if (!noStoredData) {
                resolveData(oSvc, 'trainingPersonList');
            }

            return oSvc.getDeferred().promise;
        }

        factory.getTrainingPersons = function (searchOptions, noStoredData) {
            var oSvc = odataSvc.get();
            var apiPath = "/GetTrainingPersonSearch";
            var predicates = [];
            var combinedPredicate;

            if (searchOptions.commissionId)
                predicates.push(new $odata.Predicate('commissionId', '=', searchOptions.commissionId));

            if (searchOptions.trainingYear)
                predicates.push(new $odata.Predicate('trainingYear', '=', searchOptions.trainingYear));

            if (searchOptions.trainingId)
                predicates.push(new $odata.Predicate('trainingId', '=', searchOptions.trainingId));

            combinedPredicate = $odata.Predicate.and(predicates);

            oSvc.getSource(apiPath).odata()
                .filter(combinedPredicate)
                .query()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            if (!noStoredData) {
                resolveData(oSvc, 'trainingPersonList');
            }

            return oSvc.getDeferred().promise;
        }


        factory.sendBatchAttendedIds = function(attendedIds, notAttendedIds, resetIds){
            var apiPath = amsConst.webApiUrl + '/odata/SendBatchAttendedIds';
            var data = { "attendedIds": attendedIds, "notAttendedIds": notAttendedIds, "resetAttendanceIds": resetIds };

            return $http.post(apiPath, data).then(function (response) {

            }).catch(function (error) {
                console.log('Error while submitting', error);
            });
        }
        
        factory.approvePevVist = function (trainingPersonId, approvedType, societyId) {//ApproveForVisit
            var apiPath = amsConst.webApiUrl + '/odata/ApproveForVisit';
            var data = {"trainingPersonId": trainingPersonId, "approvedType": approvedType, "societyId": societyId};

            return $http.post(apiPath, data).then(function (response) {

            }).catch(function (error) {
                console.log('Error while submitting', error);
            });
        }

        factory.enrollUsers = function (trainingId) {
            var apiPath = amsConst.webApiUrl + '/odata/EnrollUsers';
            var data = { "trainingId": trainingId };

            //return $http.post(apiPath, data).then(function (response) {

            //}).catch(function (error) {
            //    console.log('Error while submitting', error);
            //});

            return $http.post(apiPath, data);
        }

        factory.cleanPevcFromSearch = function(pevc){
            var copiedPevc = angular.copy(pevc);
            
            delete copiedPevc.firstName;
            delete copiedPevc.lastName;
            delete copiedPevc.middleName;
            delete copiedPevc.sessionName;
            delete copiedPevc.societyAbbreviation;
            delete copiedPevc.societyName;
            delete copiedPevc.trainingMenteeId;
            delete copiedPevc.trainingMentorId;
            delete copiedPevc.trainingMentorName;
            delete copiedPevc.trainingYear;
            delete copiedPevc.applicationSocietyCode;
            delete copiedPevc.applicationSocietyId;
            delete copiedPevc.trainingTypeId;
            delete copiedPevc.attended
            delete copiedPevc.societyStatusId;
            delete copiedPevc.volunteerId;
            delete copiedPevc.applicationSocietyArray;
            delete copiedPevc.volunteerApplicationId;
            delete copiedPevc.volunteerApplicationSocieties;
            delete copiedPevc.trainingFacilitatorId;
            delete copiedPevc.trainingFacilitatorName;
            delete copiedPevc.trainingPersonFacilitatorId;
            delete copiedPevc.isEnrolled;
            delete copiedPevc.email;


            return copiedPevc;
        }

        factory.getPEVCEvaluationByPersonId = function (personId,trainingSessionId) {
            var oSvc = odataSvc.get();
            var path = oSvc.getPathWithParameters('/ViewPEVCEvaluation', 'personId', personId, 'trainingSessionId', trainingSessionId)

            oSvc.getSource(path).odata()
                .single()
                .$promise
                .then(oSvc.onSuccess, oSvc.onFailure);

            return oSvc.getDeferred().promise;
        };

        factory.openInstructions = function () {
            console.log("test");
            alertSvc.openPDFModal('/Content/files/PEVC-Training-Instructions.pdf', 'PEVC Training Instructions');
        };

        factory.convertTrainingPersonViewModelToDto = function (pevc) {
            return {
                trainingPersonId: pevc.trainingPersonId,
                trainingId: pevc.trainingId,
                personId: pevc.personId,
                trainingStatusId: pevc.trainingStatusId,
                trainingSessionId: pevc.trainingSessionId,
                commissionId: pevc.commissionId,
                societyId: pevc.societyId,
                trainingObserverTypeId: pevc.trainingObserverTypeId,
                isPaidByABET: pevc.isPaidByABET,
                note: pevc.note,
                isEnrolled: pevc.isEnrolled,
                isMentor: pevc.isMentor,
                trainingStatusName: pevc.trainingStatusName,
                trainingObserverTypeName: pevc.trainingObserverTypeName
            };
        };

        function resolveData(oSvc, dataName) {
            oSvc.getDeferred().promise.then(function (data) {
                if (dataName === 'pevcs') {
                    var mergedSolution = [];
                    data.forEach(function (item) {
                        var existing = mergedSolution.filter(function (v, i) {
                            return v.trainingPersonId === item.trainingPersonId;
                        })
                        if(existing.length === 0) {                           
                            mergedSolution.push(item);
                        }                    
                    })
                    angular.forEach(mergedSolution, function(pevc){
                        pevc.volunteerApplicationSocieties = angular.fromJson(pevc.volunteerApplicationSocieties);
                    });
                    factory.data[dataName] = mergedSolution;
                } else {
                    factory.data[dataName] = data;
                }
            });
        }


        return {
            data: factory.data,
            getPEVCTrainingSessions: factory.getPEVCTrainingSessions,
            getPEVCTrainingPersons: factory.getPEVCTrainingPersons,
            getCurrentUsersTrainingPersonDto: factory.getCurrentUsersTrainingPersonDto,

            addTraining: factory.addTraining,
            updateTraining: factory.updateTraining,
            deleteTraining: factory.deleteTraining,

            addSession: factory.addSession,
            updateSession: factory.updateSession,
            deleteSession: factory.deleteSession,

            updateTrainingSession: factory.updateTrainingSession,

            getPEVCTrainingSearch: factory.getPEVCTrainingSearch,
            getTrainingMentors: factory.getTrainingMentors,
            getTrainingFacilitators: factory.getTrainingFacilitators,
            getTrainingObservers: factory.getTrainingObservers,
            getTrainings: factory.getTrainings,

            addTrainingFacilitator: factory.addTrainingFacilitator,
            deleteFacilitator: factory.deleteFacilitator,
            updateTrainingFacilitator: factory.updateTrainingFacilitator,

            addTrainingMentor: factory.addTrainingMentor,
            deleteMentor: factory.deleteMentor,

            addMentees: factory.addMentees,
            deleteMentee: factory.deleteMentee,

            addPersonFacilitators: factory.addPersonFacilitators,
            deletePersonFacilitator: factory.deletePersonFacilitator,           
            
            deleteVisitApproval: factory.deleteVisitApproval,
            approvePevVist: factory.approvePevVist,

            addTrainingObserver: factory.addTrainingObserver,
            deleteTrainingObserver: factory.deleteTrainingObserver,
            deleteTrainingPerson: factory.deleteTrainingPerson,

            getPEVCTrainingSessionsAdmin: factory.getPEVCTrainingSessionsAdmin,
            sendBatchAttendedIds: factory.sendBatchAttendedIds,
            cleanPevcFromSearch: factory.cleanPevcFromSearch,
            getMyTrainingRoles: factory.getMyTrainingRoles,
            getPevcTrainingsByPersonId: factory.getPevcTrainingsByPersonId,
            openInstructions: factory.openInstructions,
            getTrainingPersons: factory.getTrainingPersons,
            getOtherTrainingPersons: factory.getOtherTrainingPersons,
            getPEVCEvaluationByPersonId: factory.getPEVCEvaluationByPersonId,
            enrollUsers: factory.enrollUsers,
            convertTrainingPersonViewModelToDto: factory.convertTrainingPersonViewModelToDto
        };
    };

    module.factory('trainingSvc', trainingSvc);

})(angular.module('training'));