(function (module) {

    var rfrInstitutionalReviewOptionsCtrl = function ($scope, $uibModal, $state, helperSvc, eventSvc, typeConstSvc, rfrSvc) {
        var model = this;

        model.parent = $scope.$parent;
        model.title = 'Institutional Review Options';
        model.openReviewOptionsInstructions = rfrSvc.openReviewOptionsInstructions;
        model.isAdmin = $state.current.name.toLowerCase().contains('admin');
        model.commissions = [];
        model.allCommissionsFocusedReport = true;
        model.commissionsIR = [];
        model.helpText = {
            simultaneousReview: 'A simultaneous ABET review happens when two or more Commissions of ABET conduct an onsite visit at the same time in the same review cycle. Please note that any program requiring review by two Commissions must be a simultaneous onsite visit.<br/><br/>Ex: Computer Science and Engineering, Construction Engineering and Management',
            institutionLocations: 'Based on where the accredited program(s) or new program(s) are delivered, please indicate the number of institution locations/sites the team will need to visit.'
        }

        model.editSimultaneousOption = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/apps/rfr/templates/rfrSimultaneousReviewTeamsEdit.html',
                size: 'lg',
                controller: 'rfrSimultaneousReviewTeamsEditCtrl',
                resolve: {
                    parent: function () {
                        return model;
                    }
                }
            });
        };

        model.editCampusLocationCount = function () {
            var modalInstance = $uibModal.open({
                animation: true,
                templateUrl: '/apps/rfr/templates/rfrLocationCountEdit.html',
                size: 'md',
                controller: 'rfrLocationCountEditCtrl'
            });
        };

        model.getTeam1Text = function () {
            return getRfrCommissionDtoText(1);
        };

        model.getTeam2Text = function () {
            return getRfrCommissionDtoText(2);
        };

        model.getLocationCount = function () {
            
            var locationCount = 0;
            
            if (rfrHasLocationCount()) {
                locationCount = model.data.rfr.noOfInstitutionLocations;
            }

            return locationCount;
        };

        model.getRfrCommissionDtos = function () {
            var rfrCommissionDtos = [];

            if (model.rfrHasCommissionDtos()) {
                rfrCommissionDtos = model.data.rfr.rfrCommissionDtos;
            }

            return rfrCommissionDtos;
        };

        //also used in rfrSimultaneousReviewTeamsEditCtrl
        model.rfrHasCommissionDtos = function () {
            return model.data && model.data.rfr && model.data.rfr.rfrCommissionDtos;
        };

        var checkMultipleCommissions = function () {
            var hasMultipleCommissions = false;

            if (model.rfrHasCommissionDtos()) {
                var numCommissions = model.data.rfr.rfrCommissionDtos.length;

                if (numCommissions > 1) {
                    hasMultipleCommissions = true;
                }
            }

            return hasMultipleCommissions;
        };

        model.canHaveTeam2 = function () {
            var canHaveTeam2 = false;
            var totalNumOfCommissions = 4;

            if (model.rfrHasCommissionDtos() && model.data.rfr.rfrCommissionDtos.length === totalNumOfCommissions) {
                canHaveTeam2 = true;
            }

            return canHaveTeam2;
        };

        model.hasTeam2 = function () {
            var foundTeam2 = false;
            var rfrCommissionDtos = model.getRfrCommissionDtos();

            for (var i = 0; i < rfrCommissionDtos.length; i++) {
                if (rfrCommissionDtos[i].simultaneousOptionSeq === 2) {
                    foundTeam2 = true;
                }
            }

            return foundTeam2;
        };

        var rfrHasLocationCount = function () {
            return model.data && model.data.rfr && model.data.rfr.noOfInstitutionLocations;
        };

        var getRfrCommissionDtoText = function (teamId) {
            var team = model.getRfrCommissionDtos();
            var teamText = 'Not entered';
            var teams = [];

            if (team.length !== 0) {
                for (var i = 0; i < team.length; i++) {
                    if (team[i].simultaneousOptionSeq === teamId) {
                        teams.push(typeConstSvc.getCommissionAbbreviationById(team[i].commissionId));
                    }
                }
            }

            if (teams.length > 0) {
                teamText = teams.join(', ');
            }

            return teamText;
        };

        activate();

        function activate() {
            model.data = rfrSvc.data;
            model.isTeam2Visible = (model.canHaveTeam2() && model.hasTeam2()) ? true : false;
            model.hasMultipleCommissions = checkMultipleCommissions();
            model.allCommissionsFocusedReport = rfrSvc.checkForAllCommissionsAreFocusedReport(model.data.rfr);
            if (!model.allCommissionsFocusedReport) {
                model.commissionsIR = rfrSvc.getIRCommissionIds(model.data.rfr);
                model.engoughCommissionsForSimultaneous = model.data.rfr.rfrCommissionDtos.length - model.commissionsIR.length > 1;
            }
        }

        model.isEmpty = helperSvc.isEmpty;
    };

    module.controller('rfrInstitutionalReviewOptionsCtrl', rfrInstitutionalReviewOptionsCtrl);

}(angular.module('rfr')));