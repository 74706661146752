(function (module) {

    var filter = function (pevAssignmentSvc, helperSvc) {
        return function (reviewPrograms, reviewProgramFilters) {
            var matches = [];

            //var rangeStartDate = reviewProgramFilters.rangeStartDate ? new Date(reviewProgramFilters.rangeStartDate + ' 00:00:00 UTC') : null;
            //var rangeEndDate = reviewProgramFilters.rangeEndDate ? new Date(reviewProgramFilters.rangeEndDate + ' 00:00:00 UTC') : null;
            var rangeStartDate = reviewProgramFilters.rangeStartDate ? reviewProgramFilters.rangeStartDate : null;
            var rangeEndDate = reviewProgramFilters.rangeEndDate ? reviewProgramFilters.rangeEndDate : null;
            var programReviewType = reviewProgramFilters.programReviewType ? reviewProgramFilters.programReviewType.toLowerCase() : null;
            var DISTANCE_LEARNING_100 = '100 %'; // could also define a constant and manually inject here
            var programReviewVisitTypes = pevAssignmentSvc.data.programReviewTypeOptions? pevAssignmentSvc.data.programReviewTypeOptions.map(function (code) { return code.codeKey; }).join(',') : null;


            angular.forEach(reviewPrograms, function (reviewProgram) {
                // Assignment status
                if (reviewProgramFilters.assignment) {
                    switch (reviewProgramFilters.assignment) {
                        case 'assigned':
                            if (!reviewProgram.pevsAssigned || reviewProgram.pevsAssigned.length < reviewProgram.pevsRequired) {
                                return;
                            }
                            break;
                        case 'partial':
                            if (!reviewProgram.pevsAssigned || reviewProgram.pevsAssigned.length === 0 || reviewProgram.pevsAssigned.length >= reviewProgram.pevsRequired) {
                                return;
                            }
                            break;
                        case 'unassigned':
                            if (reviewProgram.pevsAssigned && reviewProgram.pevsAssigned.length > 0) {
                                return;
                            }
                            break;
                        case 'needsAssignment':
                            if (reviewProgram.pevsAssigned && reviewProgram.pevsAssigned.length >= reviewProgram.pevsRequired) {
                                return;
                            }
                            break;
                    }
                }

                // Visit start date

                //if user is filtering by date, remove N/A dates
                if ((rangeStartDate !== null || rangeEndDate !== null) && reviewProgram.visitStartDate === 'N/A') return;

                var visitStartDate = new Date(reviewProgram.visitStartDate + ' 00:00:00 UTC');
                
                if (visitStartDate && ((rangeStartDate && visitStartDate < rangeStartDate) || (rangeEndDate && visitStartDate > rangeEndDate))) {
                    return;
                }

                var reviewProgramDetail = (reviewProgram.programDetail && reviewProgram.programDetail.length > 0) ? reviewProgram.programDetail[0] : null;

                // Review type
                if (programReviewType) {
                    if (!reviewProgram.programReviewTypeCode || reviewProgram.programReviewTypeCode.toLowerCase() !== programReviewType) {
                        return;
                    }
                } else {
                    // Always filter on program review type is a "visit" type; i.e. programReviewTypes where methodTypeCode = 'V'
                    if (programReviewVisitTypes && programReviewVisitTypes.indexOf(reviewProgram.programReviewTypeCode) < 0) {
                        return;
                    }
                }

                // Program area
                if (reviewProgramFilters.programAreas && reviewProgramFilters.programAreas.length > 0) {
                    if (!reviewProgramDetail ||
                        !reviewProgramFilters.programAreas.some(
                            function (filteredProgramArea) {
                                return (filteredProgramArea.disciplineName === reviewProgramDetail.disciplineName);
                    })) {
                        return;
                    }
                }

                // Distance learning
                if (reviewProgramFilters.distanceLearning) {
                    if (!reviewProgramDetail ||
                        !reviewProgramDetail.distanceLearningType ||
                        (reviewProgramDetail.distanceLearningType.indexOf(DISTANCE_LEARNING_100) < 0 && 
                        reviewProgramDetail.distanceLearningType.indexOf(DISTANCE_LEARNING_100.replace(/ /g, '')) < 0)) { // have to check for '100 %' and '100%'
                        return;
                    }
                }

                // Society observer allowed
                if (reviewProgramFilters.societyObserver) {
                    if (!reviewProgram.societyObserverFlag) {
                        return;
                    }
                }

                // US programs only or non-US programs
                if (reviewProgramFilters.usOnly && reviewProgramFilters.nonUsOnly) {
                    if (!reviewProgram.countryName) return;
                }
                else if (reviewProgramFilters.usOnly) {
                    if (!reviewProgram.countryName) return;

                    var countryName = reviewProgram.countryName.trim().toLowerCase(); // countryName is sometimes " United States"
                    if (countryName !== 'united states' && countryName !== 'us' && countryName !== 'u.s.') {
                        return;
                    }
                } else if (reviewProgramFilters.nonUsOnly) {
                    if (!reviewProgram.countryName) return;

                    var countryName = reviewProgram.countryName.trim().toLowerCase(); // countryName is sometimes " United States"
                    if (countryName === 'united states' || countryName === 'us' || countryName === 'u.s.') {
                        return;
                    }
                }

                // Exclude programs without confirmed visit start date
                if (reviewProgramFilters.excludeUnconfirmedStartDate) {
                    if (!pevAssignmentSvc.pevReqs.hasStartDate(reviewProgram)) {
                        return;
                    }
                }
                // Onsite Visit Restriction
                if (reviewProgramFilters.onsiteVisitRestriction && reviewProgramFilters.onsiteVisitRestriction.length > 0 && reviewProgramFilters.onsiteVisitRestriction.length < 5) {
                    if (!helperSvc.arrayContainsByPropertyValue(reviewProgramFilters.onsiteVisitRestriction, 'codeKey', reviewProgram.onsiteVisitRestrictionStatusId)) {
                        return;
                    }
                }

                // Review program has passed every filter check; add it to the filtered matches collection.
                matches.push(reviewProgram);
            });

            return matches;
        };
    };

    module.filter('reviewPrograms', filter);

}(angular.module('assignment')));

