(function (module) {

    var rfrSimultaneousReviewTeamsEditCtrl = function ($scope, $uibModalInstance, $timeout, programReviewTypeIds, parent, typeConstSvc, helperSvc, rfrSvc, alertSvc, rfrValidationSvc) {
        var model = $scope;
        model.parent = parent;
        model.title = 'Simultaneous Visit Teams';
        model.showAddTeamButton = false;
        model.showDeleteTeamButton = false;
        model.rfrCommissionDtos = [];
        model.teamId1 = 1;
        model.teamId2 = 2;
        model.data = {};
        model.conflictMessage = 'There is a conflict between the suggested visit dates for the selected commissions within team';
        model.conflictMessageRestriction = 'There is a conflict between on-site visit restriction selections for the selected commissions';
        model.hasTeam1Conflict = false;
        model.hasTeam2Conflict = false;

        var addingTeam2 = false;
        var deletingTeam2 = false;
        var maxCommissions = 4;

        //shared function from rfrInstitutionalReviewOptionsCtrl
        model.rfrHasCommissionDtos = model.parent.rfrHasCommissionDtos;

        //ignores updated data and closes modal
        model.cancel = function () {
            closeModal();
        }

        //persists changes and closes modal
        model.save = function () {

            rfrSvc.update(model.data.rfr).then(function () {
                alertSvc.addAlertSuccess("Review options successfully updated.");
                rfrValidationSvc.invokeValidation();
                closeModal();
            });
        };

        //remove team data from rfr
        model.deleteTeamInfo = function () {
            alertSvc.confirmDelete(null, deleteFunc);

            function deleteFunc() {
                removeTeam(model.teamId1);

                if (canHaveTeam2()) {
                    model.deleteTeam2();
                } else {
                    removeTeam(model.teamId2);
                }

                model.save();
            }
        };

        //get abbreviated text for a commission by id
        model.getCommissionName = function (commission) {
            return typeConstSvc.getCommissionAbbreviationById(commission.commissionId);
        };

        //shows button for adding second team in view
        model.addTeam2 = function () {
            model.showAddTeamButton = false;
            model.showDeleteTeamButton = true;
            addingTeam2 = true;
        };

        //removes any commission from team 2 and hides button from view
        model.deleteTeam2 = function () {
            //var rfrCommissionDtos = getRfrCommissionDtos();
            model.showAddTeamButton = true;
            model.showDeleteTeamButton = false;
            addingTeam2 = false;

            //remove all commissions from second team
            removeTeam(model.teamId2);

            model.showTeam2();
        };

        //determines if second team is shown in view
        model.showTeam2 = function () {
            var hasTeam2 = false;
            
            if (model.rfrHasCommissionDtos()) {
                var rfrCommissionDtos = getRfrCommissionDtos();

                for (var i = 0; i < rfrCommissionDtos.length; i++) {
                    if (rfrCommissionDtos[i].simultaneousOptionSeq === model.teamId2) {
                        hasTeam2 = true;
                    }
                }
            }

            if (addingTeam2) {
                hasTeam2 = true;
            }

            return hasTeam2;
        };

        //determine if first and second teams are valid
        model.invalidTeams = function () {
            var invalidTeam1 = model.invalidTeam1();
            var invalidTeam2 = model.invalidTeam2();

            return invalidTeam1 || invalidTeam2;
        };

        //determines if first team is valid
        model.invalidTeam1 = function () {
            return invalidTeam(model.teamId1);
        };

        //determines if second team is valid
        model.invalidTeam2 = function () {
            var invalidTeam2 = false;

            //if a second team is possible, then it is validated
            if (canHaveTeam2() && model.showTeam2()) {
                invalidTeam2 = invalidTeam(model.teamId2);
            }

            return invalidTeam2;
        };

        //determines if either team has a conflicting suggested visit date
        model.hasTeamConflict = function () {
            return model.hasTeam1Conflict || model.hasTeam2Conflict;
        };

        //determines if team 1 has a conflicting suggested visit date
        model.getTeam1Conflict = function () {
            model.hasTeam1Conflict = checkDateConflicts(model.teamId1);
            return model.hasTeam1Conflict;
        }

        //determines if team 2 has a conflicting suggested visit date
        model.getTeam2Conflict = function () {
            model.hasTeam2Conflict = checkDateConflicts(model.teamId2);
            return model.hasTeam2Conflict;
        }

        //check team for suggested visit dates that dont match
        var checkDateConflicts = function (teamSeq) {
            var commissionDtos = getRfrCommissionDtos();
            var valueArray = [];
            var hasConflicts = false;
            var filteredCommissions = commissionDtos.filter(t => t.simultaneousOptionSeq == teamSeq);

            if (filteredCommissions && filteredCommissions.length > 1) {
                for (let com of filteredCommissions)
                {
                    if (com.jsonContents.suggestedVisitDate) {
                        valueArray.push(com.jsonContents.suggestedVisitDate);
                    }
                }
                if (valueArray.length > 1)
                    hasConflicts = helperSvc.anyDifferentValueInArray(valueArray);
            }

            return hasConflicts;
        };

        //check team for on-site restriction that dont match
        model.getRestrictionConflicts = function () {
            var commissionDtos = getRfrCommissionDtos();
            return rfrSvc.checkRestrictionConflicts(commissionDtos);
        };

        //remove all commissions from matching team
        var removeTeam = function (teamId) {
            var rfrCommissionDtos = getRfrCommissionDtos();

            for (var i = 0; i < rfrCommissionDtos.length; i++) {
                var commissionDto = rfrCommissionDtos[i];

                if(commissionDto.simultaneousOptionSeq === teamId) {
                    commissionDto.simultaneousOptionSeq = null;
                    }
                }
        };

        //a team is valid if it has at least 2 commissions, otherwise invalid
        var invalidTeam = function (teamId) {
            var isValid = true;
            var count = countTeam(teamId);

            if (count > 1) {
                isValid = false;
            }

            return isValid;
        };

        //count occurences of a teamId
        var countTeam = function (teamId) {
            var rfrCommissionDtos = getRfrCommissionDtos();
            var count = 0;

            for (var i = 0; i < rfrCommissionDtos.length; i++) {
                if (rfrCommissionDtos[i].simultaneousOptionSeq === teamId) {
                    count++;
                }
            }

            return count;
        };

        //add a commission to a team
        var addToTeam = function (rfrCommissionDto, simultaneousOptionSeq) {
            rfrCommissionDto.simultaneousOptionSeq = simultaneousOptionSeq;
        };

        var getRfrCommissionDtos = function () {
            return model.rfrCommissionDtos;
        };

        var closeModal = function () {
            $uibModalInstance.close();
        };

        //only rfrs with all 4 commissions are allowed to have a second team
        var canHaveTeam2 = function () {
            return model.rfrCommissionDtos.length === maxCommissions;
        };

        activate();

        function activate() {
            angular.copy(rfrSvc.data, model.data);
            model.rfrCommissionDtos = helperSvc.getFilteredArray(model.data.rfr.rfrCommissionDtos, 'reviewTypeCode', [programReviewTypeIds.FOCUSEDREPORT])

            //special 4 commission scenario
            if (canHaveTeam2()) {
                //show delete button if second team already exists, otherwise show add button
                if (model.showTeam2()) {
                    model.showDeleteTeamButton = true;f
                    addingTeam2 = true;
                }
                else {
                    model.showAddTeamButton = true;
                }
            }
        }
    };

    module.controller('rfrSimultaneousReviewTeamsEditCtrl', rfrSimultaneousReviewTeamsEditCtrl);

}(angular.module('rfr')));